import { DepartmentsComponent } from './departments.component';
import { AddDepartmentComponent } from './add-department/add-department.component';
import { EditDepartmentComponent } from './edit-department/edit-department.component';
import { DepartmentComponent } from './department/department.component';


export const DEPARTMENT_ROUTES = [
    {
        path: 'departments',
        component: DepartmentsComponent
    },
    {
        path: 'departments/add',
        component: AddDepartmentComponent
    },
    {
        path: 'departments/edit/:id',
        component: EditDepartmentComponent
    }
];

export const DEPARTMENT_COMPONENTS = [
    DepartmentsComponent,
    AddDepartmentComponent,
    EditDepartmentComponent,
    DepartmentComponent
];
