<mat-toolbar color="accent">
	<div class="maxcontainer">
		<a class="space" [routerLink]="['/', 'login']"><img style="max-height: 60px;" src="{{ event.logo }}" /></a>
	</div>
</mat-toolbar>
<div class="full-height-no-sidebar">
    <mat-vertical-stepper *ngIf="company != null" [linear]="true" #stepper>
	   <p>Company</p>
	   <mat-step *ngIf="company.first_time">
		  <form>
            <ng-template matStepLabel>Are you the primary contact for <b>{{ company.name }}</b>?</ng-template>
			<div class="row">
				<div class="col">
					<mat-button-toggle-group [(value)]="is_primary" #primary="matButtonToggleGroup" >
						<mat-button-toggle [value]="true">
							Yes
						</mat-button-toggle>
						<mat-button-toggle [value]="false">
							No
						</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
			</div>
			<br>
			<div class="row">
				<div class="col">
					<button mat-raised-button matStepperNext color="accent">Next</button>
				</div>
			</div>
			</form>
		</mat-step>
		<mat-step *ngIf="is_primary">
			<form [formGroup]="compForm">
				<ng-template matStepLabel>Update your company info</ng-template>
				<div class="container-fluid">
						<div class="row">
							<div class="col">
								<h2>{{ company.name }}</h2>
							</div>
						</div>
						<div class="row">
							<div *ngIf="!shipOnly" class="col-md">
								<mat-card appearance="outlined" formGroupName="billingGroup">
									<mat-card-header>
										<mat-card-title>Billing Address</mat-card-title>
									</mat-card-header>
									<mat-card-content>
											<mat-form-field>
												<mat-label>Country</mat-label>
												<mat-select [(ngModel)]="billingCountry" formControlName="country_id" name="country">
													<mat-option  *ngFor="let country of countries" [value]=country.id>{{country.long_name}}</mat-option>
												</mat-select>
											</mat-form-field>
											<mat-form-field *ngIf="billingCountry == 4">
												<input matInput formControlName="other_country" name="other_country" placeholder="Other Country" />
											</mat-form-field>
											<mat-form-field>
												<input matInput name="address_1" placeholder="Address 1" formControlName="address_1" />
											</mat-form-field>
											<mat-form-field>
												<input matInput name="address_2" placeholder="Address 2" formControlName="address_2" />
											</mat-form-field>
											<mat-form-field>
												<input matInput name="address_3" placeholder="Address 3" formControlName="address_3" />
											</mat-form-field>
											<mat-form-field>
												<input matInput name="city" placeholder="City" formControlName="city" />
											</mat-form-field>
											<mat-form-field *ngIf="billingCountry == 1">
												<mat-label>State</mat-label>
												<mat-select formControlName="state_id" name="state">
													<mat-option *ngFor="let state of states" [value]=state.id>{{state.long_name}}</mat-option>
												</mat-select>
											</mat-form-field>
											<mat-form-field *ngIf="billingCountry > 1">
												<input matInput formControlName="other_state" name="other_state" placeholder="State" />
											</mat-form-field>
											<mat-form-field>
												<input matInput name="postal_code" placeholder="Postal Code" formControlName="postal_code" />
											</mat-form-field>
									</mat-card-content>
								</mat-card>
							</div>
							<div class="col-md">
								<mat-card appearance="outlined" formGroupName="mailingGroup">
									<mat-card-header>
										<mat-card-title>Mailing Address</mat-card-title>
									</mat-card-header>
									<div *ngIf="!shipOnly" class="row">
										<div class="col">
											<mat-checkbox [checked]="sameAsBilling" (change)="sameAsBilling = !sameAsBilling">Same as Billing Address</mat-checkbox>
										</div>
									</div>
									<mat-card-content *ngIf="!sameAsBilling">
											<mat-form-field>
												<mat-label>Country</mat-label>
												<mat-select [(ngModel)]="mailingCountry" formControlName="country_id" name="country">
													<mat-option  *ngFor="let country of countries" [value]=country.id>{{country.long_name}}</mat-option>
												</mat-select>
											</mat-form-field>
											<mat-form-field *ngIf="mailingCountry == 4">
												<input matInput formControlName="other_country" name="other_country" placeholder="Other Country" />
											</mat-form-field>
											<mat-form-field>
												<input matInput name="address_1" placeholder="Address 1" formControlName="address_1" />
											</mat-form-field>
											<mat-form-field>
												<input matInput name="address_2" placeholder="Address 2" formControlName="address_2" />
											</mat-form-field>
											<mat-form-field>
												<input matInput name="address_3" placeholder="Address 3" formControlName="address_3" />
											</mat-form-field>
											<mat-form-field>
												<input matInput name="city" placeholder="City" formControlName="city" />
											</mat-form-field>
											<mat-form-field *ngIf="mailingCountry == 1">
												<mat-label>State</mat-label>
												<mat-select formControlName="state_id" name="state">
													<mat-option *ngFor="let state of states" [value]=state.id>{{state.long_name}}</mat-option>
												</mat-select>
											</mat-form-field>
											<mat-form-field *ngIf="mailingCountry > 1">
												<input matInput formControlName="other_state" name="other_state" placeholder="State" />
											</mat-form-field>
											<mat-form-field>
												<input matInput name="postal_code" placeholder="Postal Code" formControlName="postal_code" />
											</mat-form-field>
									</mat-card-content>
								</mat-card>
							</div>
						</div>

					</div>
			<button *ngIf="!addressesInvalid()" mat-raised-button matStepperNext color="accent">Next</button>
			<h4 *ngIf="addressesInvalid()"><b>Please complete address information before continuing.</b></h4>
		</form>
	</mat-step>
    <mat-step *ngIf="is_primary && (company.contract_status == 'Presented' || company.contract_status == 'Received Not Accepted' || company.contract_status == 'Not Presented') && !addressesInvalid() && !shipOnly && !contractNotApplicable">
        <ng-template matStepLabel>Review and accept your contract</ng-template>
        <form [formGroup]="contractForm"><br>
            <p><span *ngIf="!(company.contract_status == 'Not Presented' || company.contract_status == 'Exempt')">The details for your company's participation in the event have been confirmed<ng-template [ngIf]="event.has_activation_participation_fee">, including a participation fee of <span style="font-weight: bold;">{{ company.participation_fee | currency }}</span></ng-template>. <br></span>Please review the event <a href="{{ event.participation_agreement_link }}" target="_blank">participation agreement</a> and click the button below to indicate your company's acceptance.</p>
            <div *ngIf="event.has_activation_participation_fee && !(company.contract_status == 'Not Presented' || company.contract_status == 'Exempt')" [innerHTML]="event.overview_html"></div>
            <div *ngIf="event.has_activation_participation_fee && (company.contract_status == 'Not Presented' || company.contract_status == 'Exempt')" [innerHTML]="event.not_presented_overview_html"></div>
            <mat-checkbox formControlName="accept" style="clear: both;">Accept</mat-checkbox>
            <button style="display: block;" type="button" mat-raised-button matStepperNext color="accent">Next</button>
        </form>
    </mat-step>
	<mat-step *ngIf="(!addressesInvalid() && is_primary) || !company.first_time">
		<form (submit)="submit()" [formGroup]="userForm">
			<ng-template matStepLabel>Enter your account info</ng-template>
			<h3>{{ company.name }}</h3>
			<br>
			<mat-form-field>
				<input matInput required name="first_name" placeholder="First Name" formControlName="first_name" />
			</mat-form-field>
			<mat-form-field>
				<input matInput required name="last_name" placeholder="Last Name" formControlName="last_name" />
			</mat-form-field>
			<mat-form-field>
				<input matInput required name="email" placeholder="Email" formControlName="email" />
			</mat-form-field>
			<mat-form-field>
				<input matInput type="text" mask="(000-000-0000)" [dropSpecialCharacters]="['(', ')']" [(ngModel)]="myModel" required name="phone" placeholder="Phone Number" formControlName="phone" />
			</mat-form-field>
			<mat-form-field>
				<input matInput name="ext" placeholder="Extension" formControlName="ext" />
			</mat-form-field>
			<mat-form-field>
				<input matInput required name="job_title" placeholder="Job Title" formControlName="title" />
			</mat-form-field>
			<mat-form-field>
				<input matInput required name="username" placeholder="User Name" formControlName="username" />
				<mat-hint *ngIf="userForm.get('username').pending" class="full-width">
					<mat-progress-bar mode='query'></mat-progress-bar>
				</mat-hint>
				<mat-error *ngIf="userForm.controls.username.invalid">{{userForm.controls.username.hasError('UsernameExists', 'any') ? "This username is taken." : "Please enter a valid username."}}</mat-error>
			</mat-form-field>
			<mat-checkbox #showPassword>Show password</mat-checkbox>
			<mat-form-field>
				<input [type]="showPassword.checked ? 'text' : 'password'" required matInput placeholder="Password" name="password" formControlName="password" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
			</mat-form-field>
			<mat-form-field>
				<input [type]="showPassword.checked ? 'text' : 'password'" required matInput placeholder="Password Confirm" name="confirm_password" formControlName="confirm_password" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
			</mat-form-field>
			<p>By clicking submit, you agree to the terms and conditions of the <a target="_blank" href="{{ event.website_user_agreement_link }}">Website User Agreement</a>.</p>
			<button type="submit" [disabled]="submitted || !userForm.valid" mat-raised-button color="accent">Submit</button>
		</form>
	</mat-step>
	<mat-step *ngIf="!is_primary && company.first_time">
		<ng-template matStepLabel>Primary Contact</ng-template>
		<h2>Please forward your invite email to the primary contact and allow them to set up the company</h2>
	</mat-step>
</mat-vertical-stepper>
</div>
<app-footer [event]="event"></app-footer>
