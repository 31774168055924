import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { LabelDialogComponent } from '../label-dialog/label-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShippingDestinationService } from '@common/services/shipping-destination.service';
import { DepartmentService } from '@common/services/department.service';
import { AddressService } from '@common/services/address.service';
import { ManifestService } from '@common/services/manifest.service';
import { FormGroup } from '@angular/forms';
import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import { log } from '../../../common/log/log';


@Component({
  selector: 'app-manifest-dialog',
  templateUrl: './manifest-dialog.component.html',
  styleUrls: ['./manifest-dialog.component.css']
})
export class ManifestDialogComponent implements OnInit {

  form: FormGroup;
  parentComponent;
  selectedID;
  shippingDestination;
  departmentInfo;
  companyMailingAddress;
  manifestLineItems;
  manifestpackages;
  downloading = false;
  manifest_id;
  event;

  @ViewChild('content') content: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<LabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public sds: ShippingDestinationService,
    public ds: DepartmentService,
    public as: AddressService,
    public ms: ManifestService
  ) { }

  ngOnInit() {
    log.Debug('data')
    log.Debug(this.data.dataKey)

    log.Debug("event: ", this.data.event)
    this.event = this.data.event

    this.manifest_id = this.data.dataKey.id
    this.sds.getShippingLabel(this.data.dataKey.shipping_destination_id, this.data.dataKey.company_id).subscribe(r => {
      this.shippingDestination = r;

      this.ds.get(this.shippingDestination['department_id']).subscribe(r => {
          this.departmentInfo = r;
      });

      this.as.getFullAddressByID(this.shippingDestination['mailing_address_id']).subscribe(r => {
          this.companyMailingAddress = r;
      });

    });

    this.ms.getManifestPackagesByManifestID(this.data.dataKey.id).subscribe(r => {
      this.manifestpackages = r;
      log.Debug('manifestpackages')
      log.Debug(this.manifestpackages)
    });

    this.ms.getManifestLineItemsByManifestID(this.data.dataKey.id).subscribe(r => {
      this.manifestLineItems = r;
      log.Debug('manifestLineItems')
      log.Debug(this.manifestLineItems)
    });

  }


  print(): void {
      let printContents, popupWin;
      printContents = document.getElementById('print-section').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
          <title>Print Manifest</title>
            <style>
              body {font-family: sans-serif;}
              .supplier-information p{
                font-size: 14px;
                margin-bottom: 5px;
              }
              .delivery-address p{
                  margin: 0;
              }
              .clear {
                  clear:both;
              }
              table {
                border-collapse: collapse;
              }
              th {
                  text-align: center;
              }
              table, th, td {
                border: 1px solid #999;
              }
              th, td {
                  padding: 2px;
              }
              .package-size th {
                padding: 2px 5px;
              }
              .description-address p{
                margin: 2px 0;
              }
            </style>
          </head>
          <body onload="window.print();window.close()">
          ${printContents}
          </body>
        </html>`
      );
      popupWin.document.close();
  }


  DownloadPDF() {
      this.downloading = true
      this.ms.ManifestToPDF(this.manifest_id).subscribe( blob => {
          const a = document.createElement('a');
          a.setAttribute('download', 'manifest_'+this.data.dataKey.manifest_number+'.pdf');
          a.setAttribute('href', window.URL.createObjectURL(blob));
          a.click()
          this.downloading = false;
      })
  }

}
