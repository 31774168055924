import { FilesComponent } from './files.component';

export const FILE_MANAGEMENT_ROUTES = [
    {
        path: 'files',
        component: FilesComponent
    },
];

export const FILE_COMPONENTS = [
    FilesComponent,
];