<div class="container-fluid">
	<mat-card appearance="outlined">
		<mat-card-header>
			<mat-card-title><h1>Personal Information</h1></mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<form [formGroup]="form" (submit)="save()">
				<mat-form-field>
					<input matInput name="first_name" placeholder="First Name" formControlName="first_name" />
				</mat-form-field>
				<mat-form-field>
					<input matInput name="last_name" placeholder="Last Name" formControlName="last_name" />
				</mat-form-field>
				<mat-form-field>
					<input matInput required name="username" placeholder="Username" formControlName="username" />
				</mat-form-field>
				<mat-form-field>
					<input matInput required name="email" placeholder="Email" formControlName="email" />
				</mat-form-field>
				<mat-form-field>
					<input matInput name="phone" mask="(000-000-0000)" [dropSpecialCharacters]="['(', ')']" placeholder="Phone Number" formControlName="phone" />
				</mat-form-field>
				<mat-form-field>
					<input matInput name="ext" placeholder="Extension" formControlName="ext" />
				</mat-form-field>
				<mat-form-field>
					<input matInput name="job_title" placeholder="Job Title" formControlName="title" />
				</mat-form-field>
                <ng-container formGroupName="passwordGroup">
					<br>
					<br>
					<fieldset><legend>Change Password</legend>
						<mat-form-field>
						<input matInput name="password" type="password" placeholder="Password" formControlName="password" />
						</mat-form-field>
						<mat-form-field>
						<input matInput name="confirm_password" type="password" placeholder="Confirm Password" formControlName="confirmPassword" />
						</mat-form-field>
					</fieldset>
                </ng-container>
				<button type="submit" mat-raised-button color="primary" [disabled]="form.invalid">Submit</button>
		 	</form>
		</mat-card-content>
	</mat-card>
</div>
<app-footer [event]="event"></app-footer>
