import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DepartmentService } from '@common/services/department.service';
import { CompanyService } from '@common/services/company.service';
import { UserService } from '@common/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { ManifestService } from '@common/services/manifest.service';
import * as XLSX from 'xlsx';
import { ShippingDestinationService } from '@common/services/shipping-destination.service';
import { GroupService } from '@common/services/group.service';
import { log } from '@common/log/log'
import { AppService } from '../../common/services/app.service';

@Component({
  selector: 'app-outbound-disposition',
  templateUrl: './outbound-disposition.component.html',
  styleUrls: ['./outbound-disposition.component.css']
})
export class OutboundDispositionComponent implements OnInit {

  public form: FormGroup;
  public filterForm: FormGroup;
  public companies;
  public manifests;
  public allManifestLineItems;
  public departments;
  public shippingDestinations;
  public groups;

  @ViewChild('TABLE') table: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public displayedColumns = [
        'manifest_number',
        'brand_name',
        'sku',
        'group',
        'company_name',
        'department_name',
        'disposition',
        'return_method',
        'qty',
        'description',
        'ship_to_name',
        'return_address',
        'donate'
    ];

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _fb: FormBuilder,
    private _snack: MatSnackBar,
    private cs: CompanyService,
    private ds: DepartmentService,
    private sds: ShippingDestinationService,
    private ms: ManifestService,
    private us: UserService,
    private gs: GroupService,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService
  ) { }

  ngOnInit() {

    this.gs.all().subscribe(r => {
        this.groups = r;
    });

    this.sds.all().subscribe(r => {
      this.shippingDestinations = r;
    });

    this.cs.all().subscribe(r => {
      this.companies = r;
    });

    this.ds.allByLabelASC().subscribe(r => {
      this.departments = r;
    });

    this.ms.getAllManifestLineItems().subscribe(r => {
      this.allManifestLineItems = r;
    });

    this.filterForm = this._fb.group({
      'group': new FormControl(),
      'company': new FormControl(),
      'department': new FormControl(),
      'return': new FormControl(),
      'sku': new FormControl(),
      'manifest': new FormControl(),
      'shippingDestination' : new FormControl(),
    });


    this.route.queryParams.subscribe(v => {
      this.filterForm.patchValue({
        'group' : v['group'] || null,
        'company': v['company'] || null,
        'department': parseInt(v['department'])  || null,
        'return': v['return']  || null,
        'sku': v['sku'] || null,
        'manifest': parseInt(v['manifest'])  || null,
        'shippingDestination': parseInt(v['shippingDestination'])  || null,
      }, {emitEvent: false})
      this.ms.filterManifestLineItems(v).subscribe(r => {
      		log.Debug("manifests: ", r)
          this.manifests = new MatTableDataSource(<any> r);
          this.manifests.sort = this.sort;
          this.manifests.paginator = this.paginator;
      });
    });

    this.filterForm.valueChanges.pipe(
      debounceTime(500),
      ).subscribe(v => {
      this.router.navigate([], {queryParams: v});
    });

  }

  reset() {
    this.router.navigate([], {queryParams: {}});
  }

  ExportTOExcel() {

  	log.Debug("manifests to export: ", this.manifests)

    this.manifests.paginator.pageSize = this.manifests.filteredData.length;
    this.manifests.paginator._emitPageEvent(0);
    // set 'dontUseColumn' to the title of unwanted column
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.manifests._renderData._value);

    log.Debug("worksheet: ", ws)

    this.manifests.paginator.pageSize = 25;
    this.manifests.paginator._emitPageEvent(0);
    var formattedWS = {};
    var badColumn;

    Object.keys(ws).forEach(function (item) {
        if(!item.includes(badColumn)){
            if(ws[item]['v'] == " block "){
                ws[item]['v'] = "no";
            }
            if(ws[item]['v'] == " done "){
                ws[item]['v'] = "yes";
            }
            formattedWS[item] = ws[item];
        }
    });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
    var d = new Date();
    var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
    XLSX.writeFile(wb, 'outbound_dispositions_spreadsheet_'+date+'.xlsx');
    this._snack.open("Spreadsheet Downloaded!",null ,{duration: 3000});

  }

}
