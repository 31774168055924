<div class="container-fluid">
	<div *ngIf="company">
		<div class="row">
			<div class="col">
				<button *ngIf="!company.invoice_paid" [routerLink]="['pay']" mat-raised-button color="accent" class='extended gutters'><mat-icon>payment</mat-icon> Pay My Invoice</button>
				<button (click)="downloadInvoice()" mat-raised-button color="accent"><mat-icon>cloud_download</mat-icon> Download My Invoice</button>
				<div  *ngIf="downloadingInvoice" style="width:200px">
					<mat-progress-bar mode="buffer"></mat-progress-bar>
					Downloading Invoice
				</div>
			</div>
		</div>
		<div class="row" *ngIf="contractStatus !== 'Accepted' && !(currentUserRole.includes('supplier') && contractStatus === 'Received')">
			<div class="col">
				<mat-card appearance="outlined">
					<mat-card-content>
						<p>Please accept your event contract by clicking the person icon at the top right of your screen, then choose "Contract" and then click "Accept".</p>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
		<div class="row">
			<div class="col" *ngIf="!company.invoice_paid">
				<mat-card appearance="outlined" *ngIf="showContractAccepted()">
				    <mat-card-content>
				        <p>Thank you for accepting your event contract.</p>
				        <p>Download your invoice by clicking "Download My Invoice" above.  You can pay via credit card by clicking "Pay My Invoice" above.  For check and wire transfer payments, please be sure your records are updated with the correct Hartmann Studios address and bank account information.  <a href="{{ event.wire_transfer_instructions_link }}" target="_blank" >Click here for the wire transfer instructions.</a>  Refer to your invoice for check mailing address.</p>
				    </mat-card-content>
				</mat-card>
				<button *ngIf="!showContractAccepted()" mat-raised-button color="accent" [routerLink]="['/', 'app', 'contract']" class='extended gutters'>
					View Contract
				</button>
			</div>
			<div class="col" *ngIf="company.invoice_paid">
				<mat-card appearance="outlined">
					<mat-card-content>
						<p>
							Your invoice has been paid. Thank you for participating.
						</p>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
	</div>
	<div *ngIf="customInvoices">
		<h3>Other Invoices</h3>
		<div class="row">
			<div class="col">
				<table mat-table [dataSource]="customInvoices" class="table mat-elevation-z7 gutters">
		
					<ng-container matColumnDef="invoice_number">
						<th mat-header-cell *matHeaderCellDef> Invoice Number </th>
						<td mat-cell data-label="Invoice Number" *matCellDef="let element"> 
							{{element.invoice_number}}
							</td>
					</ng-container>
				
					<ng-container matColumnDef="amount">
						<th mat-header-cell *matHeaderCellDef> Amount </th>
						<td mat-cell data-label="Amount" *matCellDef="let element"> 
							{{element.amount | currency}}
						</td>
					</ng-container>
				
					<ng-container matColumnDef="actions">
						<th mat-header-cell *matHeaderCellDef> Actions </th>
						<td mat-cell data-label="Actions" *matCellDef="let element; let i = index"> 
							<span *ngIf="element.paid">
								Invoice Paid
							</span>
							<span *ngIf="!element.paid">
								<button mat-raised-button (click)="downloadCustom(element)">Download Invoice</button>
								<a mat-raised-button [routerLink]="['pay_invoice', element.id]">Pay Invoice</a>
							</span>
						</td>
					</ng-container>
			
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>
			</div>
		</div>
	</div>
</div>