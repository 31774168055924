import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { USERS } from '../../models/example-data/fake_users';
import { User } from '../../models/user';
import { environment } from '../../../environments/environment';
import { log } from '../log/log';
import { switchMap, map, tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Department } from '../../models/department';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	private _users$: BehaviorSubject<User[]> = new BehaviorSubject([]);

	get users$() {
		return this._users$.asObservable();
	}

	constructor(
		public http: HttpClient,
        private _auth: AuthService
		) {
		let users = USERS.map(u => {
			return new User(u);
		});
		this._users$.next(users);
	}

	getAll(filters) {
		return this.http.get(environment.api + '/admin/users/all', {params: filters});
	}

	getAllArchive(filters) {
		return this.http.get(environment.api + '/admin/users/all-archive', {params: filters});
	}

	all(){
		return this.http.get(environment.api + '/admin/users/all');
	}

	getPotentialOverideUsersByUserID(id){
		return this.http.get(environment.api + '/admin/users/overide_users_by_id/' + id);
	}

	getPotentialOverideUsersByUsernameByEmail(username, email){
		return this.http.get(environment.api + '/admin/users/overide_users_by_username_by_email/' + username + '/' + email);
	}

	getByID(id){
		return this.http.get(environment.api + '/admin/users/by_id/' + id);
	}

    getByHash(hash){
        log.Debug("hash: ", hash)
        return this.http.get(environment.api + '/users/by_hash/' + hash).pipe(map(u => new User(u)));
    }

	activate(data){
		return this.http.post(environment.api + '/users/activate', data).pipe(
            map( u => ({...u, ...data}) ),
            switchMap(u => this.http.post(environment.api + '/users/authenticate', u))
            );

	}

	add(data){
		return this.http.post(environment.api + '/admin/users/create', data);
	}

	save(data){
		return this.http.post(environment.api + '/admin/users/save_with_departments', data);
	}

	delete(id){
		return this.http.delete(environment.api + '/admin/users/delete/' + id);
	}

    changePassword(u: User){
        return this.http.put(environment.api + '/admin/users/change-password/' + u.id, u);
    }

	getCoordinators(){
		return this.http.get(environment.api + "/admin/users/all", {params: {role: "Supplier Coordinator"}});
	}

	getDepartmentsByUser(id){
		return this.http.get<Department[]>(environment.api + "/users_department/by_user_id/" + id);
	}

    sendPasswordResetLink(data){
        return this.http.post(environment.api + "/users/reset-password-link", data).pipe(tap(r => {
            log.Debug("send password reset response: ", r)
        }))
    }

	checkUsername(username){
		return this.http.get(environment.api + '/users/check_username/' + username);
	}

	usernameValidator(): AsyncValidatorFn {

		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			return this.checkUsername(control.value).pipe(
			debounceTime(400),
			distinctUntilChanged(),
			map(val => val ? { UsernameExists: true } : null)
			);
		};
	}

	getCompanyByInvitationCode(code){
        let response = this.http.get(environment.api + '/companies/by_invite_code/' + code).pipe(
            map( r => {
                log.Debug("r: ", r)
                let company = r["Company"]
                company["contract_status"] = r["ContractStatus"]
                return company
            })
        );
        log.Debug("response: ", response)
        return response
	}

    /**
     *
     * @param data
     * @deprecated
     * @returns
     */
    public login(data){
        return this.http.post(environment.api + "/users/authenticate", data).pipe(tap(r => {
            localStorage.setItem('token', r["Token"]["Token"]);
            r["User"]["password"] = ""
            localStorage.setItem("user", JSON.stringify(r["User"]));
            localStorage.setItem("role", "admin");
        }));
    }

	getLoggedInUser() {
		log.Debug("user raw local storage: ", this._auth.getUser())
		// log.Debug("user parsed local storage: ", JSON.parse(localStorage.getItem("user")) )
		log.Debug("user model local storage: ", new User(this._auth.getUser()))
		return new User(this._auth.getUser());
	}


}
