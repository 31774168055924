<div class="container-fluid">
  <form [formGroup]="filterForm">
      <mat-expansion-panel [expanded]="true" [hideToggle]="false" class="col">
          <mat-expansion-panel-header>
              <mat-panel-title>Search</mat-panel-title>
              <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <div class="row gutters">
            <div class="col">
                <mat-form-field>
                    <mat-label>Company</mat-label>
                    <input type="text" formControlName="company" matInput placeholder="Company" />
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field>
                    <mat-label>Group</mat-label>
                    <mat-select formControlName="group">
                        <mat-option [value]=null>All</mat-option>
                        <mat-option *ngFor="let g of groups" [value]=g.name>{{g.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field>
                    <mat-label>Department</mat-label>
                    <mat-select formControlName="department">
                        <mat-option [value]=null>All</mat-option>
                        <mat-option *ngFor="let d of departments" [value]=d.id>{{d.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <mat-label>Return</mat-label>
                    <mat-select formControlName="return">
                      <mat-option value="">All</mat-option>
                      <mat-option value="discard">Discard</mat-option>
                      <mat-option value="donate">Donate</mat-option>
                      <mat-option value="return">Return</mat-option>
                      <mat-option value="special_arrangement">Special Arrangement</mat-option>
                      <mat-option value="Show Carrier (TL)">Return via Show Carrier</mat-option>
                      <mat-option value="Other Carrier">Return via Other Carrier</mat-option>
                      <mat-option value="UPS">Return via UPS</mat-option>
                      <mat-option value="FedEx">Return via FedEx</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
          </div>
          <div class="row gutters">
            <div class="col">
                <mat-form-field>
                    <mat-label>SKU Number</mat-label>
                    <input type="text" formControlName="sku" matInput placeholder="SKU Number" />
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <mat-label>Manifest Number</mat-label>
                    <input type="text" formControlName="manifest" matInput placeholder="Manifest Number" />
                </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                  <mat-label>Shipping Destinations</mat-label>
                  <mat-select formControlName="shippingDestination">
                      <mat-option [value]=null>All</mat-option>
                      <mat-option *ngFor="let sd of shippingDestinations" [value]=sd.id>{{sd.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
          </div>
          <button mat-raised-button color="primary" (click)="reset()">Reset Filters</button>
          <button style="margin-left: 10px;" mat-raised-button color="primary" (click)="ExportTOExcel()">Export as .xls</button>
      </mat-expansion-panel>
  </form>
  <div>
      <div class="row">
          <div class="col" #TABLE>
              <table mat-table #table [dataSource]="manifests" matSort class="table mat-elevation-z7 gutters sticky-header">

                  <ng-container matColumnDef="manifest_number">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Manifest Number</th>
                    <td mat-cell data-label="manifest Number" *matCellDef="let element"> <a [routerLink]="['../..', 'shipping', 'manifest-info', element.manifest_id]">{{element.manifest_number}}</a> </td>
                  </ng-container>

                  <ng-container matColumnDef="brand_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell data-label="Name" *matCellDef="let element"> {{element.brand_name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="sku">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU </th>
                    <td mat-cell data-label="SKU" *matCellDef="let element"> {{element.sku}} </td>
                  </ng-container>
                  
                  <ng-container matColumnDef="group">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Group </th>
                    <td mat-cell data-label="Group" *matCellDef="let element"> {{element.group_name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="company_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                    <td mat-cell data-label="Company" *matCellDef="let element"> {{element.company_name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="department_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                    <td mat-cell data-label="Department" *matCellDef="let element"> {{element.department_name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="disposition">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Disposition</th>
                    <td mat-cell data-label="Disposition" *matCellDef="let element">
                      <span *ngIf="element.is_donate">Donate</span>
                      <span *ngIf="element.is_discard">Discard</span>
                      <span *ngIf="element.is_return">Return</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="return_method">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Shipment Method </th>
                    <td mat-cell data-label="Shipment Method" *matCellDef="let element"> {{element.return_method}} </td>
                  </ng-container>

                  <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity </th>
                    <td mat-cell data-label="Quantity" *matCellDef="let element"> {{element.qty}} </td>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                    <td mat-cell data-label="Description" *matCellDef="let element"> {{element.description}} </td>
                  </ng-container>

                  <ng-container matColumnDef="ship_to_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ship to Name </th>
                    <td mat-cell data-label="Ship to Name" *matCellDef="let element"> {{element.ship_to_name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="return_address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
                    <td mat-cell data-label="Address" *matCellDef="let element"> {{element.return_address}} </td>
                  </ng-container>

                  <ng-container matColumnDef="donate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Donate </th>
                    <td mat-cell data-label="Donate" *matCellDef="let element"> 
                      <span *ngIf="element.is_donate">
                        {{element.donated_value | currency}}
                      </span>
                      <mat-icon color="accent" *ngIf="!element.is_donate">
                        block
                      </mat-icon>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
          </div>
      </div>
  </div>
</div>
