import {PaymentsComponent} from './payments.component';

export const PAYMENTS_ROUTES = [
	{
		path: 'payments',
		component: PaymentsComponent
	},
];

export const PAYMENTS_COMPONENTS = [
	PaymentsComponent
];