import { Component, OnInit, Inject } from '@angular/core';
import { Page } from '../../../models/page';
import { PageService } from '../../../common/services/page.service';
import { MenuService } from '../../../common/services/menu.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TinyMCEService } from '../../../common/services/tinymce.service';

@Component({
	selector: 'app-edit-page-content',
	templateUrl: './edit-page-content.component.html',
	styleUrls: ['./edit-page-content.component.css']
})

export class EditPageContentComponent implements OnInit {
	// get these on init - or make/override options if you want something special
	public options: Object;
	public apiKey: string;

	pageID = 0;
	page = new Page();
	menus;
	constructor(
		public dialogRef: MatDialogRef<EditPageContentComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private PageService: PageService,
		private MenuService: MenuService,
		private MatSnackBar: MatSnackBar,
		private Router: Router,
		private ActivatedRoute: ActivatedRoute,
		public tinymce: TinyMCEService,
	) {
		// special case, removed from oninit
		this.options = this.tinymce.Options();
		this.apiKey = this.tinymce.GetAPIKey();
		let component = this;
		this.options['setup'] = function(editor) {

			function saveAndExit() {
				component.save();
			}

			function cancel() {
				component.cancel();
			}

			editor.on('init', function(e) {
				editor.execCommand('mceFullScreen');
			});

			editor.ui.registry.addButton('save_exit', {
				text: "Save",
				tooltip: 'Save the content and close',
				onAction: saveAndExit
			});

			editor.ui.registry.addButton('exit', {
				text: 'Exit',
				tooltip: "Cancel and Exit",
				onAction: cancel
			});
		};
	}

	ngOnInit() {

		this.PageService.get(this.data['page_id']).subscribe(r => {
			this.page.patch(r);
		});
	}

	save() {
		this.PageService.save(this.page).subscribe(r => {
			this.MatSnackBar.open("Page Saved!", null, { duration: 3000 });
			this.dialogRef.close();
		});
	}

	cancel() {
		this.dialogRef.close();
	}

}
