import { CompanyTypeService } from '../common/services/company-type.service';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../common/services/auth.service';
 
@Injectable()
export class UserCompanyTypeResolve {

	constructor(
		public CompanyTypeService: CompanyTypeService,
		public AuthService: AuthService,
	){}

	resolve(){
		const userID = this.AuthService.getUserID();
		return this.CompanyTypeService.GetCompanyTypeByUser(userID);
	}

}