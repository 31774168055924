import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SettingsService {

	constructor(
		public http: HttpClient
	) { }

    ByName( name: string ) {
        return this.http.get(environment.api + '/settings/by_name/' + name);
    }

	Update( name: string, value: string ) {
		return this.http.post(environment.api + '/settings/update_setting', {Key: name, Value: value});
	}
}
