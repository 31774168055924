import { Component, OnInit, HostBinding } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { log } from '../../common/log/log';


@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.css']
})
export class UnderConstructionComponent implements OnInit {

  event;

  constructor(
    public route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.data.subscribe((data: {
			event,
		}) => {
			log.Debug("data: ", data)
			this.event = data.event
		})
  }

}
