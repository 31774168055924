import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
  	public auth: AuthService,
  	public router: Router,
  	private appService: AppService
  ) {}

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.appService.navigate(['login']);
      return false;
    }
    return true;
  }
}
