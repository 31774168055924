// import {MediaMatcher} from '@angular/cdk/layout';
import {Component, OnInit, HostBinding} from '@angular/core';
import { ContentService } from '../common/services/content.service';
import { SidebarService } from '../common/services/sidebar.service';
import { PageService } from '../common/services/page.service';
import { ActivatedRoute , Router } from '@angular/router';
import { UserService } from '../common/services/user.service';
import { AnalyticsService } from '../common/services/analytics.service';
import { Autobind } from '../common/decorators/autobind';
import { AppService } from '../common/services/app.service';
import { AuthService } from '../common/services/auth.service';
import { log } from "../common/log/log";

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
    public openNav: boolean;
    public content;
    public pages;

    user;
    event;


    @HostBinding('class') classes = 'full-height no-head';
    constructor(
        public ContentService: ContentService,
        public SidebarService: SidebarService,
        private PageService: PageService,
        private ActivatedRoute: ActivatedRoute,
        public us: UserService,
        private router: Router,
        public AnalyticsService: AnalyticsService,
        public route: ActivatedRoute,
        private appService: AppService,
        private _auth: AuthService
    ){}

    ngOnInit() {

        this.route.data.subscribe((data: {
            event,
        }) => {
            log.Debug("data in info: ", data)
            this.event = data.event
        })

        this.ActivatedRoute.params.subscribe(params => {
            this.user = this.us.getLoggedInUser()

            if(this.user.role_id <= 3){
                this.PageService.getByMenu(params['menu']).subscribe(r => {
                    this.pages = r;
                    log.Debug("pages in info: ", this.pages);
                    if(this.ActivatedRoute.children.length == 0){
                        this.router.navigate([this.pages[0].slug], {relativeTo: this.ActivatedRoute});
                    }
                });
            }else{
                this.PageService.getActiveByMenu(params['menu']).subscribe(r => {
                    this.pages = r;
                    log.Debug(this.pages);
                    if(this.ActivatedRoute.children.length == 0){
                        this.router.navigate([this.pages[0].slug], {relativeTo: this.ActivatedRoute});
                    }
                });
            }
        });

        if(this.ActivatedRoute.children.length == 0){
            this.appService.navigate
        }

        this.content = this.ContentService.content;
        this.openNav = true;
        this.SidebarService.setPageHasSidebar(true);
    }

    @Autobind
    SaveAnalytic(item: string) {
        log.Debug("save analytic item: ", item)
        this.AnalyticsService.create(
                {item: item, user_id: this.user.id, company_id: this.user.company_id}
            ).subscribe( r =>
                log.Debug("saved analytic: ", r)
            )
    }

    menuOpen(){
        return this.SidebarService.isOpen();
    }




}
