<mat-sidenav-container >
	<mat-sidenav
		#sidenav
		[mode]="position$ | async"
		[opened]="SidebarService.isOpen()"
		[disableClose]="true">
		<mat-nav-list>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR) || AuthService.HasPermission(authGroups.VENDOR)" routerLinkActive="active" [routerLink]="['suppliers']">Supplier List</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.USER_MANAGER)" routerLinkActive="active" [routerLink]="['users']">Manage Users</a>
			<!-- <a mat-list-item *ngIf="AuthService.HasPermission(authGroups.USER_MANAGER) && isArchive" routerLinkActive="active" [routerLink]="['archive-users']">Manage Archive Users</a> -->
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR) || AuthService.HasPermission(authGroups.VENDOR)" routerLinkActive="active" [routerLink]="['companies']">Manage Companies</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPER_ADMIN)" routerLinkActive="active" [routerLink]="['departments']">Manage Departments</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPER_ADMIN)" routerLinkActive="active" [routerLink]="['groups']">Manage Groups</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR)" routerLinkActive="active" [routerLink]="['shipping_destinations']">Manage Shipping Destinations</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPER_ADMIN)" routerLinkActive="active" [routerLink]="['menus']">Manage Menus</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPER_ADMIN)" routerLinkActive="active" [routerLink]="['pages']">Manage Pages</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR)" routerLinkActive="active" [routerLink]="['invites']">Manage Invites</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR)" routerLinkActive="active" [routerLink]="['invoice_templates']">Manage Invoice Templates</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR) || AuthService.HasPermission(authGroups.VENDOR)" routerLinkActive="active" [routerLink]="['registrations']">Registrations</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR)" routerLinkActive="active" [routerLink]="['invoices']">Invoices</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPER_ADMIN)" routerLinkActive="active" [routerLink]="['settings']">App Settings</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPER_ADMIN)" routerLinkActive="active" [routerLink]="['files']">File Management</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.ACCOUNTING)" routerLinkActive="active" [routerLink]="['payments']">Payments</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR)" routerLinkActive="active" [routerLink]="['reports']">Reports</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR)" routerLinkActive="active" [routerLink]="['imports']">Imports</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.VENDOR) || AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR)" routerLinkActive="active" [routerLink]="['outbound-disposition']">Outbound Disposition Search</a>
			<a mat-list-item *ngIf="AuthService.HasPermission(authGroups.SUPER_ADMIN)" routerLinkActive="active" [routerLink]="['analytics']">Analytics</a>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
