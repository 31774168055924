import { Component, OnInit, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventService } from '../../common/services/event.service';
import { TinyMCEService } from '../../common/services/tinymce.service';
import { log } from '../../common/log/log';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})

export class SettingsComponent implements OnInit {
	// get these on init - or make/override options if you want something special
	public options: Object;
	public apiKey: string;

	@HostBinding('class') classes = 'full-height no-head';

    form: FormGroup;
    event;

    get participationAgreementLink() {
        return this.form.get('participation_agreement_link').value
    }

    get websiteUserAgreementLink() {
        return this.form.get('website_user_agreement_link').value
    }

    get privacyPolicyLink() {
        return this.form.get('privacy_policy_link').value
    }

    get wireTransferInstructionsLink() {
        return this.form.get('wire_transfer_instructions_link').value
    }

    get benefitsOverviewLink() {
        return this.form.get('benefits_overview_link').value
    }

    constructor(
        public route: ActivatedRoute,
        private _snack: MatSnackBar,
        public EventService: EventService,
		public tinymce: TinyMCEService,
    ) {
        this.form = this.createFormGroup();
    }

    ngOnInit() {
        this.route.data.subscribe((data: {
            event;
        }) => {
            log.Debug("data: ", data)
            this.event = data.event
            this.form.patchValue(data.event)
        });

		this.options = this.tinymce.Options();
		this.apiKey = this.tinymce.GetAPIKey();
    }

    createFormGroup() {
        return new FormGroup({
            id: new FormControl(null, Validators.required),
            name: new FormControl(null, Validators.required),
            reply_to: new FormControl(null, Validators.required),
			registration_text: new FormControl(null, Validators.required),
			new_registration_html: new FormControl(null, Validators.required),
            horizontal_logo: new FormControl(null, Validators.required),
            manifest_template: new FormControl(null, Validators.required),
            manifest_ship_only_template: new FormControl(null, Validators.required),
            participation_agreement_link: new FormControl(null, Validators.required),
			privacy_policy_link: new FormControl(null, Validators.required),
            website_user_agreement_link: new FormControl(null, Validators.required),
            wire_transfer_instructions_link: new FormControl(),
            benefits_overview_link: new FormControl(),
			overview_html: new FormControl(null, Validators.required),
        })
    }

    onSubmit(): void {
        log.Debug("in on submit")
        if(this.form.invalid ) {
            log.Debug("form invalid")
            return;
        }

        this.save();
    }

    save() {
        log.Debug("in save")
        this.EventService.UpdateSettings(this.form.value).subscribe(
            (resp) => {
                log.Debug("resp: ", resp)
                log.Debug(this.form.value)
                if(!resp) {
                    this._snack.open("Settings failed to save.", "", {duration:3000, verticalPosition:'top'});
                } else {
                    this.event = resp
                    this._snack.open("Settings saved successfully.", "", {duration:3000, verticalPosition:'top'});
                }
            }
        )
    }

}
