import { RoleService } from '../common/services/role.service';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class RolesResolve {

	constructor(
		public RoleService: RoleService
	){}

	resolve(){
		return this.RoleService.all();
	}

}