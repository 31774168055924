import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { log } from '../log/log';


@Injectable({
  providedIn: 'root'
})

export class ShipmentService {

  constructor(public http: HttpClient) { }

  getShipmentById(id){
  	log.Debug('getShipmentById: ' + id);
  	return this.http.get('http://localhost/responses/shipment_load_step_1.json');
  }

  public currentManifest = {
    Info: {},
    Schedule: {},
  };



}
