import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
	selector: 'inline-text-edit',
	templateUrl: './inline-text-edit.component.html',
	styleUrls: ['./inline-text-edit.component.css']
})
export class InlineTextEditComponent implements OnInit {

	@Input() field;
	@Output() saved = new EventEmitter();

	public editing = false;
	public control = new FormControl();

	constructor() { }

	ngOnInit() {
		this.control.patchValue(this.field);
	}

	save(){
		this.editing = false;
		this.saved.emit(this.control.value);
	}

	cancel(){
		this.editing = false;
	}

}
