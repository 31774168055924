<div class="container-fluid">
	<h1>Add Group</h1>
<!-- 	<pgl-group
		[fieldList]="fieldList"
		(onSave)="save($event)">

	</pgl-group> -->
	<form [formGroup]="form" (submit)="save()">
		<div class="row">
			<div class="col">
				<mat-form-field>
					<input formControlName="name" required matInput name="name" placeholder="Name" />
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-checkbox formControlName="active" name="active">Active</mat-checkbox>
			</div>
		</div>
		<button type="submit" [disabled]="form.invalid" mat-raised-button>Save</button>
		<a style="margin-left: 5px;" color="basic" mat-raised-button (click)="this.location.back();">Cancel</a>
	</form>
</div>