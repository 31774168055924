import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
	providedIn: 'root'
})
export class SupplierService {

	constructor(
		public http: HttpClient
	) { }

    public getSuppliers(params) {
    	return this.http.get( environment.api  + "/admin/suppliers/all", {params: params});
	}

	public getSuppliersForExport(params) {
    	return this.http.get( environment.api  + "/admin/suppliers/all_for_export", {params: params});
	}
}
