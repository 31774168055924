<div class="container-fluid">
    <div class="row gutters" >
          <mat-expansion-panel [expanded]="true" [hideToggle]="false" class="col">
              <mat-expansion-panel-header>
                  <mat-panel-title>Filter Payments</mat-panel-title>
                  <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <form [formGroup]="filterForm">
                <div class="row gutters">
                    <div class="col">
                      <mat-form-field>
                        <mat-label>Company</mat-label>
                        <input type="text" formControlName="company" matInput placeholder="Company" />
                      </mat-form-field>
                    </div>
                  </div>
                  <button mat-raised-button color="primary" (click)="reset()">Reset Filters</button>
                  <button style="margin-left: 10px;" mat-raised-button color="primary" (click)="ExportTOExcel()">Export as .xls</button>
              </form>
          </mat-expansion-panel>
      </div>
      <div id="print-section">
    <div class="row">
      <div class="col" #TABLE>
        <table mat-table #table [dataSource]="payments" matSort class="table mat-elevation-z7 gutters sticky-header">
  
          <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
          <td mat-cell data-label="Company" *matCellDef="let element"> {{element.company}} </td>
          </ng-container>

          <ng-container matColumnDef="credit_card_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Card Type </th>
            <td mat-cell data-label="Card Type" *matCellDef="let element"> {{element.credit_card_type}} </td>
          </ng-container>

          <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
              <td mat-cell data-label="Amount" *matCellDef="let element"> {{element.amount | currency}} </td>
          </ng-container>
          <ng-container matColumnDef="invoice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice </th>
              <td mat-cell data-label="Invoice" *matCellDef="let element"> {{element.invoice}} </td>
          </ng-container>

          <ng-container matColumnDef="transaction_number">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Number </th>
              <td mat-cell data-label="Transaction Number" *matCellDef="let element"> {{element.transaction_number}} </td>
          </ng-container>

          <ng-container matColumnDef="paid">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Paid </th>
              <td mat-cell data-label="Paid" *matCellDef="let element"> {{element.paid | date: 'medium'}} </td>
          </ng-container>
          
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell data-label="Actions" *matCellDef="let element">
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
      </div>
      <button style="margin-left: 10px;" type="button" mat-raised-button color="primary" (click)="print()">Print <mat-icon>print</mat-icon></button>
  </div>