import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ShippingDestinationService } from '../../../common/services/shipping-destination.service';
import { DepartmentService } from '../../../common/services/department.service';

import { ShippingDestination } from '../../../models/shipping-destination';
import { CustomLockDate } from '../../../models/custom-lock-date';
import { log } from '../../../common/log/log';


@Component({
	selector: 'app-lock-dates-dialog',
	templateUrl: './lock-dates-dialog.component.html',
	styleUrls: ['./lock-dates-dialog.component.css']
})
export class LockDatesDialogComponent implements OnInit {

	public destination: ShippingDestination;
	public departments; 
	public dates;
	public addForm;
	public displayedColumns = ['department', 'inbound_lock', 'outbound_lock', 'delete'];
	constructor(
		public dialogRef: MatDialogRef<LockDatesDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private ShippingDestinationService: ShippingDestinationService,
		private DepartmentService: DepartmentService,
		private FormBuilder: FormBuilder
	) { }

	ngOnInit() {
		this.destination = new ShippingDestination();
		this.destination.patch(this.data);
		log.Debug(this.destination);
		this.addForm = this.FormBuilder.group(new CustomLockDate());
		this.addForm.patchValue({shipping_destination_id: this.destination.id});
		this.ShippingDestinationService.getCustomLockDatesFor(this.destination.id).subscribe(r => {
			this.dates = r;
		});

		this.DepartmentService.allByLabelASC().subscribe(r => {
			this.departments = r;
			log.Debug(this.departments);
		});
	}

	add(){
		this.ShippingDestinationService.addCustomLockDate(this.addForm.value).subscribe(r => {
			this.ngOnInit();
		});
	}

	update(data){
		this.ShippingDestinationService.updateCustomLockDate(data).subscribe(r => {
			this.ngOnInit();
		});
	}

	delete(data){
		if(confirm("Are you sure?")){
			this.ShippingDestinationService.deleteCustomLockDate(data.id).subscribe(r => {
				this.ngOnInit();
			});
		}
	}

}
