<div class="container-fluid">
    <div class="row">
        <div class="col-md">
            <mat-card appearance="outlined">
                <mat-card-header>
                    <mat-card-title>
                        <h1>Contract</h1>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <span *ngIf="['Accepted', 'Accepted Before Presented', 'Received', 'Not Presented', 'Exempt'].indexOf(company.contract_status) === -1">The details for your company's participation in the event have been confirmed<span *ngIf="event.has_participation_fee">, including a participation fee of <span style="font-weight: bold;">{{ company.participation_fee | currency }}</span></span></span>

                    <span *ngIf="company.contract_status != 'Accepted Before Presented' && (company.contract_status == 'Accepted' || company.contract_status == 'Received')">Thank you for accepting your contract<span *ngIf="event.has_participation_fee"> with a participation fee of <span style="font-weight: bold;">{{ company.participation_fee | currency }}</span></span>.<br><br></span>

                    <span *ngIf="['Accepted', 'Accepted Before Presented', 'Received'].indexOf(company.contract_status) === -1"><p>Please review the event <a href="{{ event.participation_agreement_link }}" target="_blank">participation agreement</a> and click the accept button below to indicate your company's acceptance.</p></span>

                    <span *ngIf="company.contract_status == 'Accepted Before Presented'"><p>Thank you for accepting your contract.<span *ngIf="event.has_participation_fee"> Your participation fees will be posted on this site at a later date.</span></p></span>

                    <div *ngIf="event.has_participation_fee" [innerHTML]="event.overview_html"></div>
                </mat-card-content>
                <mat-card-actions>
                    <button *ngIf="['Accepted', 'Accepted Before Presented', 'Received'].indexOf(company.contract_status) === -1" mat-raised-button color="primary" type="button" (click)="accept()">Accept</button>
                    <a *ngIf="company.contract_status == 'Accepted' || company.contract_status == 'Accepted Before Presented'" mat-raised-button color="primary" type="button" target="_blank" href="{{ event.participation_agreement_link }}">Contract</a>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>
<app-footer [event]="event"></app-footer>
