
<form (submit)="save()" [formGroup]="form">
    <div class="container-fluid tall">
        <div class="row">
            <div class="col">
                <mat-card appearance="outlined">
                    <mat-card-content>
                        <h2>Edit Registration Information</h2>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-card appearance="outlined">
                    <mat-card-header>
                        <mat-card-title>Basic Information</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-form-field>
                            <input matInput type="text" name="badge_first_name" formControlName="badge_first_name" placeholder="Badge First Name" disabled="true"/>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput name="badge_last_name" type="text" formControlName="badge_last_name" placeholder="Badge Last Name" />
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput name="mobile_phone" type="text" mask="(000-000-0000)" [dropSpecialCharacters]="['(', ')']" formControlName="mobile_phone" placeholder="Mobile Phone" />
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput name="email" type="text" formControlName="email" placeholder="Email" />
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput name="title" type="text" formControlName="title" placeholder="Title" />
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Onsite Role</mat-label>
                            <mat-select formControlName="onsite_role" name="onsite_role">
                                <mat-option [value]="'Set Up/Tear Down Crew'">Set Up/Tear Down Crew</mat-option>
                                <mat-option [value]="'Show Presenter'">Show Presenter</mat-option>
                                <mat-option [value]="'Both'">Both</mat-option>
                            </mat-select>
                        </mat-form-field>
                            <mat-checkbox name="primary_onsite" formControlName="primary_onsite">Primary Onsite Contact</mat-checkbox>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col">
                <mat-card appearance="outlined">
                    <mat-card-header>
                        <mat-card-title>In Case Of Emergency Please Contact</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-form-field>
                            <input matInput type="text" name="emergency_contact" formControlName="emergency_contact" placeholder="Name" />
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="text" formControlName="emergency_contact_relationship" placeholder="Relationship" />
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="text" mask="(000-000-0000)" [dropSpecialCharacters]="['(', ')']" formControlName="emergency_contact_phone" placeholder="Phone" />
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button type="submit" mat-raised-button>Save Registration</button>
            </div>
        </div>
    </div>
</form>
