import { LogoResolve } from './logo.resolve';
import { AppNameResolve } from './app-name.resolve';
import { AppReplyToResolve } from './app-reply-to.resolve';
import { LoginTextResolve } from './login-text.resolve';
import { RegistrationTextResolve } from './registration-text.resolve';

export const SETTINGS_PROVIDERS = [
    LogoResolve,
    AppNameResolve,
    AppReplyToResolve,
    LoginTextResolve,
    RegistrationTextResolve,
]
