<mat-toolbar color="accent">
    <div class="maxcontainer">
        <a class="space" [routerLink]="['/', 'login']"><img style="max-height: 60px;" src="{{ event.logo }}" /></a>
    </div>
</mat-toolbar>
<div class="container">
    <div class="row">
        <div class="col">
            <mat-card appearance="outlined">
                <mat-card-header>
                    <mat-card-title>Reset Password</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form [formGroup]="resetPasswordForm" (submit)="resetPassword()">
                        <mat-form-field>
                            <input matInput name="password" formControlName="password" placeholder="Password" type="password" />
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput name="confirmPassword" formControlName="confirmPassword" placeholder="Confirm Password" type="password" />
                        </mat-form-field>
                        <button mat-raised-button color="accent">Reset Password</button>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
