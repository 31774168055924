import { IModel, Model } from './model';


export class Group extends Model {
    name: string = null;
    active: boolean = null;

    constructor(properties?: Object) {
        super(properties)
        this.patch(properties)
    }
}