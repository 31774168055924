<div class="container padding-height">
  <div class="row">
    <div class="col" *ngIf="invoiceAmount">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>Pay Invoice</mat-card-title>
          <mat-card-subtitle>{{ event.name }} Invoice Payment</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-list>
              <mat-list-item>
                  Participation Fee: {{invoiceAmount.sub_total | currency}}
              </mat-list-item>
              <mat-list-item>
                  Online Payment Fee: {{invoiceAmount.fees | currency}}
              </mat-list-item>
              <mat-list-item>
                  Total: {{invoiceAmount.total | currency}}
              </mat-list-item>
            </mat-list>
            <form [formGroup]="paymentForm" (submit)="sendPayment()">
              <mat-form-field>
                <input matInput formControlName="card_number" name="card_number" placeholder="Card Number" />
              </mat-form-field>
              <mat-form-field>
                <input matInput formControlName="card_exp" name="card_exp" placeholder="Card Exp (MM/YY)" />
              </mat-form-field>
              <mat-form-field>
                <input matInput formControlName="card_code" name="card_code" placeholder="Card Code" />
              </mat-form-field>
              <button mat-raised-button>Submit Payment</button>
            </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>  
</div>