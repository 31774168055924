<div style="min-height:calc(100vh - 120px);" class="container">
	<form [formGroup]="searchForm">
		<div class="row">
			<div class="col">
				<mat-form-field>
					<input matInput placeholder="Company" name="company" formControlName="company">
				</mat-form-field>
			</div>
			<div class="col">
				<mat-form-field>
					<mat-label>Department</mat-label>
					<mat-select formControlName="department">
						<mat-option value="1">D23</mat-option>
						<mat-option value="2">D24</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field>
					<input matInput formControlName="sku" placeholder="SKU" name="sku">
				</mat-form-field>
			</div>
			<div class="col">
				<mat-form-field>
					<mat-label>Return Option</mat-label>
					<mat-select formControlName="return_option">
						<mat-option value="0">All</mat-option>
						<mat-option value="-1">Discard</mat-option>
						<mat-option value="-2">Donate</mat-option>
						<mat-option value="-3">Special Arrangement</mat-option>
						<mat-option value="4">Return via Show Carrier</mat-option>
						<mat-option value="7">Return via Other Carrier</mat-option>
						<mat-option value="8">Return via UPS</mat-option>
						<mat-option value="9">Return via FedEx</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field>
					<input matInput formControlName="manifest_number" placeholder="Manifest Number">
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<button type="submit" mat-raised-button color="accent">Search</button>
			</div>
		</div>
	</form>
</div>