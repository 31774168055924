import {InvoiceTemplatesComponent} from './invoice-templates.component';
import {AddInvoiceTemplateComponent} from './add-invoice-template/add-invoice-template.component';
import {EditInvoiceTemplateComponent} from './edit-invoice-template/edit-invoice-template.component';

export const INVOICE_TEMPLATE_ROUTES = [
	{
		path: 'invoice_templates',
		component: InvoiceTemplatesComponent
	},
	{
		path: 'invoice_templates/add',
		component: AddInvoiceTemplateComponent
	},
	{
		path: 'invoice_templates/edit/:id',
		component: EditInvoiceTemplateComponent
	}
];

export const INVOICE_TEMPLATE_COMPONENTS = [
	InvoiceTemplatesComponent,
	AddInvoiceTemplateComponent,
	EditInvoiceTemplateComponent
];