import { PaymentComponent } from './payment/payment.component';
import { Routes } from '@angular/router';
import { PayInvoiceComponent } from './pay-invoice/pay-invoice.component';
import { UnderConstructionGuardService as UnderConstructionGuard } from '../common/services/under-construction-guard.service';
import { EventResolve } from '../event';

export const PAYMENT_ROUTES : Routes = [
    {
        path: "invoices/pay",
        component: PaymentComponent,
        resolve: {
        	event: EventResolve,
        },
        canActivate: [UnderConstructionGuard]
    },
    {
        path: "invoices/pay_invoice/:id",
        component: PayInvoiceComponent,
        resolve: {
        	event: EventResolve,
        },
        canActivate: [UnderConstructionGuard]
    }
]
