import { Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { PageComponent } from './page/page.component';
import { AddPageComponent } from './add-page/add-page.component';
import { EditPageComponent } from './edit-page/edit-page.component';

export const PAGES_ROUTES: Routes = [
    {
        path: '',
        component: PagesComponent,
    },
    {
        path:"add",
        component: AddPageComponent
    },
    {
        path: "edit/:id",
        component: EditPageComponent
    },
]

export const PAGES_COMPONENTS = [
    PagesComponent,
    PageComponent,
    AddPageComponent,
    EditPageComponent
]
