<div class="container-fluid">
    <div class="row" style="margin-bottom:16px;">
		<div class="col">
			<button *ngIf="authService.HasPermission(authGroups.SUPER_ADMIN)" mat-raised-button color="primary" (click)="reassignInvoiceNumbers()"><mat-icon>assignment_late</mat-icon>Re-assign Invoice Numbers</button>
		</div>
	</div>
    <form [formGroup]="filterForm">
        <mat-expansion-panel [expanded]="true" [hideToggle]="false" class="col margin-top">
            <mat-expansion-panel-header>
                <mat-panel-title>Search</mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <mat-select formControlName="department" placeholder="Department">
                            <mat-option [value]=null>All</mat-option>
                            <mat-option *ngFor="let d of departments" [value]=d.name>{{d.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput formControlName="company" placeholder="Company" />
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput formControlName="transaction_number" placeholder="Transaction Number" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput formControlName="invoice_number" placeholder="Invoice Number" />
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-label>Hidden Invoice</mat-label>
                        <mat-select formControlName="hide_invoice">
                            <mat-option [value]=null>All</mat-option>
                            <mat-option [value]=1>Yes</mat-option>
                            <mat-option [value]=0>No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="invoice_type">
                            <mat-option [value]=null>All</mat-option>
                            <mat-option value="Default">Default</mat-option>
                            <mat-option value="Custom">Custom</mat-option>
                            <mat-option value="Override">Override</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <button mat-raised-button color="primary" (click)="reset()">Reset Filters</button>
            <button style="margin-left: 10px;" mat-raised-button color="primary" (click)="ExportTOExcel()">Export as .xls</button>
        </mat-expansion-panel>
    </form>
    <div id="print-section">
        <div class="row">
            <div class="col" #TABLE>
                <table mat-table #table [dataSource]="invoices" matSort class="table mat-elevation-z7 gutters sticky-header">

                    <ng-container matColumnDef="department">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                        <td mat-cell data-label="Department" *matCellDef="let element"> {{element.department}} </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                        <td mat-cell data-label="Company" *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>


                    <ng-container matColumnDef="participation_fee">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Participation Fee </th>
                        <td mat-cell data-label="Participation Fee" *matCellDef="let element">
                            <span *ngIf="element.participation_fee">
                                {{element.participation_fee | currency:'USD':'symbol'}}
                            </span>
                            <span *ngIf="!element.participation_fee">
                                --
                            </span>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="invoice_number">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice Number </th>
                        <td mat-cell data-label="Invoice Number" *matCellDef="let element"> <button mat-button style="color: #0000EE; text-decoration: underline;" (click)="downloadXLS(element)">{{element.invoice_number}}</button> </td>
                    </ng-container>

                    <ng-container matColumnDef="transaction_number">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Number </th>
                        <td mat-cell data-label="Transaction Number" *matCellDef="let element"> {{element.transaction_number}} </td>
                    </ng-container>


                    <ng-container matColumnDef="invoice_downloaded">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice Downloaded </th>
                        <td mat-cell data-label="Invoice Downloaded" *matCellDef="let element">
                            <mat-icon *ngIf="element.invoice_downloaded">
                                done
                            </mat-icon>
                            <mat-icon color="accent" *ngIf="!element.invoice_downloaded">
                                block
                            </mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="invoice_paid">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice Paid </th>
                        <td mat-cell data-label="Invoice Paid" *matCellDef="let element">
                            <mat-icon *ngIf="element.invoice_paid">
                                done
                            </mat-icon>
                            <mat-icon color="accent" *ngIf="!element.invoice_paid">
                                block
                            </mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="invoice_type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                        <td mat-cell data-label="Type" *matCellDef="let element"> {{element.invoice_type}} </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="action-column-3">Actions</th>
                        <td mat-cell data-label="Actions" *matCellDef="let element">

                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
    <button style="margin-left: 10px;margin-top: 10px;" type="button" mat-raised-button color="primary" (click)="print()">Print <mat-icon>print</mat-icon></button>
</div>
