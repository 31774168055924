// import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component } from '@angular/core';
import { AuthService, AuthGroups } from './common/services/auth.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SidebarService, ViewState } from './common/services/sidebar.service';
import { MenuService } from './common/services/menu.service';
import { UserService } from './common/services/user.service';
import { CompanyService } from './common/services/company.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { log } from './common/log/log';
import { AnalyticsService } from './common/services/analytics.service';
import { Subject, Observable, EMPTY } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { AppService } from './common/services/app.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'product-walk-client';
    mobileQuery: MediaQueryList;
    menus = [];
    allMenus;
    user;
    userRole;
    contractStatus;
    snack;
    shipOnly: boolean;
    currentRoute = "Product Walk 2020";
    snackDismissed: boolean = false;
    private _destroyed$ = new Subject<void>();
    private _mobileQueryListener: () => void;
    authGroups = AuthGroups
    event;
    companyType;
    isArchive;
    state$: Observable<ViewState> = EMPTY
    isMobile$ = this.state$.pipe(map(r => r.show))
    constructor(
        public us: UserService,
        public AuthService: AuthService,
        public SidebarService: SidebarService,
        public router: Router,
        public route: ActivatedRoute,
        public changeDetectorRef: ChangeDetectorRef,
        // public media: MediaMatcher,
        public MenuService: MenuService,
        public cs: CompanyService,
        private _snackBar: MatSnackBar,
        public AnalyticsService: AnalyticsService,
        public TitleService: Title,
        private appService: AppService
    ) {

        this.user = this.us.getLoggedInUser()
        this.state$ = this.SidebarService.state$

        this.isArchive = window.location.origin.includes("archive") || window.location.origin.includes("2021");
        log.Debug("url: ", window.location.origin)


        this.router.events.pipe(filter((r) => r instanceof NavigationEnd)).subscribe(val => {
            this.contractStatus = this.AuthService.getContractStatus()
            log.Debug("route events: ", val);
            log.Debug("contract status in app component: ", this.contractStatus)
            this.shipOnly = this.AuthService.getShipOnly()
            this.companyType = this.AuthService.getCompanyType()
            this.userRole = this.AuthService.getRole()


            // this.setMobile();

            //TODO update this so only suppliers see snack
            if (this.userRole == 'supplier_primary_contact' &&
                !this.snackDismissed && this.user.is_primary &&
                !this.shipOnly && (this.contractStatus == "Presented" ||
                this.contractStatus == "Not Presented") && this.companyType != "The Home Depot"
            ) {
                this.snackDismissed = false
                log.Debug("companyType in if: ", this.companyType)

                this.snack = this._snackBar.open("Please review and accept your contract.", "Go", {
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                    panelClass: ['yellow-snackbar']
                })
                this.snack.onAction().subscribe(a => {
                    this.appService.navigate(['/app/contract']);
                })
                this.snack.afterDismissed().subscribe(val => {
                    this.snackDismissed = true
                })
            } else {
                if (this.snack) {
                    this.snack.dismiss()
                }
            }
        });

        // this.user = new User(this.AuthService.getUser());
        //---- removed 11/27/2023 user already exists.
        // this.us.getByID(this.user['id']).subscribe((u: User) => {
        //     this.user = u;
        // });
        if (this.userRole !== "supplier") {
            this.MenuService.getAllActiveSorted().subscribe(r => {
                this.allMenus = r;
                log.Debug("all menus: ", r)
                if (this.shipOnly) {
                    this.allMenus.forEach(menu => {
                        if (menu["name"] != 'Invoice' && menu["name"] != 'Registration' && this.hasPermission(menu["allowed_role"])) {
                            this.menus.push(menu)
                        }
                    });
                } else {
                    this.allMenus.forEach(menu => {
                        if (this.hasPermission(menu["allowed_role"])) {
                            this.menus.push(menu)
                        }
                    });
                }

                this.menus.forEach(menu => {
                    if (this.router.isActive(menu["href"], false)) {
                        this.currentRoute = menu["name"];
                    }
                });
            });
        } else {
            this.MenuService.getUserMenus().subscribe(r => {
                this.allMenus = r;
                if (this.shipOnly) {
                    this.allMenus.forEach(menu => {
                        if (menu["name"] != 'Invoice' && menu["name"] != 'Registration' && this.hasPermission(menu["allowed_role"])) {
                            this.menus.push(menu)
                        }
                    });
                } else {
                    this.allMenus.forEach(menu => {
                        if (this.hasPermission(menu["allowed_role"])) {
                            this.menus.push(menu)
                        }
                    });
                }
                this.menus.forEach(menu => {
                    if (this.router.isActive(menu["href"], false)) {
                        this.currentRoute = menu["name"];
                    }
                });
            });
        }

        this.router.events.pipe(
            takeUntil(this._destroyed$),
            filter(r => r instanceof NavigationEnd),
            switchMap(_ => this.AnalyticsService.create(
                { item: this.router.url, user_id: this.user.id, company_id: this.user.company_id }
            ))
        ).subscribe(r => log.Debug("analytic: ", r))

    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            event,
        }) => {
            log.Debug("data: ", data)
            this.event = data.event
        })

        log.Debug("route snapshot: ", this.route.paramMap, this.route.snapshot)

        this.TitleService.setTitle(this.event.name)
    }


    navigateTo(route) {
        this.router.navigateByUrl("/app/" + route);
    }

    setMobile() {
        // this.SidebarService.isMobile = this.mobileQuery.matches;
        this.SidebarService.close();
    }

    toggleMenu() {
        this.SidebarService.toggle();
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    hasMenu() {
        return this.SidebarService.pageHasSidebar();
    }

    hasPermission(allowedRole: string): boolean {
        if (!allowedRole || allowedRole == "") {
            return true
        } else if (allowedRole == "admin") {
            return this.AuthService.HasPermission(this.authGroups.ADMIN)
        } else if (allowedRole == "supplier_primary_contact") {
            return this.AuthService.HasPermission(this.authGroups.SUPPLIER_PRIMARY_CONTACT)
        } else if (allowedRole == "supplier_coordinator") {
            return this.AuthService.HasPermission(this.authGroups.SUPPLIER_COORDINATOR)
        }

        return false
    }

    logout() {
        this.AuthService.logout();
        this.appService.navigate(['login']);
    }
}
