import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AnalyticsService } from '../../common/services/analytics.service';

@Injectable()
export class AnalyticsResolve implements Resolve<any> {

	constructor(
        public AnalyticsService: AnalyticsService,
	){}

	resolve(route, state){
        return this.AnalyticsService.getAll();
	}
}
