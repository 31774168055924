import { FormGroup } from '@angular/forms';
import { log } from '../common/log/log';

export interface IModel {
    id?: number;
    created: string;
    modified: string;
}

export class Model {

    id: number;


    constructor(options?) {
       for (var prop in options) {
        if (options.hasOwnProperty(prop)) {
            this[prop] = options[prop];
        }
       }
    }

    patch(options: Object){
        for (var prop in options)
        if (options.hasOwnProperty(prop))
            this[prop] = options[prop];
    }

    newObj(obj) {
        return new Model(obj);
    }

    toJSON() {
        const proto = Object.getPrototypeOf(this);
        const jsonObj: any = Object.assign({}, this);

        Object.entries(Object.getOwnPropertyDescriptors(proto))
          .filter(([key, descriptor]) => typeof descriptor.get === 'function')
          .map(([key, descriptor]) => {
            if (descriptor && key[0] !== '_') {
              try {
                const val = (this as any)[key];
                jsonObj[key] = val;
              } catch (error) {
                console.error(`Error calling getter ${key}`, error);
              }
            }
          });

        return jsonObj;
    }
    copy() {
        return this.newObj(JSON.parse(JSON.stringify(this, (key, value) => key === 'parent' || key === 'id' ? null : value)));
    }
}
