import { Component, OnInit, Input } from '@angular/core';
import { log } from '../common/log/log';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    @Input()
    event;
    currentYear: number = new Date().getFullYear();

  constructor() { }

  ngOnInit() {
      log.Debug("event in footer: ", this.event)
  }

}
