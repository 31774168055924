<div class="container-fluid">
    <div class="row" >
        <div class="col">
            <button mat-raised-button (click)="openInvite()" color="accent"><mat-icon>add</mat-icon>Import Invites</button>
            <a style="float:right;" mat-raised-button href="/assets/invite_template.xlsx" color="accent" download><mat-icon>archive</mat-icon> Invite Template</a>
        </div>
    </div>
    <div class="row gutters" >
        <mat-expansion-panel [expanded]="true" [hideToggle]="false" class="col margin-top">
            <mat-expansion-panel-header>
                <mat-panel-title>Filter Invites</mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <form [formGroup]="filterForm">
                <div class="row gutters">
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Company</mat-label>
                            <input type="text" formControlName="name" matInput placeholder="Company" />
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Department</mat-label>
                            <mat-select formControlName="department_id">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let d of departments" [value]=d.id>{{d.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Group</mat-label>
                            <mat-select formControlName="group_id">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let g of groups" [value]=g.id>{{g.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Invite Sent</mat-label>
                            <mat-select formControlName="invite_sent_bool">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option [value]=1>Sent</mat-option>
                                <mat-option [value]=0>Not Sent</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <button style="margin-top: 20px;" mat-raised-button (click)="reset()">Reset Filters</button>
            </form>
        </mat-expansion-panel>
    </div>
    <div id="print-section">
        <div class="row gutters">
            <div class="col" #TABLE>
                <table mat-table #table [dataSource]="companies" matSort class="table mat-elevation-z7 gutters sticky-header">
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox [checked]="allSelected()" (change)="toggleAll()"></mat-checkbox>
                        </th>
                        <td mat-cell data-label="Select" *matCellDef="let element"><mat-checkbox [checked]="isSelected(element)" (change)="toggleSelection($event, element)"></mat-checkbox>
                    </ng-container>

                    <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                    <td mat-cell data-label="Company" *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="department">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                    <td mat-cell *matCellDef="let element"> {{element.department}} </td>
                    </ng-container>

                    <ng-container matColumnDef="contact_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact Name</th>
                    <td mat-cell data-label="Contact Name" *matCellDef="let element"> {{element.contact_name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="contact_email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                    <td mat-cell data-label="Contact Email" *matCellDef="let element"> {{element.contact_email}} </td>
                    </ng-container>

                    <ng-container matColumnDef="company_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Type</th>
                    <td mat-cell data-label="Company Type" *matCellDef="let element"> {{element.company_type}} </td>
                    </ng-container>

                    <ng-container matColumnDef="participation_fee">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Participation Fee </th>
                    <td mat-cell data-label="Participation Fee" *matCellDef="let element">
                        <span *ngIf="element.participation_fee">
                            {{element.participation_fee | currency:'USD':'symbol'}}
                        </span>
                        <span *ngIf="!element.participation_fee">
                            --
                        </span>
                    </td>
                    </ng-container>

                    <ng-container matColumnDef="invitation_code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Invitation Code </th>
                    <td mat-cell data-label="Invitation Code" *matCellDef="let element"> {{element.invitation_code}} </td>
                    </ng-container>

                    <ng-container matColumnDef="invite_sent">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Invite Sent </th>
                    <td mat-cell data-label="Invite Sent" *matCellDef="let element">
                        <mat-icon *ngIf="element.invite_sent_bool">
                            done
                        </mat-icon>
                        <mat-icon color="accent" *ngIf="!element.invite_sent_bool">
                            block
                        </mat-icon>
                    </td>
                    </ng-container>

                    <ng-container matColumnDef="last_invite_sent">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Invite Sent </th>
                    <td mat-cell data-label="Last Invite Sent" *matCellDef="let element">
                        <span *ngIf="element.invite_sent">
                            {{element.invite_sent | date:'short' }}
                        </span>
                        <span color="accent" *ngIf="!element.invite_sent">
                            --
                        </span>
                    </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell data-label="Actions" *matCellDef="let element">
                            <a mat-icon-button color="primary" matTooltip="Send Invite to Company" (click)="sendInvite(element.id, element.name)"><mat-icon>email</mat-icon></a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button color="accent" *ngIf="selected.length > 0" (click)="sendInvites()">Send {{selected.length}} Invites</button>
            </div>
        </div>
    </div>
    <button style="margin-left: 10px;" type="button" mat-raised-button color="primary" (click)="print()">Print <mat-icon>print</mat-icon></button>
</div>