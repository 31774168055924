<div class="container-fluid">
	<div class="row">
		<div class="col">
			<a [routerLink]="['add']" mat-raised-button color="accent"><mat-icon>add</mat-icon>Add Department</a>
		</div>
		<div class="col">
			<form [formGroup]="filterForm">
				<mat-form-field>
					<input matInput type="text" formControlName="name" placeholder="Search Departments" />
				</mat-form-field>
			</form>
		</div>
	</div>
	<div id="print-section">
	<div class="row">
		<div class="col">
			<table mat-table #table [dataSource]="departments" matSort class="table mat-elevation-z7 gutters sticky-header">
				<ng-container matColumnDef="name">
				   <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
				   <td mat-cell data-label="Name" *matCellDef="let element"> {{element.name}} </td>
				</ng-container>

				<ng-container matColumnDef="group_id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Group</th>
					<td mat-cell data-label="Group" *matCellDef="let element">{{getGroupNameById(element.group_id)}}</td>
				</ng-container>

				<ng-container matColumnDef="label">
				  	<th mat-header-cell *matHeaderCellDef mat-sort-header>Label</th>
				  	<td mat-cell data-label="Label" *matCellDef="let element">{{element.label}}</td>
				</ng-container>

				<ng-container matColumnDef="abbreviation">
				  	<th mat-header-cell *matHeaderCellDef mat-sort-header>Abbreviation</th>
				  	<td mat-cell data-label="Abbreviation" *matCellDef="let element">{{element.abbreviation}}</td>
				</ng-container>

			  	<ng-container matColumnDef="coordinator_id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Coordinator</th>
					<td mat-cell data-label="Group" *matCellDef="let element">{{getCoordinatorNameById(element.coordinator_id)}}</td>
				</ng-container>

				<ng-container matColumnDef="active">
				  	<th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
				  	<td data-label="Active" mat-cell *matCellDef="let element">
				  		<mat-icon *ngIf="element.active">
				    		done
				    	</mat-icon>
				    	<mat-icon color="accent" *ngIf="!element.active">
				    		block
				    	</mat-icon>
				  	</td>
				</ng-container>

				<ng-container matColumnDef='shipping_only'>
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Shipping Only</th>
					<td data-label='Shipping Only' mat-cell *matCellDef='let element'>
						<mat-icon *ngIf='element.shipping_only'>
							done
						</mat-icon>
						<mat-icon color='accent' *ngIf='!element.shipping_only'>
							block
						</mat-icon>
					</td>
				</ng-container>

				<ng-container matColumnDef="actions">
				  	<th mat-header-cell *matHeaderCellDef>Actions</th>
				  	<td mat-cell data-label="Actions" *matCellDef="let element">
				  		<a mat-icon-button color="primary" [routerLink]="['edit', element.id]"><mat-icon>edit</mat-icon></a>
				  		<a mat-icon-button color="accent" (click)="delete(element.id)"><mat-icon>delete</mat-icon></a>
				  	</td>
				</ng-container>

				  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
				  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
			<mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
		</div>
	</div>
	</div>
	<button style="margin-left: 10px;" type="button" mat-raised-button color="primary" (click)="print()">Print <mat-icon>print</mat-icon></button>
</div>
