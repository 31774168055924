<div class="container-fluid personal-company">
	<div class="row">
		<div class="col-md">
			<mat-card appearance="outlined">
				<mat-card-header>
					<mat-card-title>
						<h1>{{ name }}</h1>
					</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<div class="row">
						<div class="col-md">
							<mat-list>
								<mat-list-item>
									<h3 mat-line>Department</h3>
									<p mat-line>{{ department_name }}</p>
								</mat-list-item>
								<mat-list-item>
									<h3 mat-line>Invitation Code</h3>
									<p mat-line>{{ invitation_code }}</p>
								</mat-list-item>
								<mat-list-item *ngIf="company.contract_status != 'Not Presented' && company.contract_status != 'Exempt' && company.contract_status != 'Accepted Before Presented' && isPrimary && event.has_participation_fee">
									<h3 mat-line>Participation Fee</h3>
									<p mat-line>{{ participation_fee | currency }}</p>
								</mat-list-item>
							</mat-list>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<form [formGroup]="form" (submit)="save()">
	<div class="row">
		<div *ngIf="!shipOnly" class="col-md" [formGroup]="billing">
			<mat-card appearance="outlined">
				<mat-card-header>
					<mat-card-title>Billing Address</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<mat-form-field>
						<mat-label>Country</mat-label>
						<mat-select [(ngModel)]="billingCountry" formControlName="country_id" name="country">
							<mat-option  *ngFor="let country of countries" [value]=country.id>{{country.long_name}}</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field *ngIf="billingCountry == 4">
						<input matInput formControlName="other_country" name="other_country" placeholder="Other Country" />
					</mat-form-field>
					<mat-form-field>
						<input matInput name="address_1" placeholder="Address 1" formControlName="address_1" />
					</mat-form-field>
					<mat-form-field>
						<input matInput name="address_2" placeholder="Address 2" formControlName="address_2" />
					</mat-form-field>
					<mat-form-field>
						<input matInput name="address_3" placeholder="Address 3" formControlName="address_3" />
					</mat-form-field>
					<mat-form-field>
						<input matInput name="city" placeholder="City" formControlName="city" />
					</mat-form-field>
					<mat-form-field *ngIf="billingCountry == 1">
						<mat-label>State</mat-label>
						<mat-select formControlName="state_id" name="state">
							<mat-option *ngFor="let state of states" [value]=state.id>{{state.long_name}}</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field *ngIf="billingCountry > 1">
						<input matInput formControlName="other_state" name="other_state" placeholder="State" />
					</mat-form-field>
					<mat-form-field>
						<input matInput name="postal_code" placeholder="Postal Code" formControlName="postal_code" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>
		<div class="col-md" [formGroup]="mailing">
			<mat-card appearance="outlined">
				<mat-card-header>
					<mat-card-title>Mailing Address</mat-card-title>
				</mat-card-header>
				<div class="row">
					<div *ngIf="!shipOnly" class="col">
						<mat-checkbox *ngIf="isPrimary" [checked]="sameAsBilling" (change)="sameAsBilling = !sameAsBilling">Same as Billing Address</mat-checkbox>
						<h4 style="margin-left:15px;" *ngIf="!isPrimary && sameAsBilling">Same as Billing Address</h4>
					</div>
				</div>
				<mat-card-content *ngIf="!sameAsBilling">
					<mat-form-field>
						<mat-label>Country</mat-label>
						<mat-select [(ngModel)]="mailingCountry" formControlName="country_id" name="country">
							<mat-option  *ngFor="let country of countries" [value]=country.id>{{country.long_name}}</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field *ngIf="mailingCountry == 4">
						<input matInput formControlName="other_country" name="other_country" placeholder="Other Country" />
					</mat-form-field>
					<mat-form-field>
						<input matInput name="address_1" placeholder="Address 1" formControlName="address_1" />
					</mat-form-field>
					<mat-form-field>
						<input matInput name="address_2" placeholder="Address 2" formControlName="address_2" />
					</mat-form-field>
					<mat-form-field>
						<input matInput name="address_3" placeholder="Address 3" formControlName="address_3" />
					</mat-form-field>
					<mat-form-field>
						<input matInput name="city" placeholder="City" formControlName="city" />
					</mat-form-field>
					<mat-form-field *ngIf="mailingCountry == 1">
						<mat-label>State</mat-label>
						<mat-select formControlName="state_id" name="state">
							<mat-option *ngFor="let state of states" [value]=state.id>{{state.long_name}}</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field *ngIf="mailingCountry > 1">
						<input matInput formControlName="other_state" name="other_state" placeholder="State" />
					</mat-form-field>
					<mat-form-field>
						<input matInput name="postal_code" placeholder="Postal Code" formControlName="postal_code" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>

	</div>
	<button mat-raised-button color="primary" type="submit">Submit</button>
	</form>
</div>
<app-footer [event]="event"></app-footer>
