import { Injectable } from '@angular/core';
import { switchMap, map, filter } from 'rxjs/operators';
import { log } from '@common/log/log';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class AppService {

	private _archiveYear;

	get archiveYear() {
		return this._archiveYear;
	}

	set archiveYear( year: string ) {
		this._archiveYear = year;
	}

	get hasArchive() {
		return !!this.archiveYear
	}

    constructor(
    	private router: Router,
    ){}

    isActiveFilter() {
        return map((items: any[]) => {
            log.Debug("items: ", items)
            return items.filter( item => item.active )
        })
    }

    navigate( commands: any[], extras: NavigationExtras= { skipLocationChange: false }) {
    	if( this.hasArchive ) {
    		this.router.navigate([this.archiveYear, ...commands], extras);
    	} else {
    		this.router.navigate(commands, extras);
    	}
    }

}
