import { IFormGroup } from '../common/generic';

export const ADDRESS_FIELDS: IFormGroup = {
    country: {
        name: 'country',
        label: 'Country',
        type: 'select'
    },
    address1: {
        name: 'address_1',
        label: 'Address 1'
    },
    address2: {
        name: 'address_2',
        label: 'Address 2'
    },
    address3: {
        name: 'address_3',
        label: 'Address 3'
    },
    city: {
        name: 'city',
        label: 'City'
    },
    state: {
        name: 'state',
        label: 'State',
        type: 'select'
    },
    postalCode: {
        name: 'postal_code',
        label: 'Postal Code'
    }
};

export const COMPANY_FIELDS: IFormGroup = {
    name: {
      name: 'name',
      label: 'Name'
    },
    active: {
        name: 'active',
        label: 'Active',
        type: 'checkbox'
    },
    code: {
      name: 'code',
      label: 'Invitation Code'
    },
    fee: {
      name: 'fee',
      label: 'Participation Fee'
    },
    template: {
      name: 'template',
      label: 'Invoice Template',
      type: 'select',
      options: [
          {value: 1, name: 'Template A'},
          {value: 2, name: 'Template B'}
      ],
      optionValue: (option) => option.value,
      optionDisplay: (option) => option.name
    },
    department: {
        name: 'department',
        label: 'Department',
        type: 'select',
        options: [
            {value: 1, name: 'D23'},
            {value: 2, name: 'D24'}
        ],
        optionValue: (option) => option.value,
        optionDisplay: (option) => option.name
    },
    billing: {
        name: 'billing',
        label: 'Billing Address',
        group: ADDRESS_FIELDS
    },
    mailing: {
        name: 'mailing',
        label: 'Mailing Address',
        group: ADDRESS_FIELDS
    }
  };

