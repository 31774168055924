import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Routes, RouterModule } from '@angular/router';

@Injectable({
	providedIn: 'root'
})

export class EventService {

	constructor(
		public http: HttpClient
	) { }

	GetEvent() {
		return this.http.get(environment.api + "/events/current");
	}

	UpdateSettings( data ) {
		return this.http.put(environment.api + "/events/update_settings", data);
	}
}
