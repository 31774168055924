import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';
import { FormGroup, FormBuilder } from '@angular/forms';
import { log } from '@common/log/log';
import { FieldBuilder, IField } from '../admin/common/generic';
import { USER_FIELD_LIST, USER_PASSWORD_FIELD_LIST, MatchPassword } from '../admin/users/fields';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../common/services/user.service';
import { AuthService } from '../common/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css']
})
export class PersonalComponent implements OnInit {

    id;

    currentUser;
    form: FormGroup;
    passwordForm: FormGroup;
    event;
    isArchive;
    potentialUsers;

  constructor(
      private _fb: FormBuilder,
      private _snack: MatSnackBar,
      private us: UserService,
      private as: AuthService,
      public route: ActivatedRoute,
      private matchPassword: MatchPassword
  ) {
    this.form = this._fb.group(FieldBuilder.getGroup(USER_FIELD_LIST, this._fb));
  }

  ngOnInit() {


      this.currentUser = this.us.getLoggedInUser();
      log.Debug("user from localStorage: ", this.currentUser)

      // this.us.getByID(this.currentUser['id']).subscribe((u: User) => {
      //   log.Debug("user in get by: ", u);
      //   this.id = u.id;
      //   this.form.patchValue(u);
      // });
      forkJoin([
        this.us.getByID(this.currentUser['id']),
        this.us.getPotentialOverideUsersByUserID(this.currentUser['id'])
      ]).subscribe((
        [user, potentialUsers]:
        [User, User[]]
      ) => {
        this.form.patchValue(user);
        this.id = user.id;
        this.potentialUsers = potentialUsers;
      });
      this.passwordForm = this._fb.group(FieldBuilder.getGroup(USER_PASSWORD_FIELD_LIST, this._fb), { validators: [this.matchPassword.validate] });
      this.form.addControl('passwordGroup', this.passwordForm);
      log.Debug(this.form);

      this.route.data.subscribe((data: {
          event,
      }) => {
          log.Debug("data: ", data)
          this.event = data.event
      })
      this.isArchive = window.location.origin.includes("archive");
  }

  save() {
      let user = new User(this.form.value);
      user.id = this.id;
      user.password = this.form.value.passwordGroup.password;
      log.Debug("user: ", user);
      this.us.save(user).subscribe(r => {
          this._snack.open('Personal Information Saved!',null ,{duration: 3000, horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['yellow-snackbar', 'largeSnack']});
    });
  }

}
