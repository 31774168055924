<div class="container-fluid">

    <h2>Files</h2>
    <div class="row gutters">
        <div class="col">File Name</div>
        <div class="col">Download Link</div>
        <div class="col">Copy Link</div>
        <div class="col"></div>
    </div>
    <div class="row gutters" *ngFor="let file of resp.Contents">
        <div class="col">{{ file.Key }}</div>
        <div class="col"><a style="word-break:break-all;" href="{{ bucketPath + file.Key }}" target="_blank">{{ bucketPath + file.Key }}</a></div>
        <div class="col">
            <button style="margin-right:5%;float:left;" mat-raised-button color="primary" type="button" (click)="copyInputMessage(userinput)" value="click to copy" >Copy Link</button>
            <textarea class="input-reg-text" style="word-break:break-all;" matInput value="{{ bucketPath + file.Key }}" #userinput></textarea>
        </div>
        <div class="col" style="text-align:center;"><button mat-raised-button color="primary" type="button" (click)="delete(file)">Delete</button></div>
    </div>

    <h2>Upload File</h2>
    <input type="file" placeholder="File" (change)="upload($event)" />

</div>