import { Component, OnInit } from '@angular/core';
import { Page } from '../../../models/page';
import { PageService } from '../../../common/services/page.service';
import { MenuService } from '../../../common/services/menu.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { SlugifyPipe } from 'ngx-pipes';
import { AppService } from '../../../common/services/app.service';
import { TinyMCEService } from '../../../common/services/tinymce.service';
import { log } from '../../../common/log/log';

@Component({
	selector: 'app-edit-page',
	templateUrl: './edit-page.component.html',
	styleUrls: ['./edit-page.component.css'],
	providers: [SlugifyPipe]
})
export class EditPageComponent implements OnInit {
	// get these on init - or make/override options if you want something special
	public options: Object;
	public apiKey: string;

	pageID = 0;
	page = new Page();
	menus;
	constructor(
		private PageService: PageService,
		private MenuService: MenuService,
		private MatSnackBar: MatSnackBar,
		private Router: Router,
		private ActivatedRoute: ActivatedRoute,
		private slugifyPipe: SlugifyPipe,
		public tinymce: TinyMCEService,
		private appService: AppService
	) { }

	ngOnInit() {
		this.MenuService.getAll().subscribe(r => {
			this.menus = r;
		});
		this.ActivatedRoute.params.subscribe(data => {
			this.PageService.get(data['id']).subscribe(r => {
				this.page.patch(r);
			});
		});

		this.options = this.tinymce.Options();
		this.apiKey = this.tinymce.GetAPIKey();
	}

	save() {
		log.Debug("page: ", this.page)
		// this.page.slug = this.slugifyPipe.transform(this.page.name);
		this.PageService.save(this.page).subscribe(r => {
			this.MatSnackBar.open("Page Saved!", null, { duration: 3000 });
			this.appService.navigate(["/", "app", "admin", "pages"]);
		});
	}

}
