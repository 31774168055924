import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { log } from '../common/log/log';
import { SidebarService } from '../common/services/sidebar.service';
import { AuthService } from '../common/services/auth.service';
import { CompanyService } from '../common/services/company.service';
import { RegistrationService } from '../common/services/registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { AppService } from '../common/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { RegistrationInterceptDialog } from './registration-intercept-dialog/registration-intercept.dialog';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

    public company;
    public form: FormGroup;
    public registrations;
    public user;
    registrationText: string;
    event;

    public displayedColumns = ['firstName', 'lastName', 'email', 'role', 'actions'];

    constructor(
        private _fb: FormBuilder,
        public SidebarService: SidebarService,
        public AuthService: AuthService,
        public CompanyService: CompanyService,
        public RegistrationService: RegistrationService,
        private route: ActivatedRoute,
        private router: Router,
        private appService: AppService,
        private dialog: MatDialog,
    ) {
        this.form = this._fb.group({name: ''});
        this.SidebarService.setPageHasSidebar(false);
    }

    ngOnInit() {

        this.route.data.subscribe((data: {
            event,
        }) => {
            log.Debug("data: ", data)
            this.event = data.event
        })

        this.user = this.AuthService.getUser();
        this.CompanyService.get(this.user['company_id']).subscribe(r => {
            this.company = r;
            log.Debug("company: ", r)
            this.registrationText = this.event.registration_text.replace("company.onsite_reps_allowed", this.company.onsite_reps_allowed || 0)
            this.RegistrationService.filter({company_id: this.user['company_id']}).subscribe(r => {
                this.registrations = r;
            });
        });

        this.route.queryParams.subscribe(v => {
            this.form.patchValue({
                'name': v['name'] || null,
            }, {emitEvent: false});
            this.RegistrationService.filter({name: v.name || '', company_id: this.user['company_id']}).subscribe(r => {
                this.registrations = r;
            });
        });

        this.form.valueChanges.pipe(
            debounceTime(500),//waits .5 seconds after input changes so we don't make too many requests
        ).subscribe(v => {
           this.appService.navigate([], {queryParams: v});
        });
    }

    newRegistrationClickHandler() {
        if( this.event.is_registration_button_intercept ) {
            const dialogRef = this.dialog.open(RegistrationInterceptDialog, {});

            dialogRef.afterClosed().subscribe( isVaxxed => {
                log.Debug("result: ", isVaxxed);
                if( isVaxxed ) {
                    this.appService.navigate(['add'], {relativeTo: this.route});
                } else {
                    alert("Suppliers must be fully vaccinated in order to attend Product Walk 2022.");
                }
            });

        } else {
            this.appService.navigate(['add'], {relativeTo: this.route});
        }
    }

    delete(registration) {
        if (confirm('Are you sure that you want to delete registration?')) {
            this.RegistrationService.delete(registration.id).subscribe(r => {
                this.ngOnInit();
            });
        }
    }

}
