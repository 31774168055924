<div class="container-fluid">
	<div class="row">
		<div class="col">
			<a [routerLink]="['add']" mat-raised-button color="accent"><mat-icon>add</mat-icon>Add Menu</a>
		</div>
		<div class="col" [formGroup]="form">
			<mat-form-field>
				<input matInput name="search" placeholder="Search Menus" formControlName="name"/>
			</mat-form-field>
		</div>
	</div>
	<div id="print-section">
		<!-- <div class="row">
			<div class="col">
				<table mat-table [dataSource]="menus" class="table mat-elevation-z7 gutters"> -->
					  <!-- Position Column -->
					  <!-- <ng-container matColumnDef="name">
					    <th mat-header-cell *matHeaderCellDef> Name </th>
					    <td data-label="Name" mat-cell *matCellDef="let element"> {{element.name}} </td>
					  </ng-container>

					  <ng-container matColumnDef="actions">
					  	<th mat-header-cell *matHeaderCellDef>Actions</th>
					  	<td data-label="Actions" mat-cell *matCellDef="let element">
					  		<a mat-icon-button color="primary" [routerLink]="['edit', element.id]"><mat-icon>edit</mat-icon></a>
					  		<a mat-icon-button color="accent" (click)="delete(element)"><mat-icon>delete</mat-icon></a>
					  	</td>
					  </ng-container>

					  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>
			</div> -->
		<!-- </div> -->
        <div class="row">
            <div class="col">
                <div class="drag-drop">
                    <div style="position:sticky;top:-12px;z-index:100;" class="drag-drop-list-header">
                        <div class="row gutters">
                            <div class="col"><span>Name</span></div>
                            <div class="col" style="text-align: center;"><span>Actions</span></div>
                        </div>
                    </div>
                    <div cdkDropList (cdkDropListDropped)="onDrop($event)" class="drag-drop-list">
                        <div
                            *ngFor="let menu of filteredMenus"
                            cdkDrag
                            class="drag-drop-list-box row gutters"
                            [ngStyle]="{'background-color': menu.active? 'white' : 'rgb(0,0,0,0.05)'}"
                        >
                            <div class="col"><span>{{ menu.name }}</span></div>
                            <div class="col" style="text-align: center;">
                                <a mat-icon-button [routerLink]="['edit', menu.id]" color="primary"><mat-icon>edit</mat-icon></a>
                                <a mat-icon-button color="accent" (click)="delete(menu)"><mat-icon>delete_forever</mat-icon></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
    <button style="margin-left: 10px;" type="button" mat-raised-button color="primary" (click)="saveOrder()">Save Order<mat-icon>save</mat-icon></button>
	<button style="margin-left: 10px;" type="button" mat-raised-button color="primary" (click)="print()">Print <mat-icon>print</mat-icon></button>
</div>
