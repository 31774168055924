import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';

@Component({
	selector: 'app-outbound-disposition-search',
	templateUrl: './outbound-disposition-search.component.html',
	styleUrls: ['./outbound-disposition-search.component.css']
})
export class OutboundDispositionSearchComponent implements OnInit {

	public searchForm: FormGroup;

	constructor(
		public fb: FormBuilder
	) { }

	ngOnInit() {
		this.searchForm = this.fb.group({
			company: new FormControl(),
			department: new FormControl(),
			sku: new FormControl(),
			return_option: new FormControl(),
			manifest_number: new FormControl()
		});	
	}

}
