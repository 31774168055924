<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>
            <span *ngIf="!isNew">Shipment: #{{manifest.manifest_number}}</span>
            <span *ngIf="isNew">New Shipment</span>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <nav *ngIf="!isNew" mat-tab-nav-bar backgroundColor="primary" color="accent">
            <a mat-tab-link [routerLink]="['./contact-info']" routerLinkActive="highlight router-link-active">Shipment Info</a>
            <a mat-tab-link [routerLink]="['./schedule']" routerLinkActive="highlight router-link-active">Shipment Schedule</a>
            <a mat-tab-link [routerLink]="['./manifest']" routerLinkActive="highlight router-link-active">Shipment Manifest</a>
        </nav>
        <br>
        <router-outlet></router-outlet>
    </mat-card-content>
</mat-card>