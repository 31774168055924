import { CompanyTypeService } from '../common/services/company-type.service';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class CompanyTypesResolve {

	constructor(
		public CompanyTypeService: CompanyTypeService
	){}

	resolve(){
		return this.CompanyTypeService.all();
	}

}