import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { RegistrationService } from '@common/services/registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartmentService } from '@common/services/department.service';
import { CompanyService } from '@common/services/company.service';
import { debounceTime } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import { AuthService, AuthGroups } from '@common/services/auth.service';
import { AppService } from '../../common/services/app.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-manage-registration',
  templateUrl: './manage-registration.component.html',
  styleUrls: ['./manage-registration.component.css']
})
export class ManageRegistrationComponent implements OnInit {

  registrations;
  public filterForm: FormGroup;
  public departmentFilterCtrl: FormControl = new FormControl();
  filteredDepartments;
  filteredCompanies;
  registrationsXLS;
  addressesToXLS = false;
  public departments;
  public companies;
  authGroups = AuthGroups

  @ViewChild('TABLE') table: ElementRef;

  public displayedColumns = [
    'badge_first_name',
    'badge_last_name',
    'onsite_role',
    'mobile_phone',
    'email',
    'company',
    'department',
    'primary_onsite',
    'registration_date',
    'actions'
  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public router: Router,
    public rs: RegistrationService,
    private route: ActivatedRoute,
    public fb: FormBuilder,
    public ds: DepartmentService,
    public cs: CompanyService,
    private _snack: MatSnackBar,
    private AuthService: AuthService,
    private appService: AppService,
    public datePipe: DatePipe
  ) { }

  ngOnInit() {

    this.ds.allByLabelASC().subscribe(r => {
      this.departments = r;
      this.filteredDepartments = this.departments;
    });

    this.filterForm = this.fb.group({
      'department_id': new FormControl(),
      'company': new FormControl(),
      'name': new FormControl(),
      'primary_onsite': new FormControl()
    });

    this.route.queryParams.subscribe(v => {
      this.filterForm.patchValue({
          'company': v['company'] || null,
          'department_id': parseInt(v['department_id']) || null,
          'name': v['name'] || null,
          'primary_onsite': 'primary_onsite' in v ? parseInt(v['primary_onsite']) : null,
      }, {emitEvent: false});
      this.rs.filter(v).subscribe(r => {
          this.registrations = new MatTableDataSource(<any> r);
          this.registrations.sort = this.sort;
          this.registrations.paginator = this.paginator;
      });
    });

    this.filterForm.valueChanges.pipe(
      debounceTime(500), // waits .5 seconds after input changes so we don't make too many requests
    ).subscribe(v => {
      this.router.navigate([], {queryParams: v});
    });

    this.departmentFilterCtrl.valueChanges.subscribe(() => {
      this.filterDepartments();
    });

  }

  protected filterDepartments() {
    if (!this.departments) {
      return;
    }
    // get the search keyword
    let search = this.departmentFilterCtrl.value;
    if (!search) {
      this.filteredDepartments = this.departments;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the departments
    this.filteredDepartments = this.departments.filter(department => department.name.toLowerCase().indexOf(search) > -1);
  }

  reset() {
    this.router.navigate([], {queryParams: {}});
  }

  // ExportTOExcel() {
  //
  //   this.cs.getCompaniesForExport(this.route.snapshot.queryParams).subscribe(r => {
  //     this.companiesForExport = Object.keys(r).map(i => r[i])
  //
  //     const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.companiesForExport);
  //     var formattedWS = {};
  //     var badColumn;
  //
  //     Object.keys(ws).forEach(function (item) {
  //         if(!item.includes(badColumn)){
  //             if(ws[item]['v'] == ' block '){
  //                 ws[item]['v'] = 'no';
  //             }
  //             if(ws[item]['v'] == ' done '){
  //                 ws[item]['v'] = 'yes';
  //             }
  //             formattedWS[item] = ws[item];
  //         }
  //     });
  //
  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
  //     var d = new Date();
  //     var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
  //     XLSX.writeFile(wb, 'companies_spreadsheet_'+date+'.xlsx');
  //     this._snack.open('Exported!',null ,{duration: 3000});
  //   })
  //
  // }

  ExportTOExcel() {

      this.rs.filterForExport(this.route.snapshot.queryParams).subscribe( r => {
          this.registrationsXLS = r;

          var generatedData = [];

          if (this.addressesToXLS) {
              generatedData = this.registrationsXLS;
          } else {
              this.registrationsXLS.forEach(function (value) {
                  delete value.city;
                  delete value.address_1;
                  delete value.address_2;
                  delete value.address_3;
                  delete value.zip;
                  delete value.state;
                  delete value.country;
                  generatedData.push(value);
              });
          }

          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.registrationsXLS);
          var formattedWS = {};
          var badColumn;

          Object.keys(ws).forEach(function (item) {
            if( ws[item]['v'] && item.includes("M") && item != "M1" ) {
              ws[item]['v'] = this.datePipe.transform(ws[item]['v'], 'short');
            }

              if(!item.includes(badColumn)){
                  if(ws[item]['v'] == ' block '){
                      ws[item]['v'] = 'no';
                  }
                  if(ws[item]['v'] == ' done '){
                      ws[item]['v'] = 'yes';
                  }
                  formattedWS[item] = ws[item];
              }
          }, this);

          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, formattedWS, 'Sheet1');
          var d = new Date();
          var date = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
          XLSX.writeFile(wb, 'registrations_spreadsheet_'+date+'.xlsx');
          this._snack.open('Speadsheet downloaded!',null ,{duration: 3000});

      });

  }

  delete(registration) {
    if (confirm('Are you sure that you want to delete registration?')) {
        this.rs.delete(registration.id).subscribe(r => {
          this.ngOnInit();
        });
    }
  }

}
