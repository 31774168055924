import { Injectable } from '@angular/core';

const PRODUCTWALK_API_KEY = '7wc6qrlmteld66o3pz7atwwsp098r3h19yf6lko7nzyiutx0';
const THDPLR_API_KEY = 'zcex82esci2zap9ys1r5628v66ayxiqea9h15k3gn9427clr';

@Injectable({
	providedIn: 'root',
})
export class TinyMCEService {
	GetAPIKey() {

		let hostname = this.getHostname();

		switch (hostname) {
			case 'thdplr.com':
				return THDPLR_API_KEY;
			case 'productwalk.com':
			// no break
			default:
				return PRODUCTWALK_API_KEY;
		}
	}

	Options() {
		return {
			height: 500,
			branding: false,
			menubar: 'edit insert view format table tools help',
			body_class: 'site-content',
			plugins: [
				'code',
				'link',
				'lists',
				'advlist',
				'autolink',
				'image',
				'charmap',
				'preview',
				'anchor',
				'searchreplace',
				'visualblocks',
				'fullscreen',
				'insertdatetime',
				'media',
				'table',
				'help',
				'wordcount'
			],
			importcss_append: true,
			relative_urls: false,
			convert_urls: false,
			fullscreen_native: true,
			content_css: [
				'/assets/content.css',
				'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css'
			],
			toolbar: 'undo redo | bold italic forecolor backcolor | alignleft aligncenter alignright alignnone | bullist numlist | link image | removeformat | fullscreen',
		};
	}

	getHostname() {
		let hostname = location.hostname;

		if (hostname.includes('productwalk')) {
			return 'productwalk.com';
		}
		if(hostname.includes('thdplr')) {
			return 'thdplr.com';
		}

		return 'unknown';
	}
}
