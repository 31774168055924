import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ContractStatusService {

	constructor(
		public http: HttpClient
	) { }

	all(){
		return this.http.get(environment.api + "/contract_statuses/all_by_event_id");
	}

}
