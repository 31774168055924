import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageService } from '../common/services/page.service';
import { log } from '../common/log/log';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {
  page;
  constructor(
    private ActivatedRoute: ActivatedRoute,
    private PageService: PageService
  ) { }

  ngOnInit() {
      this.ActivatedRoute.params.subscribe(r => {
        this.PageService.getBySlug(r['slug']).subscribe(page => {
            log.Debug("page: ", page)
          this.page = page;
        });
      });
  }

}
