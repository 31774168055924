<div class="container-fluid">

  <h3>Imports</h3>
  <br>
  <h4>Download attendees list</h4>
  <button mat-raised-button color="accent" (click)="downloadAttendeesList()"><mat-icon>archive</mat-icon> Download</button>
  <br><br>
  <h4>Update attendees list</h4>
  <button mat-raised-button color="accent" (click)="openAttendeeList()"><mat-icon>add</mat-icon> Upload</button>

</div>