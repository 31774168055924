import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../common/services/auth.service';
import { UserService } from '../common/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { log } from '@common/log/log';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from '../common/services/app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  public loginForm;
  public resetPasswordForm;
  event;
  isArchive;

  constructor(
    public formBuilder: FormBuilder,
    public AuthService: AuthService,
    public router: Router,
    private us: UserService,
    private MatSnackBar: MatSnackBar,
    public route: ActivatedRoute,
    private appService: AppService
  ) {
    this.isArchive = window.location.origin.includes("archive");
    log.Debug("url: ", window.location.origin)
  }

  ngOnInit() {
    this.loginForm = this.resetPasswordForm = this.formBuilder.group({
      username: new FormControl(),
      password: new FormControl()
    });
    this.route.data.subscribe((data: {
      event,
    }) => {
      log.Debug("data: ", data)
      this.event = data.event
    })
  }

  login() {
    this.AuthService.login(this.loginForm.value).subscribe({
      next: r => {
        this.appService.navigate(['app']);
      },
      error: err => {
        log.Debug("error: ", err);
        let message = err
        if (err.error) {
          message = "Something went wrong. Please contact the website admin."
        }
        this.MatSnackBar.open(message, null, { duration: 10000, panelClass: 'alert', verticalPosition: "top" });
      }
    });
  }
}
