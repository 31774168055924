import { Component, OnInit, HostBinding } from '@angular/core';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { IField, FieldBuilder } from '../../common/generic';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { USER_FIELD_LIST, USER_PASSWORD_FIELD_LIST } from '../fields';
import { log } from '@common/log/log';
import { UserService } from '../../../common/services/user.service';
import { RoleService } from '../../../common/services/role.service';
import { CompanyService } from '../../../common/services/company.service';
import { User } from '../../../models/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthGroups, AuthService } from '../../../common/services/auth.service';
import { AppService } from '../../../common/services/app.service';

@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
    private _errorPrefix = EditUserComponent.name;
    private _inputFields: IField<any>[][] = [];
    public companyFilterCtrl: FormControl = new FormControl();
    form: FormGroup;
    roles;
    companies;
    filteredCompanies;

    authGroups = AuthGroups;

    companyContactInfo = {
        id: null,
        contact_name: '',
        contact_title: '',
        contact_email: '',
        phone_number: '',
        phone_ext: ''
    };

    get inputFields(): IField<any>[][] {
        if (this._inputFields.length === 0) {
            this._inputFields = FieldBuilder.groupByColumns(
                FieldBuilder.fields(USER_FIELD_LIST).filter(field => !field.group && (!field.type || field.type === 'input'))
            );
        }
        return this._inputFields;
    }
    get company(): IField<any> {
        return USER_FIELD_LIST.company;
    }
    constructor(
        private _fb: FormBuilder,
        public us: UserService,
        private rs: RoleService,
        private cs: CompanyService,
        private _snackBar: MatSnackBar,
        private router: Router,
        public location: Location,
        private AuthService: AuthService,
        private appService: AppService
    ) {
        this.form = this._fb.group(Object.assign(FieldBuilder.getGroup(USER_FIELD_LIST, this._fb)));
        this.rs.all().subscribe(r => {
            log.Debug("roles: ", r)
            this.roles = r;
        });
        this.form.addControl('passwordGroup', this._fb.group(FieldBuilder.getGroup(USER_PASSWORD_FIELD_LIST, this._fb)))
    }

    @HostBinding('class') classes = 'full-height no-head';

    ngOnInit() {

        this.cs.getCompanies().subscribe(r => {
            this.companies = r;
            this.filteredCompanies = this.companies;
        });

        this.companyFilterCtrl.valueChanges.subscribe(() => {
            this.filterCompanies();
        });

    }

    protected filterCompanies() {
        if (!this.companies) {
          return;
        }
        // get the search keyword
        let search = this.companyFilterCtrl.value;
        if (!search) {
          this.filteredCompanies = this.companies;
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the companies
        this.filteredCompanies = this.companies.filter(company => company.name.toLowerCase().indexOf(search) > -1);
    }

    hasPermission(role: string, authGroup: string[]): boolean {
        if( this.authGroups.ACCOUNTING.findIndex(aRole => aRole == role.toLowerCase().replace(" ", "_")) == -1  ) {
            return true
        }
        return this.AuthService.HasPermission(authGroup)
    }

    save() {
    	if( !this.form.dirty || this.form.invalid ) {
    		return;
    	}

        let user = new User(this.form.value);
        user.password = this.form.value.passwordGroup.password
        log.Debug("user: ", user )
        log.Debug("form value: ", this.form.value)
        log.Debug(`${this._errorPrefix} saving: `, user);

        // NA commented out 8/11/22
        // if(user.is_primary == true && user.company_id > 0){
        //     this.companyContactInfo.id = user.company_id
        //     this.companyContactInfo.contact_name = user.first_name + ' ' + user.last_name
        //     this.companyContactInfo.contact_title = user.title
        //     this.companyContactInfo.contact_email = user.email
        //     this.companyContactInfo.phone_number = user.phone
        //     this.companyContactInfo.phone_ext = user.ext
        //     this.cs.updateContactInfoByID(this.companyContactInfo).subscribe();
        // }

        this.us.add(user).subscribe(r => {
            this._snackBar.open("User Saved!",null ,{duration: 3000});
            this.appService.navigate(['/', 'app', 'admin', 'users'])
        });
    }
}
