import { Component, OnInit } from '@angular/core';
import { Page } from '../../../models/page';
import { PageService } from '../../../common/services/page.service';
import { MenuService } from '../../../common/services/menu.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SlugifyPipe } from 'ngx-pipes';
import { AppService } from '../../../common/services/app.service';

@Component({
	selector: 'app-add-page',
	templateUrl: './add-page.component.html',
	styleUrls: ['./add-page.component.css'],
	providers: [SlugifyPipe]
})
export class AddPageComponent implements OnInit {
	options = {
		height: 500,
		menubar: false,
		plugins: [
			'code',
			'link',
			'lists advlist',
			'autolink',
			'image',
			'charmap',
			'preview',
			'anchor',
			'searchreplace',
			'visualblocks',
			'fullscreen',
			'insertdatetime',
			'media',
			'table',
			'help',
			'wordcount'
		],
		fullscreen_native: true,
		toolbar: 'undo redo | bold italic forecolor backcolor | alignleft aligncenter alignright alignnone | link image | removeformat | fullscreen',
	};
	pageID = 0;
	page = new Page();
	menus;
	constructor(
		private PageService: PageService,
        private MenuService: MenuService,
		private MatSnackBar: MatSnackBar,
		private Router: Router,
		private slugifyPipe: SlugifyPipe,
		private appService: AppService
	) { }

	ngOnInit() {
		this.MenuService.getAll().subscribe(r => {
			this.menus = r;
		});
	}

	save() {
		this.page.slug = this.slugifyPipe.transform(this.page.name);
		this.PageService.save(this.page).subscribe(r => {
			this.MatSnackBar.open("Page Added!", null, { duration: 3000 });
			this.appService.navigate(["/", "app", "admin", "pages"]);
		});
	}

}
