import { Component, OnInit } from '@angular/core';
import { AuthService } from '../common/services/auth.service';
import { CompanyService } from '../common/services/company.service';
import { CustomInvoice } from '../models/custom-invoice';
import { CustomInvoiceService } from '../common/services/custom-invoice.service';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/log/log';

@Component({
	selector: 'app-invoices',
	templateUrl: './invoices.component.html',
	styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
	invoiceText = `<p>Invoice Text</p>`;
	contractStatus;
	supplierRecieved = false;
	downloadingInvoice = false;
	customInvoices;
	currentUserRole;
	displayedColumns = ['invoice_number', 'amount', 'actions'];

	constructor(
		private auth: AuthService,
		private comps: CompanyService,
		private CustomInvoiceService: CustomInvoiceService,
		private route: ActivatedRoute,
	) {}

	public company;
	public user;
	event;

	ngOnInit() {
		this.route.data.subscribe(( data: {
			event;
		}) => {
			log.Debug("data: ", data.event)
			this.event = data.event
		})

		this.currentUserRole = this.auth.getRole();

		log.Debug('user role test');
		log.Debug(this.currentUserRole);

		this.user = this.auth.getUser();
		this.comps.get(this.user.company_id).subscribe(r => {
			log.Debug("user company: ", r)
            this.company = r;
		});
		this.contractStatus = this.auth.getContractStatus();

		if(this.contractStatus === 'Received') {
			if(this.auth.getRole() === 'supplier' || this.auth.getRole() === 'supplier_primary_contact') {
				this.supplierRecieved = true;
			}
		}

		this.CustomInvoiceService.mine().subscribe(r => {
			this.customInvoices = r;
		});
	}

	showContractAccepted() {
		let contractStatus = this.auth.getContractStatus()
		return contractStatus == "Accepted";
	}

	downloadInvoice(){
		this.downloadingInvoice = true;
		this.comps.myInvoicePDF().subscribe(blob => {
            const a = document.createElement('a');
            const invoice_id = this.company.invoice_number;
            a.setAttribute('download', invoice_id + ".pdf");
            a.setAttribute('href', window.URL.createObjectURL(blob));
			a.click();
			this.downloadingInvoice = false;
        });
	}

	downloadCustom(invoice){
		this.CustomInvoiceService.download(invoice.id).subscribe(blob => {
			const a = document.createElement('a');
            const invoice_id = invoice.invoice_number;
            a.setAttribute('download', invoice_id + ".pdf");
            a.setAttribute('href', window.URL.createObjectURL(blob));
			a.click();
			this.downloadingInvoice = false;
		});
	}

}
