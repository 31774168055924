<div class="container-fluid">
	<div class="row">
		<div class="col">
			<button [routerLink]="['add']" mat-raised-button color="accent"><mat-icon>add</mat-icon>Add Company</button>
		</div>
	</div>
	<div class="row gutters">
        <mat-expansion-panel [expanded]="true" [hideToggle]="false" class="col margin-top">
            <mat-expansion-panel-header>
                <mat-panel-title>Filter Companies</mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <form [formGroup]="filterForm">
            	<div class="row gutters">
                	<div class="col">
                		<mat-form-field>
                			<mat-label>Company</mat-label>
                			<input type="text" formControlName="name" matInput placeholder="Company" />
                		</mat-form-field>
                	</div>
                	<div class="col">
                    	<mat-form-field>
                    		<mat-label>Group</mat-label>
                    		<mat-select formControlName="group_id">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let g of groups" [value]=g.id>{{g.name}}</mat-option>
                    		</mat-select>
                    	</mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Department</mat-label>
                            <mat-select formControlName="department_id">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let d of departments" [value]=d.id>{{d.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row gutters">
                    <div class="col">
                    	<mat-form-field>
                    		<mat-label>Company Type</mat-label>
                    		<mat-select formControlName="company_type_id">
                    			<mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let c of companyTypes" [value]=c.id>{{c.name}}</mat-option>
                    		</mat-select>
                    	</mat-form-field>
                    </div>
                    <div class="col">
                    	<mat-form-field>
                    		<mat-label>Contract Status</mat-label>
                    		<mat-select formControlName="contract_status_id">
                    			<mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let c of contractStatus" [value]=c.id>{{c.name}}</mat-option>
                    		</mat-select>
                    	</mat-form-field>
                    </div>
                </div>
                <div class="row gutters">
                	<div class="col">
                        <mat-form-field>
                            <mat-label >Is Active</mat-label>
                            <mat-select formControlName="is_active">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option [value]=1>Yes</mat-option>
                                <mat-option [value]=0>No</mat-option>
                            </mat-select>
                        </mat-form-field>
                	</div>
                	<div class="col">
                        <mat-form-field>
                            <mat-label >Ship Only</mat-label>
                            <mat-select formControlName="ship_only">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option [value]=1>Yes</mat-option>
                                <mat-option [value]=0>No</mat-option>
                            </mat-select>
                        </mat-form-field>
                	</div>
                	<div class="col">
                        <mat-form-field>
                            <mat-label >Info Completed</mat-label>
                            <mat-select formControlName="info_complete">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option [value]=1>Yes</mat-option>
                                <mat-option [value]=0>No</mat-option>
                            </mat-select>
                        </mat-form-field>
                	</div>
                </div>
                <button mat-raised-button color="primary" (click)="reset()">Reset Filters</button>
                <button style="margin-left: 10px;" mat-raised-button color="primary" (click)="ExportTOExcel()">Export as .xls</button>
            </form>
        </mat-expansion-panel>
    </div>
    <div id="print-section">
	<div class="row">
		<div class="col" #TABLE>
			<table mat-table #table [dataSource]="companies" matSort class="table mat-elevation-z7 gutters sticky-header">

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox [checked]="allSelected()" (change)="toggleAll()"></mat-checkbox>
                    </th>
                    <td mat-cell data-label="Select" *matCellDef="let element"><mat-checkbox [checked]="isSelected(element)" (change)="toggleSelection($event, element)"></mat-checkbox>
                </ng-container>

                <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                <td mat-cell data-label="Company" *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="department">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                <td mat-cell *matCellDef="let element"> {{element.department}} </td>
                </ng-container>

                <ng-container matColumnDef="contact_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact Name</th>
                <td mat-cell data-label="Contact Name" *matCellDef="let element"> {{element.contact_name}} </td>
                </ng-container>

                <ng-container matColumnDef="contact_email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell data-label="Contact Email" *matCellDef="let element"> {{element.contact_email}} </td>
                </ng-container>

                <ng-container matColumnDef="company_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Type</th>
                <td mat-cell data-label="Company Type" *matCellDef="let element"> {{element.company_type}} </td>
                </ng-container>

                <ng-container matColumnDef="participation_fee">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Participation Fee </th>
                <td mat-cell data-label="Participation Fee" *matCellDef="let element">
                    <span *ngIf="element.participation_fee">
                        {{element.participation_fee | currency:'USD':'symbol'}}
                    </span>
                    <span *ngIf="!element.participation_fee">
                        --
                    </span>
                </td>
                </ng-container>

                <ng-container matColumnDef="invitation_code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Invitation Code </th>
                <td mat-cell data-label="Invitation Code" *matCellDef="let element"> {{element.invitation_code}} </td>
                </ng-container>

                <ng-container matColumnDef="contract_status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract Status </th>
                <td mat-cell data-label="Contract Status" *matCellDef="let element">
                    <mat-select
                     class="update-contract"
                     placeholder="{{ element.contract_status }}"
                     (selectionChange)="updateContractStatus($event, element)">
                        <mat-option *ngFor="let c of contractStatus" [value]=c.name>{{c.name}}</mat-option>
                        <!-- <mat-option value="Not Presented">Not Presented</mat-option>
							 <mat-option value="Presented">Presented</mat-option>
							 <mat-option value="Accepted">Accepted</mat-option>
							 <mat-option value="Received">Received</mat-option>
							 <mat-option value="Accepted Before Presented">Accepted Before Presented</mat-option> -->
                    </mat-select>
                </td>
                </ng-container>

				<ng-container matColumnDef="contract_status_updated">
					<th mat-header-cell *matHeaderCellDef>Contract Status Updated</th>
					<td mat-cell data-label="Contract Status Updated" *matCellDef="let element">
						{{element.contract_status_updated | date}}
					</td>
				</ng-container>


                <ng-container matColumnDef="invoice_sent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice Sent </th>
                <td mat-cell data-label="Invoice Sent" *matCellDef="let element">
                	<mat-icon *ngIf="element.invoice_sent">
                		done
                	</mat-icon>
                	<mat-icon color="accent" *ngIf="!element.invoice_sent">
                		block
                	</mat-icon>
                </td>
                </ng-container>

                <ng-container matColumnDef="is_custom_invoice_included">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Custom Invoice Included </th>
                    <td mat-cell data-label="Custom Invoice Included" *matCellDef="let element">
                        <mat-icon *ngIf="element.is_custom_invoice_included">
                            done
                        </mat-icon>
                        <mat-icon color="accent" *ngIf="!element.is_custom_invoice_included">
                            block
                        </mat-icon>
                    </td>
                    </ng-container>

                <ng-container matColumnDef="invoice_downloaded">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice Downloaded </th>
                <td mat-cell data-label="Invoice Downloaded" *matCellDef="let element">
                	<mat-icon *ngIf="element.invoice_downloaded">
                		done
                	</mat-icon>
                	<mat-icon color="accent" *ngIf="!element.invoice_downloaded">
                		block
                	</mat-icon>
                </td>
                </ng-container>

                <ng-container matColumnDef="info_complete">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Info Complete </th>
                    <td mat-cell data-label="Info Complete" *matCellDef="let element">
                        <mat-icon *ngIf="element.info_complete">
                            done
                        </mat-icon>
                        <mat-icon color="accent" *ngIf="!element.info_complete">
                            block
                        </mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="ship_only">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ship Only </th>
                    <td mat-cell data-label="Ship Only" *matCellDef="let element">
                        <mat-icon *ngIf="element.ship_only">
                            done
                        </mat-icon>
                        <mat-icon color="accent" *ngIf="!element.ship_only">
                            block
                        </mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="invite_sent">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Invite Sent </th>
                    <td mat-cell data-label="Invite Sent" *matCellDef="let element">
                        <mat-icon *ngIf="element.invite_sent_bool">
                            done
                        </mat-icon>
                        <mat-icon color="accent" *ngIf="!element.invite_sent_bool">
                            block
                        </mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
                    <td mat-cell data-label="Primary Contact" *matCellDef="let element">
                        <mat-icon *ngIf="element.active">
                            done
                        </mat-icon>
                        <mat-icon color="accent" *ngIf="!element.active">
                            block
                        </mat-icon>
                    </td>
                  </ng-container>

                <ng-container matColumnDef="actions">
                	<th mat-header-cell *matHeaderCellDef>Actions</th>
                	<td mat-cell data-label="Actions" *matCellDef="let element">
                		<a mat-icon-button color="primary" matTooltip="Edit Company" [routerLink]="['edit', element.id]"><mat-icon>edit</mat-icon></a>
                        <a mat-icon-button color="primary" matTooltip="Send Invite to Company" (click)="sendInvite(element.id, element.name)"><mat-icon>email</mat-icon></a>
                		<a mat-icon-button color="accent" matTooltip="Delete Company" (click)="delete(element.id, element.name)"><mat-icon>delete</mat-icon></a>
                	</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
		</div>
	</div>
    </div>
    <div  *ngIf="downloadingBulkInvoices" style="width:200px">
        <mat-progress-bar mode="buffer"></mat-progress-bar>
        Downloading Invoices
    </div>
    <button mat-raised-button color="accent" (click)="bulkDownloadInvoiceTemplates()">Download Invoice Templates</button>
    <button style="margin-left: 10px;" type="button" mat-raised-button color="primary" (click)="print()">Print <mat-icon>print</mat-icon></button>
    <!-- <button style="margin-left:10px" type="button" mat-raised-button color="primary" (click)=s"updateInvoiceNumbers()">Update Invoice Numbers</button> -->
</div>
