import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { CompanyService } from '../common/services/company.service';
import { take } from 'rxjs/operators';

@Injectable()
export class ActivateResolve implements Resolve<any> {

	constructor(
		public cs: CompanyService
	){}

	resolve(route){
		let code = route.params.code;
		return this.cs.getCompanyByInvitationCode(code).pipe(take(1));
	}

}
