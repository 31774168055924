import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GroupService } from '../../../common/services/group.service';
import { DepartmentService } from '../../../common/services/department.service';
import { UserService } from '../../../common/services/user.service';
import { Department } from '../../../models/department';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { InvoiceTemplateService } from '@common/services/invoice-template.service';
import { AppService } from '../../../common/services/app.service';

@Component({
  selector: 'app-edit-department',
  templateUrl: './edit-department.component.html',
  styleUrls: ['./edit-department.component.css']
})
export class EditDepartmentComponent implements OnInit {

  groups;
  coordinators;
  form: FormGroup;
  invoice_templates;

  constructor(
    private gs: GroupService,
    private fb: FormBuilder,
    private ds: DepartmentService,
    private sb: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private UserService: UserService,
    public location: Location,
    private InvoiceTemplateService: InvoiceTemplateService,
    private appService: AppService
  ) { }

  ngOnInit() {
    let dep = new Department();
    this.form = this.fb.group(dep);
    this.route.params.subscribe(data => {
      this.ds.get(data['id']).subscribe(r => {
        this.form = this.fb.group(r);
      });
    });

    this.gs.all().subscribe(r => {
      this.groups = r;
    });

    this.UserService.getCoordinators().subscribe(r => {
      this.coordinators = r;
    });

    this.InvoiceTemplateService.all().subscribe(r => {
      this.invoice_templates = r;
    });
  }

  save(){
    this.ds.save(this.form.value).subscribe(r => {
      this.sb.open("Department Saved!",null ,{duration: 3000});
      this.appService.navigate(['/', 'app', 'admin', 'departments']);
    });
  }
}
