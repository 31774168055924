import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderByPipe } from '@common/pipes.ts/indext';
import { SafePipe } from './safe';

@NgModule({
  declarations: [
    OrderByPipe,
    SafePipe
  ],
  exports: [
    OrderByPipe,
    SafePipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
