import { ShippingDestinationsComponent } from './shipping-destinations.component';
import { AddShippingDestinationComponent } from './add-shipping-destination/add-shipping-destination.component';
import { EditShippingDestinationComponent } from './edit-shipping-destination/edit-shipping-destination.component';
import { DestinationComponent } from './destination/destination.component';

export const SHIPPING_DESTINATION_COMPONENTS = [
    ShippingDestinationsComponent,
    AddShippingDestinationComponent,
    EditShippingDestinationComponent,
    DestinationComponent
];

export const SHIPPING_DESTINATION_ROUTES = [
    {
        path: 'shipping_destinations',
        component: ShippingDestinationsComponent
    },
    {
        path: 'shipping_destinations/add',
        component: AddShippingDestinationComponent
    },
    {
        path: 'shipping_destinations/edit/:id',
        component: EditShippingDestinationComponent
    }
];
