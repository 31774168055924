import { UsersComponent } from './users.component';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { Routes } from '@angular/router';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

export const USER_ROUTES: Routes = [
    {
        path: 'users',
        component: UsersComponent
    },
    {
        path: 'users/add',
        component: AddUserComponent
    },
    {
        path: 'users/edit/:id',
        component: EditUserComponent
    }
];

export const USER_COMPONENTS = [
    UsersComponent,
    AddUserComponent,
    EditUserComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
];
