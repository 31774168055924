<div class="container-fluid">
	<form [formGroup]="form" (submit)="save()">
		<div class="row">
			<div class="col">
				<mat-form-field>
					<input formControlName="name" matInput type="text" name="name" placeholder="Name" />
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field>
					<input formControlName="label" matInput type="text" name="label" placeholder="Label" />
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field>
					<input formControlName="abbreviation" matInput type="text" name="label" placeholder="Abbreviation" />
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field>
					<mat-label>Invoice Template</mat-label>
					<mat-select formControlName="invoice_template_id" name="invoice_template_id">
						<mat-option *ngFor="let it of invoice_templates" [value]=it.id>{{it.name}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field>
					<mat-label>Group</mat-label>
					<mat-select formControlName="group_id" name="group_id">
						<mat-option *ngFor="let group of groups" [value]=group.id>{{group.name}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field>
					<mat-label>Coordinator</mat-label>
					<mat-select formControlName="coordinator_id" name="coordinator_id">
						<mat-option *ngFor="let u of coordinators" [value]=u.id>{{u.first_name}} {{u.last_name}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-checkbox formControlName="active" name="active">Active</mat-checkbox>
			</div>
		</div>
        <div class="row">
            <div class="col">
                <mat-checkbox formControlName="shipping_only" name="shipping_only">Shipping Department</mat-checkbox>
            </div>
        </div>
		<button type="submit" mat-raised-button color="primary">Submit</button>
		<a style="margin-left: 5px;" color="primary" mat-raised-button (click)="this.location.back();">Cancel</a>
	</form>
</div>
