import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: './registration-intercept.dialog',
    templateUrl: './registration-intercept.dialog.html',
})
export class RegistrationInterceptDialog {

    constructor(
        public dialogRef: MatDialogRef<RegistrationInterceptDialog>
    ) {}

    closeDialog( isVaxxed ) {
        this.dialogRef.close(isVaxxed);
    }
}