import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../common/services/auth.service';
import { ManifestService } from '@common/services/manifest.service';
import { MatDialog } from '@angular/material/dialog';
import { ManifestErrorsDialogComponent } from '../shipment/manifest/manifest.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { PageService } from '@common/services/page.service';
import { AppService } from '../../common/services/app.service';
import { log } from '../../common/log/log';

@Component({
	selector: 'app-upload-manifest',
	templateUrl: './upload-manifest.component.html',
	styleUrls: ['./upload-manifest.component.css']
})
export class UploadManifestComponent implements OnInit {

	public uploadingFor = false;
	public forms;
	page;

	public displayedColumns = [
		'manifest_number',
		'shipping_contact_name',
		'shipping_contact_email',
		'shipping_contact_phone',
		'brand',
		'ship_date',
		'arrive_date',
		'actions'
	];

	constructor(
		public as: AuthService,
		public manifestService: ManifestService,
		public dialog: MatDialog,
		private _snack: MatSnackBar,
		private router: Router,
		public activatedRoute: ActivatedRoute,
		private PageService: PageService,
		private appService: AppService
	) { }

	ngOnInit() {
		this.manifestService.getAllByCompanyID(this.as.getUser()['company_id']).subscribe(r => {
			this.forms = r;
		});
		this.PageService.getBySlug('upload-manifest').subscribe( r => {
			this.page = r
		})
	}

	upload($event){
		log.Debug("event: ", $event);
		let fileData = new FormData()
		fileData.append('file', $event.target.files[0])
		this.manifestService.validate(fileData, this.uploadingFor).subscribe((r: string[]) => {
			if (r.length > 0) {
                $event.srcElement.value = null;
                this.dialog.open(ManifestErrorsDialogComponent, {
                    data: {errors: r}
                });
            }
            else{
				this._snack.open('Validation successful. Uploading manifest...',null ,{duration: 3000, horizontalPosition: 'center',
					  verticalPosition: 'bottom'});
				log.Debug("filedata: ", fileData)
				this.manifestService.upload(fileData, this.uploadingFor).subscribe( success => {
					if(!success) {
						this._snack.open('Upload failed!',null ,{duration: 3000, horizontalPosition: 'center',
							verticalPosition: 'bottom'});
					} else {
						this._snack.open('Upload successful!',null ,{duration: 3000, horizontalPosition: 'center',
							  verticalPosition: 'bottom'});
							  this.appService.navigate(["../manifest-info", this.uploadingFor], {relativeTo: this.activatedRoute})
					}
				});
            }
		});
	}

	isAdmin(){
		return this.as.getRole() == 'admin'
	}
}
