import { Directive, HostListener, Input } from "@angular/core";
import { log } from "../log/log";

@Directive({
  selector: '[linkWatcher]'
})

export class LinkWatcher {
    @Input('linkWatcher') SaveAnalytic: (a: any) => void;

    @HostListener("click", ["$event"])
    onClick(e: MouseEvent){
        if((e.target as Element).tagName.toLowerCase() == 'a'){
            log.Debug("event pathname: ", e.target['pathname'])
            this.SaveAnalytic(e.target['pathname']);
        }
        if((e.target as Element).tagName.toLowerCase() == 'video'){
            log.Debug("event pathname children: ", e.target['children'][0].src)
            this.SaveAnalytic(e.target['children'][0].src);
        }
    }
}
