import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { ShippingDestinationService } from '@common/services/shipping-destination.service';
import { DepartmentService } from '@common/services/department.service';
import { AddressService } from '@common/services/address.service';
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas';
import { log } from '../../../common/log/log';

@Component({
  selector: 'app-label-dialog',
  templateUrl: './label-dialog.component.html',
  styleUrls: ['./label-dialog.component.css']
})
export class LabelDialogComponent implements OnInit {

  form: FormGroup;
  parentComponent;
  selectedID;
  shippingDestination;
  departmentInfo;
  companyMailingAddress;
  event


  constructor(
    public dialogRef: MatDialogRef<LabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public sds: ShippingDestinationService,
    public ds: DepartmentService,
    public as: AddressService
  ) {}

  ngOnInit() {

      log.Debug("data: ", this.data)

    this.sds.getShippingLabel(this.data.dataKey.shipping_destination_id, this.data.dataKey.company_id).subscribe(r => {
        this.shippingDestination = r;

        this.ds.get(this.shippingDestination['department_id']).subscribe(r => {
            log.Debug("departmentInfo: ", r)
            this.departmentInfo = r;
        });

        this.as.getFullAddressByID(this.shippingDestination['mailing_address_id']).subscribe(r => {
            this.companyMailingAddress = r;
        });

    });

  }

//   print(): void {
//       let printContents, popupWin;
//       printContents = document.getElementById('print-section').innerHTML;
//       popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
//       popupWin.document.open();
//       popupWin.document.write(`
//         <html>
//           <head>
//           <title>Print Label</title>
//             <style>
//             body {font-family: sans-serif;}
//             .label-container {
//                 border: 3px solid #222;
//                 padding: 20px;
//             }

//             .label-left {
//                 float: left;
//                 width: 55%;
//             }

//             .label-right {
//                 position: relative;
//                 float: right;
//                 width: 45%;
//                 text-align: center;
//             }

//             .data-input {
//                 border: 1px solid #999;
//                 margin-bottom: 20px;
//                 width: 380px;
//             }

//             .data-input, textarea {
//                 box-shadow: none;
//                 padding: 5px 2px;
//                 border: 1px solid #999;
//             }

//             .address {
//                 border: 1px solid #999;
//                 padding: 2.5px;
//                 height: 130px;
//                 width: 270px;
//                 line-height: 100%;
//             }

//             .address p {
//                 margin: 5px 0;
//             }

//             .clear {
//                 clear: both;
//             }

//             .left-table th {
//                 width: 50px;
//                 text-align: right;
//                 padding-right: 10px;
//                 font-size: 14px;
//             }

//             .right-table tr {
//                 text-align: center;
//                 width: 80%;
//                 margin: 0 10%;
//             }

//             .right-table {
//                 margin-bottom: 20px;
//             }

//             .right-table, .right-table td, .right-table th, .right-table .data-input {
//                 text-align: center;
//             }

//             .left-table td {
//                 width: 250px;
//                 text-align: left;
//                 font-size: 18px;
//             }

//             td .data-input, td textarea {
//                 width: 270px;
//                 margin: 10px 0;
//             }

//             .left-side data-input {
//                 box-shadow: none;
//             }

//             .dept {
//                 font-size: 70px;
//                 line-height: 70px !important;
//             }

//             .piece-no {
//                 width: 280px;
//                 margin: 0 auto 20px auto;
//                 position: relative;
//                 height:75px;
//             }

//             .top-text {
//                 font-size:14px;
//                 padding: 0 10px;
//             }

//             .left-of {
//                 border: 1px solid #999;
//                 position: absolute;
//                 top: 0;
//                 float: left;
//                 width: 75px;
//                 height: 75px;
//                 box-shadow: inset 0 0 0 1000px #fff8d2;
//             }
//             .right-of {
//                 border: 1px solid #999;
//                 position: absolute;
//                 top: 0;
//                 right: 0;
//                 float: right;
//                 width: 75px;
//                 height: 75px;
//                 box-shadow: inset 0 0 0 1000px #fff8d2;
//             }

//             .of {
//                 position: relative;
//                 top: 30px;
//                 width: 25px;
//                 margin: auto;
//                 font-weight: bold;
//                 font-size: 20px;
//             }

//             .loc-id {
//                 border: 1px solid #999;
//                 margin: 0 auto 20px auto;
//                 height: 75px;
//                 box-shadow: inset 0 0 0 1000px #fff8d2;
//                 width: 280px;
//             }

//             .deliver-to {
//                 border: 1px solid #999;
//                 width: 270px;
//                 padding: 5px;
//                 height: 125px;
//             }

//             .dept-name {
//                 border: 1px solid #999;
//                 width: 100%;
//                 padding: 5px;
//                 height: 75px;
//                 line-height: 1;
//             }

//             .dept-name p {
//                 font-size: 55px !important;
//                 line-height: 1px !important;
//             }

//             .deliver-to p {
//                 margin: 5px 0;
//                 line-height: 100%;
//                 font-size: 16px !important;
//             }

//             .deliver-to.deliver-to--extended {
//                 height: 384px;
//             }
//             .deliver-to.deliver-to--extended p {
//                 font-size: 20px !important;
//             }

//             .label-right p {
//                 font-size: 18px;
//                 margin-bottom: 3px;
//             }
//             .label-center p {
//                 font-size: 18px;
//             }

//             .label-center {
//                 margin-bottom: 20px;
//             }

//             .bottom-wrapper {
//                 padding-left: 25px;
//                 width: 630px;
//             }

//             .bottom-wrapper .label-right {
//                 text-align: left;
//                 width: 40%;
//                 float: left;
//             }

//             .label-right img {
//                 position: relative;
//                 margin: 0 0 20px 150px;
//             }

//             .label-left img {
//                 margin-bottom: 30px;
//                 position: relative;
//                 width: 500px;
//                 height: 50px;
//             }

//             .bottom-wrapper .label-left {
//                 width: 25%;
//             }

//             .dept-no {
//                 max-width: 362px;
//                 width: 100%;
//                 float: left;
//             }
//             </style>
//           </head>
//           <body onload="window.print();window.close()">
//           ${printContents}
//           </body>
//         </html>`
//       );
//       popupWin.document.close();
//   }

  htmltoPDF() {
    html2canvas(document.querySelector("#print-section")).then(canvas => {
      var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
      var imgData  = canvas.toDataURL("image/jpeg", 1.0);
      pdf.addImage(imgData,60,60,canvas.width-140, canvas.height-140);
      pdf.save('label_'+this.data.dataKey.manifest_number+'.pdf');
    });
  }

  print() {
    html2canvas(document.querySelector("#print-section")).then(async canvas => {
      var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
      var imgData  = canvas.toDataURL("image/jpeg", 1.0);
      pdf.addImage(imgData,60,60,canvas.width-140, canvas.height-140);
      await pdf.save('print_label_'+this.data.dataKey.manifest_number+'.pdf', { returnPromise: true });
        // window.open(pdf.output('pdfobjectnewwindow', { filename: 'manifest_label_'+this.data.dataKey.manifest_number+'.pdf' }));
        pdf.output('pdfobjectnewwindow', { filename: 'label_'+this.data.dataKey.manifest_number+'.pdf' }).open()
    });
  }

}
