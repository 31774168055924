import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../common/services/user.service';
import { AuthService } from '../common/services/auth.service';
import { AddressService } from '../common/services/address.service';
import { CompanyService } from '../common/services/company.service';
import { CountryService } from '../common/services/country.service';
import { StateService } from '../common/services/state.service';
import { AppService } from '../common/services/app.service';
import { log } from "@common/log/log"
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from '../models/user';
import { Company } from '../models/company';
import { Address } from '../models/address';
import { Country } from '../models/country';
import { forkJoin, of,  Observable } from 'rxjs';
import { catchError, } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
	selector: 'app-activate',
	templateUrl: './activate.component.html',
	styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {

    public company: Company;
	event;
    companyTypes;
    roles;
    is_primary = true;
    submitted = false;

    public mask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

    states;
    countries: Country[] = [];
    billingCountry = 1;
    mailingCountry = 1;
    sameAsBilling = false;
    addressesNotValid;
    shipOnly: boolean;
    getUser;
    userNameIsValid = 'INVALID';
	contractNotApplicable = false;

    userForm: FormGroup;
    compForm: FormGroup;
    mailingForm: FormGroup;
    billingForm: FormGroup;
    contractForm: FormGroup;

    constructor(
        public route: ActivatedRoute,
        public us: UserService,
        public as: AuthService,
        public router: Router,
        private _fb: FormBuilder,
        private CountryService: CountryService,
        private adds: AddressService,
        private cs: CompanyService,
        private ss: StateService,
        private _snackBar: MatSnackBar,
        private appService: AppService
    ) {
        // this.userForm = this._fb.group(User.EmptyGroup());
        this.userForm = this._fb.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', Validators.required],
            ext: [''],
            title: ['', Validators.required],
            username:[ null, {
                validators: [Validators.required],
                asyncValidators: [this.us.usernameValidator()],
            }],
            password: ['', Validators.required],
            confirm_password: ['', Validators.required],
        });

        this.mailingForm = this._fb.group({
            country_id: ['', Validators.required],
            other_country: [''],
            address_1: ['', Validators.required],
            address_2: [''],
            address_3: [''],
            city: ['', Validators.required],
            state_id: ['', Validators.required],
            other_state: [''],
            postal_code: ['', Validators.required],
        });

        this.billingForm = this._fb.group({
            country_id: ['', Validators.required],
            other_country: [''],
            address_1: ['', Validators.required],
            address_2: [''],
            address_3: [''],
            city: ['', Validators.required],
            state_id: ['', Validators.required],
            other_state: [''],
            postal_code: ['', Validators.required],
        });

        this.compForm = this._fb.group({
            mailingGroup: this.mailingForm,
            billingGroup: this.billingForm
        })

        this.contractForm = this._fb.group({
            accept: false,
        });
    }

	ngOnInit() {

        this.shipOnly = this.as.getShipOnly()

        this.billingForm.get('country_id').valueChanges.subscribe(v => {
            this.addressValidate();
        });
        this.mailingForm.get('country_id').valueChanges.subscribe(v => {
            this.addressValidate();
        });

		this.route.data.subscribe(data => {
            log.Debug("data: ", data)

            this.company = data.company;
			this.event = data.event;
            this.roles = data.roles;
            this.companyTypes = data.companyTypes;
            log.Debug("comapnyTypesID: ", this.companyTypes.find(x => x.name === 'Supplier').id);
			if(this.companyTypes.find(x => x.id === data.company.company_type_id).name == "The Home Depot") {
				this.contractNotApplicable = true
			}


            if( this.company != null ) {
                forkJoin([
                    this.CountryService.getAll(),
                    this.ss.getAll(),
                    this.adds.get(this.company['billing_address_id']),
                    this.adds.get(this.company['mailing_address_id']),
                ]).subscribe(([countries, states, b, m]: [Country[], any[], {}, {}]) => {
                    this.countries = countries;
                    this.states = states;
                    log.Debug("states: ", this.states)
                    if(m){
                        this.compForm.patchValue({
                            mailingGroup: m,
                        });
                    }
                    if(b){
                        this.compForm.patchValue({
                            billingGroup: b,
                        });
                    }
                    log.Debug("compForm: ", this.compForm)
                });
            } else {
                this._snackBar.open('Invalid invitation code.', 'Ok' , {
                    verticalPosition: 'top',
                    panelClass: 'largeSnack'
                });
                this.appService.navigate(['/login']);
            }
		});

        this.getUser = this.us;
	}



    addressValidate(): any {
        var addressForm = [this.billingForm,this.mailingForm];
        var numForms = 2;
        if(this.sameAsBilling){numForms = 1;}
        for(var i=0;i < numForms;i++){
            var countryId = addressForm[i].controls['country_id'].value;
            if(countryId == this.countries.find(x => x.short_name === 'US').id){
                addressForm[i].controls['state_id'].setValidators([Validators.required]);
                addressForm[i].controls['other_state'].setValidators([]);
                addressForm[i].controls['other_country'].setValidators([]);
            }else if(countryId == this.countries.find(x => x.short_name === 'MX').id || countryId == this.countries.find(x => x.short_name === 'CA').id){
                addressForm[i].controls['state_id'].clearValidators();
                addressForm[i].controls['other_state'].setValidators([Validators.required]);
                addressForm[i].controls['other_country'].clearValidators();
            }else if(countryId == this.countries.find(x => x.short_name === 'OTH').id){
                addressForm[i].controls['state_id'].clearValidators();
                addressForm[i].controls['other_state'].setValidators([Validators.required]);
                addressForm[i].controls['other_country'].setValidators([Validators.required]);
            }
            addressForm[i].controls['other_country'].updateValueAndValidity();
            addressForm[i].controls['other_state'].updateValueAndValidity();
            addressForm[i].controls['state_id'].updateValueAndValidity();
        }
    }

    addressesInvalid(){

        if(this.shipOnly){
            if(!this.mailingForm.invalid){
                return false;
            }
        }else{
            if(this.sameAsBilling){
                if(!this.billingForm.invalid){
                    return false;
                }
            }else{
                if(!this.billingForm.invalid && !this.mailingForm.invalid){
                    return false;
                }
            }
        }

        return true;
    }

    createError = (err): Observable<any> => {
        this.submitted = false;
        return of({failed: true, error: err})
    }

    submit(){
        this.submitted = true;

        if(this.userForm.invalid){
            this._snackBar.open('Please fill out all required fields!',null ,{duration: 3000});
            this.submitted = false;
            return;
        }
        if(this.userForm.controls['password'].value != this.userForm.controls['confirm_password'].value){
            this._snackBar.open('Passwords do not match!',null ,{duration: 3000});
            this.submitted = false;
            return;
        }
        let user = new User(this.userForm.value);
        if( this.company.first_time ) {
            user.is_primary = true
            this.company.contact_name = user.first_name+" "+user.last_name
            this.company.contact_email = user.email
            this.company.contact_title = user.title
            this.company.phone_number = user.phone
            this.company.phone_ext = user.ext
        }else{
            user.is_primary = false
        }

        if(this.company.company_type_id == this.companyTypes.find(x => x.name === 'Hartmann').id){
            user.role_id = this.roles.find(x => x.name === 'Admin').id;
        }else if(this.company.company_type_id == this.companyTypes.find(x => x.name === 'Hartmann Vendor').id){
            user.role_id = this.roles.find(x => x.name === 'Vendor').id;
        }else{
            user.role_id = this.roles.find(x => x.name === 'Supplier').id;
        }

        this.company.first_time = false;
        user.company_id = this.company.id;
        user.active = true;
        //TODO move setting role, company, etc from client side to server side (avoid manipulation, this is a public facing API point)

        let billing;
        if(this.shipOnly){
            billing = Object.assign(new Address(), this.compForm.get('mailingGroup').value);
        }else{
            billing = Object.assign(new Address(), this.compForm.get('billingGroup').value);
        }
        billing.id = this.company.billing_address_id;
        let mailing;
        if(this.sameAsBilling){
            mailing = Object.assign(new Address(), this.compForm.get('billingGroup').value);
        }else{
            mailing = Object.assign(new Address(), this.compForm.get('mailingGroup').value);
        }
        mailing.id = this.company.mailing_address_id;
        if(mailing.country_id == 1){
            mailing.other_country = null;
            mailing.other_state = null;
        }else if(mailing.country_id > 1 && mailing.country_id < 4){
            mailing.other_country = null;
            mailing.state_id = null;
        }else{mailing.state_id = null;}

        if(billing.country_id == 1){
            billing.other_country = null;
            billing.other_state = null;
        }else if(billing.country_id > 1 && billing.country_id < 4){
            billing.other_country = null;
            billing.state_id = null;
        }else{billing.state_id = null;}

        log.Debug("billing: ", billing);
        log.Debug('mailing: ', mailing);

        if( Boolean(this.contractForm.value.accept) == true ) {
            if(this.company["contract_status"] === 'Not Presented'){
                this.company["contract_status"] = "Accepted Before Presented"
            }else{
                this.company["contract_status"] = "Accepted"
            }
			this.company["update_contract_status"] = true;
        }
        log.Debug("company: ", this.company);
        //TODO make one public endpoint that handles all of this, required to post invitation code to do
        delete this.company.contract_status_id;

        if(user.is_primary){
            forkJoin([
                this.adds.save(billing).pipe(
                    catchError( this.createError ),
                ),
                this.adds.save(mailing).pipe(
                    catchError( this.createError ),
                ),
                this.us.activate(user).pipe(
                    catchError( this.createError )
                ),
                this.cs.updateContractStatus(this.company).pipe(
                    catchError( this.createError )
                )
            ]).subscribe(([b, m, u, cs]) => {
                log.Debug("response: ", b, m, u, cs);
                this.company.contract_status_id = cs;
                this.as.setContractStatus(this.company['contract_status']);
                this.company.billing_address_id = b['id'];
                this.company.mailing_address_id = m['id'];
                this.cs.savePrimarySetup(this.company).pipe(
                    catchError( this.createError ),
                ).subscribe(c => {
                    this.as.login(user).subscribe(r => {
                        this.appService.navigate(['/']);
                    });
                });

            });
        }else{
            this.us.activate(user).pipe(
                catchError( this.createError )
            ).subscribe(c => {
                this.as.login(user).subscribe(r => {
                    this.appService.navigate(['/']);
                });
            });
        }



    }

}
