<footer>
	<div class="maxcontainer">
		<p>&copy; {{ currentYear }}, HARTMANN STUDIOS, Inc. All rights reserved.</p>
		<div class="link-wrap">
			<a target="_blank" href="{{ event.privacy_policy_link }}">Privacy Policy</a>
			<a target="_blank" href="{{ event.website_user_agreement_link }}">Terms of Use</a>
			<a [routerLink]="['/app/info/Contact%2520Us/contact-us']">Contact Us</a>
		</div>
		<div class="clear-footer"></div>
	</div>
</footer>
