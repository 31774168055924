import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { log } from '@common/log/log';

@Injectable({
    providedIn: 'root'
})

export class FileServerService {

    constructor(
        private http: HttpClient
    ){}

    GetByFilePath(filepath: string) {
        return this.http.post(environment.api + "/serve_files/by_filepath", {Filepath: filepath}, {responseType: 'blob'}).pipe(a => a)
    }

}
