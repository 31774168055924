<mat-card appearance="outlined">
    <mat-card-title>
        <span>Shipment Manifest</span>
    </mat-card-title>
    <mat-card-content>
    <h4>Manifest Details</h4>
    <div class="row gutters">
        <div class="col">
            <p><strong>Company Name:</strong> {{ company.name }}</p>
            <p><strong>Manifest Number:</strong> {{ manifest.manifest_number }}</p>
            <p><strong>Brand Name(s):</strong> {{ manifest.brand }}</p>
            <p><strong>Shipment Method:</strong>{{ manifest.shipment_method }}</p>
            <p *ngIf="event.has_tsl_requested"><strong>TSL Quote Requested:</strong> {{ manifest.is_tsl_requested ? 'X' : '' }}</p>
            <p><strong>Origination Address:</strong> {{ manifest.origination_address }}</p>
        </div>
        <div class="col">
            <p><strong>Destination:</strong> {{ shippingDestination.name }}</p>
            <p><strong>Shipping Department:</strong> {{ department.name }}</p>
            <p><strong>Customs Shipment?:</strong> {{ manifest.is_customs_shipment ? 'Yes' : 'No' }}</p>
            <p><strong>Ship Date:</strong> {{ manifest.ship_date | date:'MM/dd/yyyy'}}</p>
            <p><strong>Arrival Date:</strong> {{ manifest.arrive_date | date:'MM/dd/yyyy' }}</p>
            <p><strong>Notes:</strong> {{ manifest.notes }}</p>
        </div>
    </div>
    <h4>Shipment Summary</h4>
    <table mat-table [dataSource]="manifestPackages" class="table mat-elevation-z7 gutters sticky-header">
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Package Quantity</th>
            <td mat-cell data-label="Package Quantity" *matCellDef="let element">{{ element.quantity }}</td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Package Type </th>
            <td mat-cell data-label="Package Type" *matCellDef="let element">{{ element.type }}</td>
        </ng-container>
        <ng-container matColumnDef="width">
            <th mat-header-cell *matHeaderCellDef> Package Width(Inches) </th>
            <td mat-cell data-label="Package Width" *matCellDef="let element">{{ element.width }}</td>
        </ng-container>
        <ng-container matColumnDef="height">
            <th mat-header-cell *matHeaderCellDef> Package Height(Inches) </th>
            <td mat-cell data-label="Package Height" *matCellDef="let element">{{ element.height }}</td>
        </ng-container>
        <ng-container matColumnDef="depth">
            <th mat-header-cell *matHeaderCellDef> Package Depth(Inches) </th>
            <td mat-cell data-label="Package Depth" *matCellDef="let element">{{ element.depth }}</td>
        </ng-container>
        <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Weight per Package(Pounds) </th>
            <td mat-cell data-label="Weight per Package" *matCellDef="let element">{{ element.weight }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="packageColumns;sticky:true;"></tr>
        <tr mat-row *matRowDef="let row; columns: packageColumns;"></tr>

    </table>

    <table mat-table [dataSource]="manifestLineItems" class="table mat-elevation-z7 gutters sticky-header">
        <ng-container matColumnDef="item">
            <th mat-header-cell *matHeaderCellDef> Line Item </th>
            <td mat-cell data-label="Line Item" *matCellDef="let element">{{ element.item }}</td>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Item Quantity </th>
            <td mat-cell data-label="Item Quantity" *matCellDef="let element">{{ element.qty }}</td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Item Description </th>
            <td mat-cell data-label="Item Description" *matCellDef="let element">{{ element.description }}</td>
        </ng-container>
        <ng-container matColumnDef="display_type">
            <th mat-header-cell *matHeaderCellDef> Display Type </th>
            <td mat-cell data-label="Display Type" *matCellDef="let element">{{ element.type }}</td>
        </ng-container>
        <ng-container matColumnDef="sku">
            <th mat-header-cell *matHeaderCellDef> SKU </th>
            <td mat-cell data-label="SKU" *matCellDef="let element">{{ element.sku }}</td>
        </ng-container>
        <ng-container matColumnDef="discard">
            <th mat-header-cell *matHeaderCellDef>Discard</th>
            <td mat-cell data-label="Discard" *matCellDef="let element">{{ element.is_discard ? 'X' : '' }}</td>
        </ng-container>
        <ng-container matColumnDef="return">
            <th mat-header-cell *matHeaderCellDef> Return </th>
            <td mat-cell data-label="Return" *matCellDef="let element">{{ element.is_return ? 'X' : '' }}</td>
        </ng-container>
        <ng-container matColumnDef="return_method">
            <th mat-header-cell *matHeaderCellDef> Return Ship Method</th>
            <td mat-cell data-label="Return Ship Method" *matCellDef="let element">{{ element.return_method }}</td>
        </ng-container>
        <ng-container matColumnDef="donate">
            <th mat-header-cell *matHeaderCellDef> Donate </th>
            <td mat-cell data-label="Donate" *matCellDef="let element">{{ element.is_donate ? 'X' : '' }}</td>
        </ng-container>
        <ng-container matColumnDef="donate_value">
            <th mat-header-cell *matHeaderCellDef> Est. Value(USD)</th>
            <td mat-cell data-label="Est. Value(USD)" *matCellDef="let element">{{ element.donated_value }}</td>
        </ng-container>
        <ng-container matColumnDef="return_address">
            <th mat-header-cell *matHeaderCellDef>{{ event.return_address_column_name }}</th>
            <td mat-cell data-label="Return Address" *matCellDef="let element">{{ element.return_address }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <ul>
        <li>The manifest form is unique to this shipment. For shipments to different destinations or for different display types, return to the <strong>New Shipment</strong> page after completing this process to create a new shipment and manifest</li>
        <li><span style="color:red; text-transform: uppercase;">Print Manifest. Every shipment must include a copy of the manifest.<br>Print label, make copies.<br>FILL IN PIECE COUNT AND LOCATION ID AND ATTACH IN SEVERAL PLACES TO EVERY BOX, CRATE OR PALLET.</span></li>
    </ul>

    </mat-card-content>
    <mat-card-actions>
        <button [disabled]="manifest.uploaded == null" (click)="openManifestLabel()" mat-raised-button color="accent">Print Manifest</button>
        <button [disabled]="manifest.uploaded == null" (click)="openPrintLabel()" mat-raised-button color="accent">Print Label</button>
<!--         <button [disabled]="['supplier', 'supplier_primary_contact', 'supplier_ship_only'].indexOf(currentRole()) !== -1" style="margin-bottom: 2px;" color="accent" [routerLink]="['../../shipment/', manifest.id, 'manifest']" mat-raised-button >Edit Shipment</button> -->
        <button style="margin-bottom: 2px;" color="accent" [routerLink]="['../../shipment/', manifest.id, 'contact-info']" mat-raised-button >Edit Shipment</button>
    </mat-card-actions>
</mat-card>
