import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomInvoiceService } from '@common/services/custom-invoice.service';
import { FormGroup, FormBuilder, FormControl} from '@angular/forms';
import { CustomInvoice } from '@models/custom-invoice';
import { InvoiceTemplateService } from '@common/services/invoice-template.service';

@Component({
  selector: 'app-invoices-dialog',
  templateUrl: './invoices-dialog.component.html',
  styleUrls: ['./invoices-dialog.component.css']
})
export class InvoicesDialogComponent implements OnInit {

  public displayedColumns = ['invoice_number', 'file', 'amount', 'is_added_to_invoice', 'delete'];
  public invoices;
  public addForm: FormGroup

  constructor(
    public dialogRef: MatDialogRef<InvoicesDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
    public CustomInvoiceService: CustomInvoiceService,
    public FormBuilder: FormBuilder,
    public InvoiceTemplateService: InvoiceTemplateService
  ) { }

  ngOnInit() {
    this.addForm = this.FormBuilder.group(new CustomInvoice());
    this.CustomInvoiceService.getByCompany(this.data.company.id).subscribe(r => {
      this.invoices = r;
    });
  }

  add(){
    this.CustomInvoiceService.add({
      amount: this.addForm.get('amount').value,
      company_id: this.data.company.id,
      file: this.addForm.get('file').value,
      // id: this.addForm.get('id').value,
      invoice_number: this.addForm.get('invoice_number').value,
      is_added_to_invoice: this.addForm.get('is_added_to_invoice').value || false,
      paid: this.addForm.get('paid').value,
      event_id: this.data.company.event_id
    }).subscribe(r => {
      this.ngOnInit();
    });
  }

  update(data){
    this.CustomInvoiceService.update(data).subscribe(r => {
      this.ngOnInit();
    });
  }

  delete(data){
    if(confirm("Are you sure?")){
      this.CustomInvoiceService.delete(data).subscribe(r => {
        this.ngOnInit();
      });
    }
  }

  promptXLSX() {
    alert('File must be xlsx');
  }

  upload($event) {
		let fileData = new FormData()
		fileData.append('file', $event.target.files[0])
		this.InvoiceTemplateService.uploadInvoiceTemplateForCompany(this.data.company.id, fileData).subscribe(r => {
			this.addForm.patchValue({file: r});
		});
  }

  uploadTo($event, index) {
		let fileData = new FormData()
		fileData.append('file', $event.target.files[0])
		this.InvoiceTemplateService.uploadInvoiceTemplateForCompany(this.data.company.id, fileData).subscribe(r => {
      this.invoices[index].file = r;
      this.update(this.invoices[index]);
		});
	}

}
