<div class="under-construction" style="">
    <div class="under-construction-content">
        <img src="{{ event.horizontal_logo }}" />
        <h1>Website<br>is<br>Under Construction</h1>
        <p>For asistance please contact</p>
        <p>
            Associate Producer: Georgia Vallecoccia
            <br>Email: <a href="mailto:georgiav@hartmannstudios.com">georgiav@hartmannstudios.com</a>
            <br><!--Phone: Office(<a href="tel:678-904-3506">678-904-3506</a>) or -->Mobile(<a href="tel:615-335-6204">615-335-6204</a>)
        </p>
    </div>
</div>
