import { Routes } from '@angular/router';
import { MenusComponent } from './menus.component';
import { AddMenuComponent } from './add-menu/add-menu.component';
import { EditMenuComponent } from './edit-menu/edit-menu.component';

export const MENU_ROUTES: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: MenusComponent,

            },
        ]
    },
    {
        path: 'add',
        component: AddMenuComponent
    },
    {
        path: "edit/:id",
        component: EditMenuComponent
    }
]

export const MENU_COMPONENTS = [
    MenusComponent,
    AddMenuComponent,
    EditMenuComponent
];
