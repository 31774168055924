<div class="container-fluid">
    <div class="row">
        <div class="col">
            <h2>Edit Shipping Destination</h2>
        </div>
    </div>
    <form [formGroup]="form" (submit)="save()">
        <div class="row gutters">
            <div class="col">
                <mat-form-field>
                    <input required formControlName="name" matInput placeholder="Name" />
                </mat-form-field>
                <mat-form-field>
                  <input formControlName="inbound_lock" matInput [matDatepicker]="inbound_lock" placeholder="Default Inbound Lock Date">
                  <mat-datepicker-toggle matSuffix [for]="inbound_lock"></mat-datepicker-toggle>
                  <mat-datepicker #inbound_lock></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                  <input formControlName="outbound_lock" matInput [matDatepicker]="outbound_lock" placeholder="Default Outbound Lock Date">
                  <mat-datepicker-toggle matSuffix [for]="outbound_lock"></mat-datepicker-toggle>
                  <mat-datepicker #outbound_lock></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                    <input formControlName="receiving_hours" matInput placeholder="Receiving Hours" />
                </mat-form-field>
                <mat-form-field>
                    <input matInput formControlName="contact_name" placeholder="Contact Name" />
                </mat-form-field>
                <mat-form-field>
                    <input matInput mask="(000-000-0000)" [dropSpecialCharacters]="['(', ')']" formControlName="contact_phone" placeholder="Contact Phone #" />
                </mat-form-field>
                <mat-form-field>
                    <input matInput formControlName="contact_email" placeholder="Contact Email" />
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country_id" name="country" required>
                        <mat-option  *ngFor="let country of countries" [value]=country.id>{{country.long_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="selectedCountry == 3">
                    <input matInput formControlName="other_country" name="other_country" placeholder="Other Country" />
                </mat-form-field>
                <mat-form-field>
                    <input matInput formControlName="ship_company" placeholder="Ship To Company" />
                </mat-form-field>
                <mat-form-field>
                    <input matInput required formControlName="address_1" placeholder="Address Line 1" />
                </mat-form-field>
                <mat-form-field>
                    <input matInput formControlName="address_2" placeholder="Address Line 2" />
                </mat-form-field>
                <mat-form-field>
                    <input matInput formControlName="address_3" placeholder="Address Line 3" />
                </mat-form-field>
                <mat-form-field>
                    <input matInput required formControlName="city" placeholder="City" />
                </mat-form-field>
                <mat-form-field *ngIf="selectedCountry == 1">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state_id" name="state" required>
                        <mat-option *ngFor="let state of states" [value]=state.id>{{state.long_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="selectedCountry > 1">
                    <input matInput required formControlName="other_state" name="other_state" placeholder="State" />
                </mat-form-field>
                <mat-form-field>
                    <input matInput required formControlName="postcode" placeholder="Postal Code" />
                </mat-form-field>
                <div class="row">
                    <mat-checkbox formControlName="custom_lock_dates">Custom Lock Dates</mat-checkbox>
                </div>
                <div class="row" *ngIf="form.value.custom_lock_dates">
                    <a mat-icon-button  (click)="editLockDates(form.value)" color="primary" matTooltip="Edit Custom Lock Dates"><mat-icon>calendar_today</mat-icon></a>
                </div>
                <div class="row">
                    <mat-checkbox formControlName="active">Active</mat-checkbox>
                </div>
            </div>
        </div>
        <button [disabled]="form.invalid" mat-raised-button type="submit">Save</button>
        <button style="margin-left: 5px;" color="basic" mat-raised-button (click)="this.location.back();">Cancel</button>
    </form>
</div>