import {CompaniesComponent} from './companies.component';
import { AddCompanyComponent } from './add-company/add-company.component';
import {EditCompanyComponent} from './edit-company/edit-company.component';
//import { CompanyComponent } from './company/company.component';
import { EventResolve } from '../../event/event.resolve';

export const COMPANY_ROUTES = [
	{
		path: 'companies',
		component: CompaniesComponent
	},
	{
		path: 'companies/add',
		component: AddCompanyComponent,
		resolve: {
			event: EventResolve,
		}
	},
	{
		path: 'companies/edit/:id',
		component: EditCompanyComponent
	}
];

export const COMPANY_COMPONENTS = [
	CompaniesComponent,
	AddCompanyComponent,
	EditCompanyComponent,
	//CompanyComponent
];
