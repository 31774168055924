<form [formGroup]="addForm" (submit)="add()">
	<div class="row">
		<div class="col">
			<mat-form-field>
				<input formControlName="invoice_number" matInput placeholder="Invoice Number" />
			</mat-form-field>
		</div>
		<div class="col">
			<mat-form-field>
        		<input formControlName="amount" matInput type="number" placeholder="Invoice Amount (before processing fee)" />
      		</mat-form-field>
		</div>
		<div class="col">
				<mat-checkbox formControlName="is_added_to_invoice">Add to invoice?</mat-checkbox>
		</div>
		<div class="col">
      		<label>
	   			Upload Invoice
				<input type="file" (click)="promptXLSX()" (change)="upload($event)"/>
	  		</label>
		</div>
		<div class="col">
			<button type="submit" mat-raised-button color="primary">Add</button>
		</div>
	</div>
</form>
<div class="row">
	<div class="col">
		<table mat-table [dataSource]="invoices" class="table mat-elevation-z7 gutters">

			<ng-container matColumnDef="invoice_number">
			    <th mat-header-cell *matHeaderCellDef> Invoice Number </th>
			    <td mat-cell data-label="Invoice Number" *matCellDef="let element"> 
              <mat-form-field>
                <input [(ngModel)]="element.invoice_number" (change)="update(element)" matInput placeholder="Invoice Number" />
              </mat-form-field>
				  </td>
      		</ng-container>
      
      		<ng-container matColumnDef="amount">
			    <th mat-header-cell *matHeaderCellDef> Amount </th>
			    <td mat-cell data-label="Amount" *matCellDef="let element"> 
              <mat-form-field>
                <input [(ngModel)]="element.amount" (change)="update(element)" type="number" matInput placeholder="Amount" />
              </mat-form-field>
				  </td>
      		</ng-container>

			<ng-container matColumnDef="is_added_to_invoice">
				<th mat-header-cell *matHeaderCellDef> Added to Invoice </th>
			    <td mat-cell data-label="Added to Invoice" *matCellDef="let element"> 
                		<mat-checkbox [(ngModel)]="element.is_added_to_invoice" (change)="update(element)">Added to Invoice?</mat-checkbox>
				</td>
      		</ng-container>
      
      		<ng-container matColumnDef="file">
			    <th mat-header-cell *matHeaderCellDef> File </th>
			    <td mat-cell data-label="File" *matCellDef="let element; let i = index"> 
					{{element.file}}
						<input type="file" (click)="promptXLSX()" (change)="uploadTo($event, i)" />
			  		<a *ngIf="element.file != null" href="{{ element.file }}" target="_blank">Download</a>
				</td>
			</ng-container>

		

			<ng-container matColumnDef="delete">
				<th mat-header-cell *matHeaderCellDef>Delete</th>
				<td mat-cell data-label="Delete" *matCellDef="let element">
					<a mat-icon-button color="accent" (click)="delete(element)"><mat-icon>delete</mat-icon></a>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>
</div>