import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[appDragDrop]'
})

export class PglFileDropDirective {

    @Output() onFileDropped = new EventEmitter<any>();

    @HostListener('dragover', ['$event']) public onDragOver(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    @HostListener('drop', ['$event']) public ondrop(e) {
        e.preventDefault();
        e.stopPropagation();
        let files = e.dataTransfer.files;
        if(files.length > 0) {
            this.onFileDropped.emit(files)
        }
    }
}