<mat-toolbar color="accent">
	<div class="maxcontainer">
		<a class="space" [routerLink]="['/', 'login']"><img style="max-height: 60px;" src="{{ event.logo }}" /></a>
	</div>
</mat-toolbar>
<div class="container full-height-no-sidebar">
	<div class="row">
		<div class="col">
			<mat-card appearance="outlined">
				<mat-card-header>
					<mat-card-title>
						Sign Up
					</mat-card-title>
					<mat-card-subtitle>
						Enter your company's invitation code to continue
					</mat-card-subtitle>
				</mat-card-header>
				<mat-card-content>
					<form (submit)="signup()" [formGroup]="invitationForm">
						<mat-form-field>
							<input matInput placeholder="Invitation Code" name="code" formControlName="code" />
						</mat-form-field>
						<button mat-raised-button color="accent">Submit</button>
					</form>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>
<app-footer [event]="event"></app-footer>
