<form (submit)="save()" [formGroup]="form">
  <div class="container-fluid tall">
      <div class="row">
          <div class="col">
              <mat-card appearance="outlined">
                  <mat-card-header>
                      <mat-card-title>
                              Register onsite representatives for March 18-19, 2020.
                      </mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                          <p>Online registration closes on March 2, 2020.</p>
                  </mat-card-content>
              </mat-card>
          </div>
      </div>
      <div class="row">
        <div class="col">
            <mat-card appearance="outlined">
                <mat-card-header>
                    <mat-card-title>
                      Select company to register
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <mat-form-field>
                    <mat-label>Company</mat-label>
                    <mat-select formControlName="company_id">
                        <mat-option>
                            <ngx-mat-select-search 
                                [formControl]="companyFilterCtrl"
                                [placeholderLabel]="'Find company...'" 
                                [noEntriesFoundLabel]="'no matching company found'">
                                <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option [value]=null>All</mat-option>
                        <mat-option *ngFor="let c of filteredCompanies" [value]=c.id>{{c.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
      <div class="row">
          <div class="col">
              <mat-card appearance="outlined">
                  <mat-card-header>
                      <mat-card-title>Basic Information</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                      <mat-form-field>
                          <input matInput type="text" name="badge_first_name" formControlName="badge_first_name" placeholder="Badge First Name" />
                      </mat-form-field>
                      <mat-form-field>
                          <input matInput name="badge_last_name" type="text" formControlName="badge_last_name" placeholder="Badge Last Name" />
                      </mat-form-field>
                      <mat-form-field>
                          <input matInput name="mobile_phone" type="tel" mask="(000-000-0000)" [dropSpecialCharacters]="['(', ')']" formControlName="mobile_phone" placeholder="Mobile Phone" />
                          <mat-hint>Format should be (xxx-xxx-xxxx)</mat-hint>
                      </mat-form-field>
                      <mat-form-field>
                         <input matInput name="email" type="text" formControlName="email" placeholder="Email" />
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput name="title" type="text" formControlName="title" placeholder="Title" />
                    </mat-form-field>
                      <mat-form-field>
                          <mat-label>Onsite Role</mat-label>
                          <mat-select formControlName="onsite_role" name="onsite_role">
                              <mat-option [value]="'Set Up'">Set Up</mat-option>
                              <mat-option [value]="'Show'">Show</mat-option>
                              <mat-option [value]="'Both'">Both</mat-option>
                          </mat-select>
                      </mat-form-field>
                          <mat-checkbox name="primary_onsite" formControlName="primary_onsite">Primary Onsite Contact</mat-checkbox>
                  </mat-card-content>
              </mat-card>
          </div>
          <div class="col">
              <mat-card appearance="outlined">
                  <mat-card-header>
                      <mat-card-title>In Case Of Emergency Please Contact</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                      <mat-form-field>
                          <input matInput type="text" name="emergency_contact" formControlName="emergency_contact" placeholder="Name" />
                      </mat-form-field>
                      <mat-form-field>
                          <input matInput type="text" formControlName="emergency_contact_relationship" placeholder="Relationship" />
                      </mat-form-field>
                      <mat-form-field>
                          <input matInput type="tel" mask="(000-000-0000)" [dropSpecialCharacters]="['(', ')']" formControlName="emergency_contact_phone" placeholder="Phone" />
                          <mat-hint>Format should be (xxx-xxx-xxxx)</mat-hint>
                        </mat-form-field>
                  </mat-card-content>
              </mat-card>
          </div>
      </div>
      <div class="row">
          <div class="col">
              <button type="submit" mat-raised-button>Save Registration</button>
          </div>
      </div>
  </div>
</form>

