<form [formGroup]="form" (submit)="next()">
  <span *ngIf="newEntry">Step 2 of 3</span>
  <div class="row" style="max-width: 300px">
    <div class="col">
      <mat-form-field>
        <!-- ? Shipping date should not be locked -->
        <input
          required
          matInput
          (dateChange)="updateArrival($event)"
          formControlName="ship_date"
          [matDatepicker]="ship_date"
          placeholder="Choose a Ship Date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="ship_date"
        ></mat-datepicker-toggle>
        <mat-datepicker #ship_date></mat-datepicker>
        <mat-error *ngIf="form.get('ship_date').hasError('required')"
          >This is a required field</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <!-- ? Arrival date should use the inbound and outbound lock dates -->
        <input
          required
          matInput
          [min]="minShipDate"
          [max]="maxShipDate"
          formControlName="arrive_date"
          [matDatepicker]="arrive_date"
          placeholder="Choose an Arrival Date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="arrive_date"
        ></mat-datepicker-toggle>
        <mat-datepicker #arrive_date></mat-datepicker>
        <mat-error *ngIf="form.get('arrive_date').hasError('required')"
          >This is a required field</mat-error
        >
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <p>{{ destination.name }}</p>
      <p>{{ destination.city }}, {{ destination.postcode }}</p>
      <p>
        <span class="b">Receiving Dates: </span>{{ destination.inbound_lock | date }} -
        {{ destination.outbound_lock | date }}
      </p>
      <p>
        <span class="b">Receiving Hours: </span>
        {{ destination.receiving_hours }}
      </p>
      <p><span class="b">Contact: </span>{{ destination.contact_name }}</p>
      <p><span class="b">Phone: </span>{{ destination.contact_phone }}</p>
      <!-- <p>
        <span class="b">Contact Email:</span> {{ destination.contact_email }}
      </p> -->
    </div>
  </div>
  <div class="row">
    <div class="col">
      <a
        *ngIf="!newEntry"
        [routerLink]="['../manifest']"
        mat-raised-button
        color="accent"
        >No Changes</a
      >
      <button
        [disabled]="form.invalid"
        *ngIf="!newEntry"
        mat-raised-button
        color="accent"
        class="gutters no-bottom"
      >
        Save
      </button>
      <button
        [disabled]="form.invalid"
        *ngIf="newEntry"
        mat-raised-button
        color="accent"
        class="gutters no-bottom"
      >
        Next
      </button>
    </div>
  </div>
</form>
