<div class="container-fluid">
	<div class="row">
		<div class="col">
			<a [routerLink]="['add']" mat-raised-button color="accent"><mat-icon>add</mat-icon>Add Shipping Destination</a>
		</div>
		<div class="col">
<!-- 			<mat-form-field>
				<input matInput name="search" placeholder="Search Shipping Destinations" />
			</mat-form-field> -->
		</div>
	</div>
	<div id="print-section">
		<div class="row">
			<div class="col">
				<table mat-table [dataSource]="shippingDestinations" class="table mat-elevation-z7 gutters sticky-header">

					  <ng-container matColumnDef="name">
					    <th mat-header-cell *matHeaderCellDef> Name </th>
					    <td mat-cell data-label="Name" *matCellDef="let element"> {{element.name}} </td>
					  </ng-container>

					  <ng-container matColumnDef="ship_company">
					    <th mat-header-cell *matHeaderCellDef> Name </th>
					    <td mat-cell data-label="Ship Company" *matCellDef="let element"> {{element.ship_company}} </td>
					  </ng-container>

					  <ng-container matColumnDef="address_1">
					    <th mat-header-cell *matHeaderCellDef> Address 1 </th>
					    <td mat-cell data-label="Address 1" *matCellDef="let element"> {{element.address_1}} </td>
					  </ng-container>

					  <ng-container matColumnDef="address_2">
					    <th mat-header-cell *matHeaderCellDef> Address 2 </th>
					    <td mat-cell data-label="Address 2" *matCellDef="let element"> {{element.address_2}} </td>
					  </ng-container>

					  <ng-container matColumnDef="address_3">
					    <th mat-header-cell *matHeaderCellDef> Address 3 </th>
					    <td mat-cell data-label="Address 3" *matCellDef="let element"> {{element.address_3}} </td>
					  </ng-container>

					  <ng-container matColumnDef="city">
					    <th mat-header-cell *matHeaderCellDef> City </th>
					    <td mat-cell data-label="City" *matCellDef="let element"> {{element.city}} </td>
					  </ng-container>

					  <ng-container matColumnDef="state">
					    <th mat-header-cell *matHeaderCellDef> State </th>
					    <td mat-cell data-label="State" *matCellDef="let element"> {{getStateNameById(element.state_id)}} </td>
					  </ng-container>

					  <ng-container matColumnDef="postal_code">
					    <th mat-header-cell *matHeaderCellDef> Postal Code </th>
					    <td mat-cell data-label="Postal Code" *matCellDef="let element"> {{element.postcode}} </td>
					  </ng-container>

					  <ng-container matColumnDef="inbound_lock">
					    <th mat-header-cell *matHeaderCellDef> Inbound Lock </th>
					    <td mat-cell data-label="Inbound Lock" *matCellDef="let element"> {{element.inbound_lock | date}} </td>
					  </ng-container>

					  <ng-container matColumnDef="outbound_lock">
					    <th mat-header-cell *matHeaderCellDef> Outbound Lock </th>
					    <td mat-cell data-label="Outbound Lock" *matCellDef="let element"> {{element.outbound_lock | date}} </td>
					  </ng-container>

					  <ng-container matColumnDef="active">
					  	<th mat-header-cell *matHeaderCellDef>Active</th>
					  	<td mat-cell data-label="Active" *matCellDef="let element">
					  		<mat-icon *ngIf="element.active">
					    		done
					    	</mat-icon>
					    	<mat-icon color="accent" *ngIf="!element.active">
					    		block
					    	</mat-icon>
					  	</td>
					  </ng-container>

					  <ng-container matColumnDef="actions">
					  	<th mat-header-cell data-label="Actions" *matHeaderCellDef>Actions</th>
					  	<td mat-cell data-label="Actions" *matCellDef="let element">
					  		<a mat-icon-button *ngIf="element.custom_lock_dates" (click)="editLockDates(element)" color="primary" matTooltip="Edit Custom Lock Dates"><mat-icon>calendar_today</mat-icon></a>
					  		<a mat-icon-button color="primary" matTooltip="Edit Shipping Destination" [routerLink]="['edit', element.id]"><mat-icon>edit</mat-icon></a>
					  		<a mat-icon-button color="accent" matTooltip="Delete Shipping Destination" (click)="delete(element)"><mat-icon>delete</mat-icon></a>
					  	</td>
					  </ng-container>

					  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
					  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>
			</div>
		</div>
	</div>
	<button style="margin-left: 10px;" type="button" mat-raised-button color="primary" (click)="print()">Print <mat-icon>print</mat-icon></button>
</div>