<div class="container-fluid">
	<h3>Edit Menu</h3>
	<form [formGroup]="menu" (submit)="save()">
		<div class="row">
			<div class="col">
				<mat-form-field>
					<input required matInput formControlName="name" placeholder="Name" />
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field>
					<input matInput formControlName="href" placeholder="Href" />
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-checkbox formControlName="active">Active</mat-checkbox>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field>
					<input matInput formControlName="allowed_role" placeholder="Allowed Roles" />
				</mat-form-field>
			</div>
		</div>
		<button [disabled]="menu.invalid" mat-raised-button type="submit">Save</button>
		<button type="button" style="margin-left: 5px;" color="basic" mat-raised-button (click)="Location.back();">Cancel</button>
	</form>
</div>