<mat-toolbar color="accent" [ngClass]="{'archive' : isArchive }">
	<div class="maxcontainer">
		<a class="space" [routerLink]="['/', 'login']"><img style="max-height: 60px;" src="{{ event.logo }}" /></a>
	</div>
</mat-toolbar>
<div class="container full-height-no-sidebar">
	<div class="row">
		<div class="col">
			<mat-card appearance="outlined">
				<mat-card-header>
					<mat-card-title>Login</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<form [formGroup]="loginForm" (submit)="login()">
						<mat-form-field>
							<input matInput name="username" formControlName="username" placeholder="User Name" />
						</mat-form-field>
						<mat-form-field>
							<input matInput name="password" formControlName="password" placeholder="Password" type="password" />
						</mat-form-field>
						<button mat-raised-button color="accent">Login</button>
                        <a class="desktop-last-button" mat-raised-button [routerLink]="['/forgot-password']">Forgot Password</a>
					</form>
				</mat-card-content>
			</mat-card>
		</div>
		<div class="col">
			<mat-card appearance="outlined">
				<mat-card-header>
					<mat-card-title>New User?</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<p>
						<a mat-raised-button [routerLink]="['/signup']">Create a new account</a>
					</p>
					<p>
						<span class="b">{{ event.login_text }}</span>
					</p>
				</mat-card-content>
			</mat-card>
		</div>
	</div>

</div>
<app-footer [event]="event"></app-footer>
