<div class="container-fluid">
	<div class="row">
		<div class="col">
			<a [routerLink]="['add']" mat-raised-button color="accent"><mat-icon>add</mat-icon>Add Invoice Template</a>
		</div>
		<div class="col">
<!-- 			<mat-form-field>
				<input matInput name="search" placeholder="Search Invoice Templates" />
			</mat-form-field> -->
		</div>
	</div>
	<div id="print-section">
		<div class="row">
			<div class="col">
				<table mat-table [dataSource]="invoice_templates" class="table mat-elevation-z7 gutters sticky-header">

					  <ng-container matColumnDef="name">
					    <th mat-header-cell *matHeaderCellDef> Name </th>
					    <td mat-cell data-label="Name" *matCellDef="let element"> {{element.name}} </td>
					  </ng-container>

					  <ng-container matColumnDef="file">
					    <th mat-header-cell *matHeaderCellDef> File </th>
					    <td mat-cell data-label="File" *matCellDef="let element"> {{element.file}} </td>
					  </ng-container>

					  <ng-container matColumnDef="default">
					    <th mat-header-cell *matHeaderCellDef> Default </th>
					    <td mat-cell data-label="Default" *matCellDef="let element"> 
							<span *ngIf="element.default_template">
								<mat-icon>check_circle</mat-icon>
							</span>	
							<span *ngIf="!element.default_template">
								<button mat-raised-button (click)="makeDefault(element)">Make Default</button>
							</span>
						</td>
					  </ng-container>

					  <ng-container matColumnDef="created">
					    <th mat-header-cell *matHeaderCellDef> Created </th>
					    <td mat-cell data-label="Created" *matCellDef="let element"> {{element.created | date}} </td>
					  </ng-container>

					  <ng-container matColumnDef="modified">
					    <th mat-header-cell *matHeaderCellDef> Modified </th>
					    <td mat-cell data-label="Modified" *matCellDef="let element"> {{element.modified | date}} </td>
					  </ng-container>

					  <ng-container matColumnDef="actions">
					  	<th mat-header-cell *matHeaderCellDef>Actions</th>
					  	<td mat-cell data-label="Actions" *matCellDef="let element">
					  		<a mat-icon-button color="primary" [routerLink]="['edit', element.id]"><mat-icon>edit</mat-icon></a>
					  		<a mat-icon-button color="accent" (click)="delete(element)"><mat-icon>delete</mat-icon></a>
					  	</td>
					  </ng-container>

					  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
					  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>
			</div>
		</div>
	</div>
	<button style="margin-left: 10px;" type="button" mat-raised-button color="primary" (click)="print()">Print <mat-icon>print</mat-icon></button>
</div>