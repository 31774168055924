<div style="padding: 10px;">
    <button style="margin-bottom: 5px;" type="button" mat-raised-button color="accent" (click)="print()">Print <mat-icon>print</mat-icon></button>
    <button style="margin-bottom:5px;margin-left:5px;" type="button" mat-raised-button color="accent" (click)="htmltoPDF()">Save as PDF <mat-icon>picture_as_pdf</mat-icon></button>
</div>
<div id="print-section">
    <p class="top-text"><b>{{ data.event.manifest_label_top_text }}</b></p>
    <div class="label-container">
        <div class="label-left">
            <img src="{{ data.event.horizontal_logo }}" style="width: 100%;max-width: 400px;height: auto;"/>
            <table class="left-table" style="width:100%">
                <tr>
                    <th>BRAND</th>
                    <td><div class="data-input">{{ data.dataKey.brand }}</div></td>
                </tr>
                <tr>
                    <th>SUPPLIER</th>
                    <td><div class="data-input">{{ shippingDestination.company_name }}</div></td>
                </tr>
                <tr>
                    <th>ADDRESS</th>
                    <td><div class="address">
                        <p>{{ companyMailingAddress.address_1 }}</p>
                        <p *ngIf="companyMailingAddress.address_2">{{ companyMailingAddress.address_2 }}</p>
                        <p *ngIf="companyMailingAddress.address_3">{{ companyMailingAddress.address_3 }}</p>
                        <p>{{ companyMailingAddress.city }}, {{ companyMailingAddress.state }} {{ companyMailingAddress.postal_code }}</p>
                    </div></td>
                </tr>
                <tr>
                    <th>SHIPPING CONTACT</th>
                    <td><div class="data-input">{{ data.dataKey.shipping_contact_name }}</div></td>
                </tr>
                <tr>
                    <th>PHONE</th>
                    <td><div class="data-input">{{ data.dataKey.shipping_contact_phone }}</div></td>
                </tr>
                <tr>
                    <th>EMAIL</th>
                    <td><div class="data-input">{{ data.dataKey.shipping_contact_email }}</div></td>
                </tr>
            </table>

        </div>

        <div class="label-right">
            <img src="/assets/img/hartmann-ita-group-logo-2025.png" />
            <p><b>SHIPMENT #</b></p>
            <div style="width:325px;margin: 0 auto 20px auto;text-align:center;font-size: 18px;" class="data-input">{{ data.dataKey.manifest_number }}</div>
            <p><b>PIECE #</b></p>
            <div class="piece-no">
                <div class="left-of"></div>
                <p class="of">OF</p>
                <div class="right-of"></div>
                <div class="clear"></div>
            </div>
            <p><b>ZONE</b></p>
            <div class="loc-id"></div>
            <ng-template [ngIf]="data.event.has_manifest_label_loc_id">
                <p><b>LOC. ID#</b></p>
                <div class="loc-id"></div>
            </ng-template>
        </div>
        <div class="clear"></div>
        <div class="bottom-wrapper">
            <div class="label-center">
                <p style="margin-bottom: 3px;"><b>DEPT. NAME</b></p>
                <div class="dept-name">
                    <p><b>{{ departmentInfo.name }}</b></p>
                </div>
            </div>
            <div class="label-left">
                <div class="dept-no">
                    <p style="display:block;font-size: 18px;margin-bottom: 3px;"><b>DEPT. #</b></p>
                    <div class="dept">
                        <p style="text-align:center;border:1px solid #999;display:block"><b>{{ departmentInfo.label }}</b></p>
                    </div>
                </div>
            </div>
            <div class="label-right">
                <p style="margin-bottom: 3px;"><b>DELIVER TO:</b></p>
                <div class="deliver-to" [ngClass]="{'deliver-to--extended' : !data.event.has_manifest_label_loc_id}">
                    <p [ngClass]="{'deliver-to-address--increase-font-size' : !data.event_has_manifest_label_loc_id}">{{ shippingDestination.ship_company }}</p>
                    <p [ngClass]="{'deliver-to-address--increase-font-size' : !data.event_has_manifest_label_loc_id}">{{ shippingDestination.address_1 }}</p>
                    <p *ngIf="shippingDestination.address_2" [ngClass]="{'deliver-to-address--increase-font-size' : !data.event_has_manifest_label_loc_id}">{{ shippingDestination.address_2 }}</p>
                    <p *ngIf="shippingDestination.address_3" [ngClass]="{'deliver-to-address--increase-font-size' : !data.event_has_manifest_label_loc_id}">{{ shippingDestination.address_3 }}</p>
                    <p [ngClass]="{'deliver-to-address--increase-font-size' : !data.event_has_manifest_label_loc_id}">{{ shippingDestination.city }}, {{ shippingDestination.ship_state }} {{shippingDestination.postcode}}</p>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</div>
