import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css']
})
export class InstructionsComponent implements OnInit {

  constructor(
  	private _location: Location
  ) { }

  ngOnInit() {
  }

  	print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        printContents = printContents.replace(/> block </gi, "> No <");
        let finalPrintContents = printContents.replace(/> done </gi, "> Yes <");
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              	.no-print {display: none;}
              	body {
              		font-family: sans-serif;
              	}
              	h1,h2,h3,h4,p,body,div,td,tr,th,li,span {
              		font-size: 13px !important;
              	}
              </style>
            </head>
            <body onload="window.print();window.close()">
            ${finalPrintContents}
            </body>
          </html>`
        );
        popupWin.document.close();
    }

}
