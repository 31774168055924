import { SupplierService } from '../../common/services/supplier.service';
import { Resolve } from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class SuppliersResolve { 

	constructor(
		public ss: SupplierService
	){}

	resolve(){
		return this.ss.getSuppliers({});
	}

}