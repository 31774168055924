import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from '../common/services/app.service';
import { log } from '../common/log/log';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
	public invitationForm;
    event;

  constructor(
  	public fb: FormBuilder,
    public router: Router,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private appService: AppService
  	) { }

  ngOnInit() {
  	this.invitationForm = this.fb.group({
  		code: new FormControl()
  	});
    this.route.data.subscribe((data: {
        event,
    }) => {
        log.Debug("data: ", data)
        this.event = data.event
    })
  }

  signup(){
    this._snackBar.dismiss();
    this.appService.navigate(['activate', this.invitationForm.value.code]);
  }

}
