<div class="container-fluid">
    <div class="row">
        <div class="col">
            <a mat-raised-button [routerLink]="['add']" color="accent"><mat-icon>add</mat-icon>Add User</a>
        </div>
    </div>
	<div class="row gutters">
        <mat-expansion-panel [expanded]="true" [hideToggle]="false" class="col margin-top">
            <mat-expansion-panel-header>
                <mat-panel-title>Filter Users</mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <form [formGroup]="filterForm">
                <div class="row gutters">
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Company</mat-label>
                            <input type="text" formControlName="company" matInput placeholder="Company" />
                        </mat-form-field>
                    </div>
                    <div class="col">
                    	<mat-form-field>
                    		<mat-label>Company Type</mat-label>
                    		<mat-select formControlName="company_type_id">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let c of companyTypes" [value]=c.id>{{c.name}}</mat-option>
                    		</mat-select>
                    	</mat-form-field>
                    </div>
                    <div class="col">
                    	<mat-form-field>
                    		<mat-label>Group</mat-label>
                    		<mat-select formControlName="group_id">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let g of groups" [value]=g.id>{{g.name}}</mat-option>
                    		</mat-select>
                    	</mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Department</mat-label>
                            <mat-select formControlName="department_id">
                                <mat-option>
                                    <ngx-mat-select-search 
                                        [formControl]="departmentFilterCtrl"
                                        [placeholderLabel]="'Find department...'" 
                                        [noEntriesFoundLabel]="'no matching department found'">
                                        <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let d of filteredDepartments" [value]=d.id>{{d.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row gutters">
                	<div class="col">
                		<mat-form-field>
                			<mat-label>Contact Name</mat-label>
                			<input type="text" formControlName="name" matInput placeholder="Contact Name" />
                		</mat-form-field>
                	</div>
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Role</mat-label>
                            <mat-select formControlName="role">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let r of roles" [value]="r.name">{{r.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                	<div class="col">
                        <mat-form-field>
                            <mat-label >Is Active</mat-label>
                            <mat-select formControlName="is_active">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option [value]=1>Yes</mat-option>
                                <mat-option [value]=0>No</mat-option>
                            </mat-select>
                        </mat-form-field>
                	</div>
                </div>
                <div class="row gutters">
                <div class="col">
                        <mat-form-field>
                            <mat-label >Is Primary</mat-label>
                            <mat-select formControlName="is_primary">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option [value]=1>Primary Contact</mat-option>
                                <mat-option [value]=0>Other</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                	<div class="col">
                        <mat-form-field>
                            <mat-label >Ship Only</mat-label>
                            <mat-select formControlName="ship_only">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option [value]=1>Yes</mat-option>
                                <mat-option [value]=0>No</mat-option>
                            </mat-select>
                        </mat-form-field>
                	</div>
                	<div class="col">
                        <mat-form-field>
                            <mat-label >Attendees Allowed</mat-label>
                            <mat-select formControlName="attendees_allowed">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option [value]=1>Yes</mat-option>
                                <mat-option [value]=0>No</mat-option>
                            </mat-select>
                        </mat-form-field>
                	</div>
                </div>
                <button mat-raised-button color="primary" (click)="reset()">Reset Filters</button>
                <button style="margin-left: 10px" mat-raised-button color="primary" (click)="ExportTOExcel()">Export as .xls</button>
            </form>
        </mat-expansion-panel>
    </div>
    <div id="print-section">
	<div class="row">
		<div class="col" #TABLE>
			<table mat-table #table [dataSource]="users" matSort class="table mat-elevation-z7 gutters sticky-header">
				  <!-- Position Column -->
				  <ng-container matColumnDef="first_name">
				    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
				    <td mat-cell data-label="First Name" *matCellDef="let element; let i = index;">
                        <inline-text-edit style="display:inline-block;cursor:pointer" [field]="element.first_name" (saved)="update($event, i, 'first_name')">
                        </inline-text-edit> &nbsp;
                        <inline-text-edit style="display:inline-block;cursor:pointer" [field]="element.last_name" (saved)="update($event, i, 'last_name')">
                        </inline-text-edit>
                    </td>
				  </ng-container>

                  <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
                    <td mat-cell data-label="User Name" *matCellDef="let element"> {{element.username}} </td>
                  </ng-container>

				  <!-- Weight Column -->
				  <ng-container matColumnDef="email">
				    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
				    <td mat-cell data-label="Email" *matCellDef="let element"> 
                        <inline-text-edit [field]="element.email" (saved)="update($event, i, 'email')">
                        </inline-text-edit>
                    </td>
				  </ng-container>

                  <ng-container matColumnDef="department">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                    <td mat-cell data-label="Department" *matCellDef="let element"> {{element.department}} </td>
                  </ng-container>

                  <ng-container matColumnDef="is_primary">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Contact </th>
                    <td mat-cell data-label="Primary Contact" *matCellDef="let element">
                        <mat-icon *ngIf="element.is_primary">
                            done
                        </mat-icon>
                        <mat-icon color="accent" *ngIf="!element.is_primary">
                            block
                        </mat-icon>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
                    <td mat-cell data-label="Primary Contact" *matCellDef="let element">
                        <mat-icon *ngIf="element.active">
                            done
                        </mat-icon>
                        <mat-icon color="accent" *ngIf="!element.active">
                            block
                        </mat-icon>
                    </td>
                  </ng-container>

				  <!-- Symbol Column -->
				  <ng-container matColumnDef="company">
				    <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
				    <td mat-cell data-label="Company" *matCellDef="let element"> {{element.company}} </td>
				  </ng-container>

                  <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
                    <td mat-cell data-label="Role" *matCellDef="let element"> {{element.role}} </td>
                  </ng-container>

                  <ng-container [style.display]="'none'" matColumnDef="billing_address">
                    <th [style.display]="'none'" mat-header-cell *matHeaderCellDef> Billing Address </th>
                    <td [style.display]="'none'" mat-cell data-label="Role" *matCellDef="let element"> {{ element.billing_address }} </td>
                  </ng-container>

                  <ng-container [style.display]="'none'" matColumnDef="billing_city">
                    <th [style.display]="'none'" mat-header-cell *matHeaderCellDef> City </th>
                    <td [style.display]="'none'" mat-cell data-label="Role" *matCellDef="let element"> {{ element.billing_city }} </td>
                  </ng-container>

                  <ng-container [style.display]="'none'" matColumnDef="billing_state">
                    <th [style.display]="'none'" mat-header-cell *matHeaderCellDef> State </th>
                    <td [style.display]="'none'" mat-cell data-label="Role" *matCellDef="let element"> {{ element.billing_state }} </td>
                  </ng-container>

                  <ng-container [style.display]="'none'" matColumnDef="billing_zip">
                    <th [style.display]="'none'" mat-header-cell *matHeaderCellDef> Zip </th>
                    <td [style.display]="'none'" mat-cell data-label="Role" *matCellDef="let element"> {{ element.billing_zip }} </td>
                  </ng-container>

                  <ng-container [style.display]="'none'" matColumnDef="billing_country">
                    <th [style.display]="'none'" mat-header-cell *matHeaderCellDef> Country </th>
                    <td [style.display]="'none'" mat-cell data-label="Role" *matCellDef="let element"> {{ element.billing_country }} </td>
                  </ng-container>

                  <ng-container [style.display]="'none'" matColumnDef="mailing_address">
                    <th [style.display]="'none'" mat-header-cell *matHeaderCellDef> Mailing Address </th>
                    <td [style.display]="'none'" mat-cell data-label="Role" *matCellDef="let element"> {{ element.mailing_address }} </td>
                  </ng-container>

                  <ng-container [style.display]="'none'" matColumnDef="mailing_city">
                    <th [style.display]="'none'" mat-header-cell *matHeaderCellDef> City </th>
                    <td [style.display]="'none'" mat-cell data-label="Role" *matCellDef="let element"> {{ element.mailing_city }} </td>
                  </ng-container>

                  <ng-container [style.display]="'none'" matColumnDef="mailing_state">
                    <th [style.display]="'none'" mat-header-cell *matHeaderCellDef> State </th>
                    <td [style.display]="'none'" mat-cell data-label="Role" *matCellDef="let element"> {{ element.mailing_state }} </td>
                  </ng-container>

                  <ng-container [style.display]="'none'" matColumnDef="mailing_zip">
                    <th [style.display]="'none'" mat-header-cell *matHeaderCellDef> Zip </th>
                    <td [style.display]="'none'" mat-cell data-label="Role" *matCellDef="let element"> {{ element.mailing_zip }} </td>
                  </ng-container>

                  <ng-container [style.display]="'none'" matColumnDef="mailing_country">
                    <th [style.display]="'none'" mat-header-cell *matHeaderCellDef> Country </th>
                    <td [style.display]="'none'" mat-cell data-label="Role" *matCellDef="let element"> {{ element.mailing_country }} </td>
                  </ng-container>

				  <ng-container matColumnDef="actions">
				  	<th mat-header-cell *matHeaderCellDef>Actions</th>
				  	<td mat-cell data-label="Actions" *matCellDef="let element">
				  		<a mat-icon-button *ngIf="hasPermission(element.role, authGroups.SUPER_ADMIN)" color="primary" [routerLink]="['edit', element.id]"><mat-icon>edit</mat-icon></a>
				  		<a mat-icon-button *ngIf="hasPermission(element.role, authGroups.SUPER_ADMIN)" color="accent" (click)="delete(element)"><mat-icon>delete</mat-icon></a>
				  	</td>
				  </ng-container>

				  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
				  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
            <div>
        </div>
		</div>
	</div>
    </div>
    <button style="margin-left: 10px;" type="button" mat-raised-button color="primary" (click)="print()">Print <mat-icon>print</mat-icon></button>
</div>