import { SettingsService } from '../../common/services/settings.service';
import { Resolve } from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class LoginTextResolve {

	constructor(
		public SettingsService: SettingsService
	){}

	resolve(){
		return this.SettingsService.ByName("LoginText");
	}

}
