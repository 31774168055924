import {Model} from './model';

export class Page extends Model {
	name: string = null;
	content: string = null;
	menu_id: number = null;
	slug: string = null;
	active: boolean = null;
	href: string = null;

	constructor(properties?: Object) {
		super(properties)
		this.patch(properties)
	}
}