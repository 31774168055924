import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShippingDestinationService } from '@common/services/shipping-destination.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ManifestService } from '@common/services/manifest.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShipmentComponent } from '../shipment.component';
import { ShippingDestination } from '../../../models/shipping-destination';
import { ShipmentService } from '@common/services/shipment.service';
import { CompanyService } from '@common/services/company.service';
import { AppService } from '../../../common/services/app.service';
import { log } from '../../../common/log/log';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {

  public newEntry = false;
  parentComponent;
  public destination;
  public form: FormGroup;
  minShipDate;
  maxShipDate;
  minArrivalDate;
  maxArrivalDate;
  manifestData;
  customLockDates;
  company;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public ShippingDestinationService: ShippingDestinationService,
    public FormBuilder: FormBuilder,
    public ManifestService: ManifestService,
    public MatSnackBar: MatSnackBar,
    private inj: Injector,
    private ShipmentService: ShipmentService,
    private CompanyService: CompanyService,
    private appService: AppService
  ) {
    this.parentComponent = this.inj.get(ShipmentComponent);
   }

  ngOnInit() {

    this.newEntry = this.parentComponent.isNew;

    if(!this.newEntry) {
      this.manifestData = this.route.snapshot.data.data.manifest;
    } else {
      this.manifestData = this.ShipmentService.currentManifest.Info;
    }

    this.form = this.FormBuilder.group(this.manifestData);
    // if(!this.newEntry) {
    //   this.form.get('ship_date').disable();
    //   this.form.get('arrive_date').disable();
    // } else {
    //   this.form.get('ship_date').enable();
    //   this.form.get('arrive_date').enable();
    // }
    this.ShippingDestinationService.get(this.manifestData.shipping_destination_id).subscribe(r => {
		this.destination = r;
      this.minShipDate = this.destination.inbound_lock;
      this.maxShipDate = this.destination.outbound_lock;
      this.minArrivalDate = this.destination.inbound_lock;
      this.maxArrivalDate = this.destination.outbound_lock;
    });
    this.CompanyService.get(this.manifestData.company_id).subscribe(r => {
      this.company = r;
    })
    this.ShippingDestinationService.getCustomLockDatesFor(this.manifestData.shipping_destination_id).subscribe( r => {
      this.customLockDates = r
      const index = this.customLockDates.findIndex( l => l.department_id == this.company.department_id)
      if( index != -1 ) {
        this.minShipDate = this.customLockDates[index].inbound_lock
        this.maxShipDate = this.customLockDates[index].outbound_lock
        this.minArrivalDate = this.customLockDates[index].inbound_lock
        this.maxArrivalDate = this.customLockDates[index].outbound_lock
      }

    })
  }

  updateArrival(e: any) {
    this.minArrivalDate = e.value;
    this.maxArrivalDate = this.maxShipDate;
  }

  next() {
    if(this.newEntry) {
      if(this.form.valid) {
        this.ShipmentService.currentManifest.Schedule = this.form.value
        log.Debug("debug schedule form value: ", this.form.value)
        this.appService.navigate(['/', 'app', 'shipping', 'shipment', 'new', 'manifest'])
      } else {
        this.MatSnackBar.open("Please fill out all required fields.")
      }
    } else {
      if(this.form.valid){

        this.ManifestService.save(this.form.value).subscribe(r => {
          this.MatSnackBar.open("Saved!", null, {duration: 3000});
          this.appService.navigate(['/', 'app', 'shipping', 'shipment', r['id'], 'manifest'])
        }, err => {
          this.MatSnackBar.open("Failed to save! Err: " + err, 'Ok');
        });

      }
    }

  }

}
