import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Address } from '../../../models/address';
import { AddressService } from '../../../common/services/address.service';
import { InviteService } from '../../../common/services/invite.service';
import { Company } from '../../../models/company';
import { CompanyService } from '../../../common/services/company.service';
import { DepartmentService } from '../../../common/services/department.service';
import { StateService } from '../../../common/services/state.service';
import { CountryService } from '../../../common/services/country.service';
import { ContractStatusService } from '../../../common/services/contract-status.service';
import { ShippingDestinationService } from '../../../common/services/shipping-destination.service';
import { CompanyTypeService } from '../../../common/services/company-type.service';
import { IFormGroup } from '../../common/generic';
import { COMPANY_FIELDS } from '../fields';
import { EMPTY, Observable, forkJoin, shareReplay, tap } from 'rxjs';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '@common/services/auth.service';
import { UserService } from '@common/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InvoiceTemplateService } from '@common/services/invoice-template.service';
import { InvoicesDialogComponent } from './invoices-dialog/invoices-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { log } from '../../../common/log/log';
import { Department } from '@models/department';

@Component({
    selector: 'app-edit-company',
    templateUrl: './edit-company.component.html',
    styleUrls: ['./edit-company.component.css'],
    host: {class: 'full-height no-head'}
})
export class EditCompanyComponent implements OnInit {

    form: FormGroup;
    mailing: FormGroup;
    billing: FormGroup;
    company;
    invites;
    invitesData;
    states;
    countries;
    statuses;
    departments;
    shippingDestinations;
    selectedShippingDestinations;
    shippingDepartments$: Observable<Department[]> = EMPTY
    companyTypes;
    sameAsBilling = false;
    billingCountry;
    mailingCountry;
    allowedDepartments = [];
    invoice_templates;
    testHtml;

    public displayedColumns = [
        'invite_sent',
        'to_email',
    ];

    @ViewChild(MatSort) sort: MatSort;


    get fieldList(): IFormGroup {
        return COMPANY_FIELDS;
    }

    @HostBinding('class') classes = 'full-height no-head';

    constructor(
        private _fb: FormBuilder,
        private cs: CompanyService,
        private cts: CompanyTypeService,
        private as: AddressService,
        private is: InviteService,
        private ss: StateService,
        private CountryService: CountryService,
        private router: Router,
        private _snack: MatSnackBar,
        private route: ActivatedRoute,
        private ContractStatusService: ContractStatusService,
        private DepartmentService: DepartmentService,
        private ShippingDestinationService: ShippingDestinationService,
        public location: Location,
        public AuthService: AuthService,
        public UserService: UserService,
        public InvoiceTemplateService: InvoiceTemplateService,
        public MatDialog: MatDialog
    ) { }

    ngOnInit() {

        this.shippingDepartments$ = this.DepartmentService.shipping().pipe(tap(r => console.log(r)), shareReplay(1))
        this.InvoiceTemplateService.all().subscribe(r =>{
            this.invoice_templates = r;
        });
        this.form = this._fb.group(new Company());
        this.mailing = this._fb.group(new Address());
        this.billing = this._fb.group(new Address());

        this.ss.getAll().subscribe(r => {
           this.states = r;
        });
        this.CountryService.getAll().subscribe(r => {
           this.countries = r;
        });
        this.ContractStatusService.all().subscribe(r => {
            this.statuses = r;
        });
        this.DepartmentService.all().subscribe(r => {
            this.departments = r;
        });
        this.cts.all().subscribe(r => {
            this.companyTypes = r;
        });

        this.route.params.subscribe(data => {
            forkJoin({
                states: this.ss.getAll(),
                countries: this.CountryService.getAll(),
                contractStatuses: this.ContractStatusService.all(),
                departments: this.DepartmentService.allActiveByLabelASC(),
                company: this.cs.get(data['id']),
                shippingDestinations: this.ShippingDestinationService.allActive(),
                chosenDestinations: this.cs.getChosenDestinations(data['id']),
                allowedDepartments: this.UserService.getDepartmentsByUser(this.AuthService.getUserID())
        }).subscribe((
                {states, countries, contractStatuses, departments, company, shippingDestinations, chosenDestinations, allowedDepartments}
            ) => {
                this.allowedDepartments = allowedDepartments;
                this.states = states;
                this.countries = countries;
                this.statuses = contractStatuses;
                this.departments = departments;
                this.company = company
                let chosen = chosenDestinations.map((cd) => cd['shipping_destination_id']);
                //select the chosen ones....
                this.shippingDestinations = shippingDestinations.map((item) => Object.assign({selected: chosen.indexOf(item['id']) !== -1 ? true : false}, item));
                this.form = this._fb.group(company);
                this.form.setControl('shipping_destinations',
                    this._fb.array(
                        this.shippingDestinations.map(
                            (r) => this._fb.group(r)
                        )
                    )
                );
                forkJoin([
                    this.as.get(company['billing_address_id']),
                    this.as.get(company['mailing_address_id']),
                    this.is.getByCompanyID(company['id'])
                ]).subscribe(([b, m, i]) => {

                    if(b)
                        this.billing = this._fb.group(b);
                    if(m)
                        this.mailing = this._fb.group(m);
                    if(i['length'] > 0){
                        this.invites = new MatTableDataSource(<any> i);
                        this.invites.sort = this.sort;
                    }else{
                        this.invites = false;
                    }
                    this.sameAsBilling = company['same_as_billing'];
                    this.billingCountry = this.billing.value.country_id;
                    this.mailingCountry = this.mailing.value.country_id;

                    this.billing.get('country_id').patchValue(this.billingCountry)
                    this.mailing.get('country_id').patchValue(this.mailingCountry)
                });
            })

        });

        if(this.notAdmin()) {
            this.form.get("department_id").disable();
        }

    }

    notAdmin(){
        return !this.AuthService.HasPermission(this.AuthService.groups.SUPER_ADMIN);
    }

    canEditFees(){
        return this.AuthService.HasPermission(this.AuthService.groups.SUPER_ADMIN) || this.allowedDepartments.map((d) => d.department_id).indexOf(this.form.value.department_id) > -1;
    }

    downloadXLS(){
        this.cs.invoiceXLS(this.form.value.id).subscribe(blob => {
            const a = document.createElement('a');
            a.setAttribute('download', this.form.value.invoice_number + ".xlsx");
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click();
        });
    }

    downloadPDF(){
        this.cs.invoicePDF(this.form.value.id).subscribe(blob => {
            const a = document.createElement('a');
            a.setAttribute('download', this.form.value.invoice_number + ".pdf");
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click();
        });
    }

    openInvoices(){
        this.MatDialog.open(InvoicesDialogComponent, {
            data: {
                company: this.form.value,
            }
        });
    }

    save() {
        log.Debug('test company form value')
        log.Debug(this.form.value)
        let company = Object.assign(new Company(), this.form.value);
        //filter out the selected ones...
        company.shipping_destinations = this.form.value.shipping_destinations.filter((r) => r.selected).map((r) => r.id);
        let billing = Object.assign(new Address(), this.billing.value);
        let mailing;
        if(this.sameAsBilling){
            //if mailing address is selected to be the same as billing
            mailing = Object.assign(new Address(), this.billing.value);
            mailing.id = this.mailing.value.id;
        }else{
            mailing = Object.assign(new Address(), this.mailing.value);
        }
        forkJoin([
            this.as.save(billing),
            this.as.save(mailing)
        ]).subscribe(([b, m]) => {
            company.billing_address_id = b["id"];
            company.mailing_address_id = m["id"];
            company.same_as_billing = this.sameAsBilling;
            log.Debug("debug company for save: ", company)
            this.cs.admin_save(company).subscribe(r => {
                this._snack.open('Company Saved!','',{duration: 2500});
                this.location.back();
            }, err => {
                log.Debug('START ERROR LOGS')
                log.Debug(err);
                this._snack.open(err, 'Ok', {duration:3000, verticalPosition:'top'});
            });
        });
    }
}
