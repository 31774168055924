import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { TransformService } from '@common/services/transform.service';
import { ShippingDestination } from '../../models/shipping-destination';
import { GenericObj } from '../../models/global';

@Injectable({
    providedIn: 'root'
})
export class ShippingDestinationService {

    constructor(
        public http: HttpClient,
        private _transform: TransformService
    ) { }

    all() {
        return this.http.get<ShippingDestination[]>(environment.api + "/shipping_destinations/all_by_event_id").pipe(
            map(this.resolveShippingDestList())
        );
    }

    allActive() {
        return this.http.get<ShippingDestination[]>(environment.api + "/shipping_destinations/all_active_by_event_id")
            .pipe(map(this.resolveShippingDestList()));
    }

    get(id) {
        return this.http.get(environment.api + "/shipping_destinations/by_id/" + id);
    }

    save(data) {
        return data.id ? this.update(data) : this.create(data);
    }

    update(data) {
        return this.http.put(environment.api + "/admin/shipping_destinations/update/" + data.id, data);
    }

    create(data) {
        return this.http.post(environment.api + "/shipping_destinations/create_with_event_id", data);
    }

    delete(id) {
        return this.http.delete(environment.api + "/admin/shipping_destinations/delete/" + id);
    }

    getCustomLockDatesFor(id) {
        return this.http.get(environment.api + "/admin/custom_lock_dates/by_shipping_destination_id/" + id);
    }

    addCustomLockDate(data) {
        return this.http.post(environment.api + "/custom_lock_dates/create_with_event_id", data);
    }

    deleteCustomLockDate(id) {
        return this.http.delete(environment.api + "/admin/custom_lock_dates/delete/" + id);
    }

    updateCustomLockDate(data) {
        return this.http.put(environment.api + "/admin/custom_lock_dates/update/" + data.id, data);
    }

    getForCompany(id) {
        return this.http.get(environment.api + "/shipping_destinations/by_company_id/" + id);
    }

    getShippingLabel(destination_id, company_id) {
        return this.http.get(environment.api + "/shipping_label/by_destination_id/" + destination_id + "/" + company_id);
    }

    resolveShippingDestList(): (r: GenericObj[]) => ShippingDestination[] {
        return this._transform.resolveList((c) => new ShippingDestination(c))

    }
    shippingBuilder(c: Partial<ShippingDestination>){
        return new ShippingDestination(c)
    }
}
