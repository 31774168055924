import { IFormGroup } from '../common/generic';
import { Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

export const USER_FIELD_LIST: IFormGroup = {
    firstName: {
        name: 'first_name',
        label: 'First Name'
    },
    lastName: {
        name: 'last_name',
        label: 'Last Name'
    },
    title: {
        name: "title",
        label: "Title"
    },
    phone: {
        name: "phone",
        label: "Phone"
    },
    ext: {
        name: "ext",
        label: "Extension"
    },
    email: {
        name: 'email',
        label: 'Email',
        validators: [Validators.required, Validators.email],
    },
    is_primary: {
        name: "is_primary",
        label: "Is Primary",
        type: "checkbox",
    },
    company_id: {
        name: 'company_id',
        label: 'Company',
        options: [],
        type: 'select',
        validators: [Validators.required]
    },
    department_id: {
        name: 'department_id',
        label: 'Department',
        options: [],
        type: 'select'
    },
    role_id: {
        name: 'role_id',
        label: "Role",
        type: "select",
        validators: [Validators.required]
    },
    active: {
        name: 'active',
        label: "Active",
        type: "select"
    },
    username: {
        name: 'username',
        label: 'User Name',
        validators: [Validators.required, Validators.minLength(3)]
    },
};

export const USER_PASSWORD_FIELD_LIST: IFormGroup = {
    password: {
        name: 'password',
        label: 'Password',
        validators: [Validators.minLength(5)]
    },
    confirmPassword: {
        name: 'confirmPassword',
        label: 'Confirm Password',
        validators: [Validators.minLength(5)]
    },
};

@Injectable({ providedIn: 'root'})
export class MatchPassword implements Validators {
    validate(formGroup: any) {
       const { password, confirmPassword } = formGroup.value
       return password === confirmPassword ? null : { passwordsDontMatch: true}
    }
}