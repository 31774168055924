<div PglFileDrop 
  (onFileDrop)="onDrop.emit($event)"
  class="drop-zone gutters">
    <div class="text-wrapper">
        <div class="text-center message">
            <ng-container *ngIf="pool.length == 0; else poolList">
                <p>
                    Drag and drop your file
                </p>
                <p>or</p>
                <p>
                    click to find it in your computer
                </p>
            </ng-container>
        </div>
        <ng-template #poolList>
            <div *ngFor="let item of pool">
                {{ item.name}}
                <!-- <button mat-raised-button (click)="upload(item)" *ngIf="!uploaded">Upload</button> -->
                <!-- <button mat-icon-button *ngIf="uploaded">
                    <mat-icon>check</mat-icon>
                </button> -->
                <mat-progress-bar *ngIf="isLoading" mode="determinate" [value]="progress$ | async"></mat-progress-bar>
            </div>
        </ng-template>
    </div>
</div>