export const USERS = [
		{
			id: 1,
			first_name: "Jesse",
			last_name: "Smith",
			phone: '555-555-5555',
			title: 'Developer',
			email:"jesse@eyesoreinc.com",
			company: "Eyesore Inc",
			company_id: 1,
			is_primary: false,
			is_active: true,
		},
		{
			id: 2,
			first_name: "Garrett",
			last_name: "Massey",
			phone: '555-555-5555',
			title: 'Owner',
			email:"garrett@eyesoreinc.com",
			company: "Eyesore Inc",
			company_id: 1,
			is_primary: true,
			is_active: true,
		},
		{
			id: 3,
			first_name: "Trey",
			last_name: "Jones",
			phone: '555-555-5555',
			title: 'Developer',
			email:"trey@eyesoreinc.com",
			company: "Eyesore Inc",
			company_id: 1,
			is_primary: false,
			is_active: false,
		},
	];