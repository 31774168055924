export function LocalStorage(key?: string, defaultValue?: any) {

    return (protoType: any, propName: string, des?: any) => {
        let itemName = key || propName;
        let storeValue: any;
        const storage: Storage = localStorage || {
            key: ()=> itemName,
            length: 1,
            clear: () => {},
            removeItem: (key: string) => {},
            setItem: (_, v) => storeValue = v,
            getItem: (key) => storeValue
        }

        Object.defineProperty(protoType, propName, {
            set: function(v: any){

                storage.setItem(itemName, JSON.stringify(v))
            },
            get: function() {
                let value: any;
                try{
                    value = JSON.parse(storage.getItem(itemName))
                }catch(e){
                    console.error(`failed to parse ${itemName}: `, e);
                    storage.removeItem(itemName)
                    value = null
                }
                return value == undefined ? defaultValue : value;
            }
        })
    }
}
