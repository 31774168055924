<div class="container-fluid">
    <form [formGroup]="form" (ngSubmit)="save()" autocomplete="off">
        <div class="row gutters" *ngFor="let column of inputFields">
            <div class="col" *ngFor="let field of column">
                <mat-form-field>
                    <mat-label>{{ field.label || field.name }}</mat-label>
                        <input  matInput [formControlName]="field.name" data-lpignore="true" readonly onfocus="this.removeAttribute('readonly');"/>
                </mat-form-field>
            </div>
        </div>
        <ng-container formGroupName="passwordGroup">
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <input matInput data-lpignore="true" name="password" placeholder="Password" formControlName="password" type="password" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <input matInput data-lpignore="true" name="confirm_password" placeholder="Confirm Password" formControlName="confirmPassword" type="password" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');">
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Company</mat-label>
                    <mat-select formControlName="company_id">
                        <mat-option>
                            <ngx-mat-select-search 
                                [formControl]="companyFilterCtrl"
                                [placeholderLabel]="'Find company...'" 
                                [noEntriesFoundLabel]="'no matching company found'">
                                <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let c of filteredCompanies" [value]=c.id>
                            {{c.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <!-- NATODO fix?, update-->
        <!-- <div class="row">
            <div style="pointer-events: none;" class="col">
                <mat-form-field>
                    <mat-label>Department</mat-label>
                    <input matInput formControlName="department_id" readonly value="{{ departmentName }}"/>
                </mat-form-field>
            </div>
        </div> -->
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="role_id">
                        <mat-option *ngFor="let role of roles" [value]=role.id [disabled]="!hasPermission(role.name, authGroups.SUPER_ADMIN)">
                            {{role.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-checkbox [ngModel]="false" formControlName="is_primary">Primary Contact</mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-checkbox [ngModel]="true" formControlName="active">Active</mat-checkbox>
            </div>
        </div>
        <button mat-raised-button color="primary" type="submit">Submit</button>
        <a style="margin-left: 5px;" color="primary" mat-raised-button (click)="this.location.back();">Cancel</a>
    </form>
</div>