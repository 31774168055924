import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root'
})

export class NotificationService {

	constructor(
		private MatSnackBar: MatSnackBar,
	) { }

	LargeNotify( message: string ) {
		this.MatSnackBar.open(message, "Ok" , {duration: 3000, verticalPosition: "top", panelClass: "largeSnack"});
	}

	FormInvalid( message: string ) {
		this.MatSnackBar.open(message, null , {duration: 3000});
	}

	Error( message: string ) {
		this.MatSnackBar.open(message, "Ok" , {verticalPosition: "top"});
	}
}
