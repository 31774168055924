import { Department } from './../../../models/department';
import { Component, OnInit, HostBinding } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Address } from '../../../models/address';
import { AddressService } from '../../../common/services/address.service';
import { Company } from '../../../models/company';
import { CompanyService } from '../../../common/services/company.service';
import { CompanyTypeService } from '../../../common/services/company-type.service';
import { CountryService } from '../../../common/services/country.service';
import { DepartmentService } from '../../../common/services/department.service';
import { StateService } from '../../../common/services/state.service';
import { log } from '@common/log/log';
import { EMPTY, Observable, forkJoin } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractStatusService } from '../../../common/services/contract-status.service';
import { ShippingDestinationService } from '../../../common/services/shipping-destination.service';
import { Location } from '@angular/common';
import { InvoiceTemplateService } from '../../../common/services/invoice-template.service';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AppService } from '../../../common/services/app.service';
// import { Department } from '@common/';


@Component({
    selector: 'app-add-company',
    templateUrl: './add-company.component.html',
    styleUrls: ['./add-company.component.css']
})
export class AddCompanyComponent implements OnInit {
    private _errorprefix = 'AddCompanyComponent';
    form: FormGroup;
    mailing: FormGroup;
    billing: FormGroup;
    nextInvoiceNumber;
    states;
    companies;
    departments;
    countries;
    statuses;
    shippingDestinations;
    shippingDepartments$: Observable<Department[]> = EMPTY
    sameAsBilling = false;
    billingCountry;
    mailingCountry;
    checked = true;
    active = true;
    invoice_templates;
    event;
    yearLastTwo;

    @HostBinding('class') classes = 'full-height no-head';

    constructor(
        private _fb: FormBuilder,
        private cs: CompanyService,
        private cts: CompanyTypeService,
        private as: AddressService,
        private ss: StateService,
        private router: Router,
        private DepartmentService: DepartmentService,
        private CountryService: CountryService,
        private _snack: MatSnackBar,
        private ContractStatusService: ContractStatusService,
        private ShippingDestinationService: ShippingDestinationService,
        public location: Location,
        public InvoiceTemplateService: InvoiceTemplateService,
        private route: ActivatedRoute,
        private appService: AppService,
    ) { }

    ngOnInit() {
        this.shippingDepartments$ = this.DepartmentService.shipping().pipe(tap(r => console.log(r)), shareReplay(1))
        this.form = this._fb.group(new Company());
        this.mailing = this._fb.group(new Address());
        this.billing = this._fb.group(new Address());

        this.InvoiceTemplateService.all().subscribe(r => {
            this.invoice_templates = r;
        });

        this.CountryService.getAll().subscribe(r => {
            this.countries = r;
        });

        this.cts.all().subscribe(r => {
            this.companies = r;
        });

        this.route.data.subscribe((data: {
            event
        }) => {
            this.event = data.event
            this.yearLastTwo = data.event.label.slice(-2)
            log.Debug("data: ", data)
        })

        forkJoin({
            states: this.ss.getAll(),
            contractStatuses: this.ContractStatusService.all(),
            countries: this.CountryService.getAll(),
            departments: this.DepartmentService.allActiveByLabelASC(),
            shippingDestinations: this.ShippingDestinationService.allActive()
        }).subscribe((
            {states, contractStatuses, countries, departments, shippingDestinations}
        ) => {
            this.states = states;
            this.statuses = contractStatuses;
            this.countries = countries;
            this.departments = departments;
            this.shippingDestinations = shippingDestinations.map((item) => Object.assign({ selected: false }, item));
            this.form = this._fb.group(new Company());
            let sdControl = this._fb.array(
                this.shippingDestinations.map(
                    (r) => this._fb.group(r)
                )
            );
            this.form.setControl('shipping_destinations', sdControl);
            // this.form.setControl('shipping_departments', sdControl)
            this.form.controls.active.setValue(true);
        })

    }

    save() {

        if (this.form.invalid) {
            this._snack.open('Please fill out all required fields!', null, { duration: 3000 });
            return;
        }
        let company = Object.assign(new Company(), this.form.value);
        log.Debug(this.form.value);
        let billing = Object.assign(new Address(), this.billing.value);
        let mailing;
        company.shipping_destinations = this.form.value.shipping_destinations.filter((r) => r.selected).map((r) => r.id);

        if (this.sameAsBilling) {
            //if mailing address is selected to be the same as billing
            mailing = Object.assign(new Address(), this.billing.value);
            mailing.id = this.mailing.value.id;
        } else {
            mailing = Object.assign(new Address(), this.mailing.value);
        }

        forkJoin([
            this.as.save(billing),
            this.as.save(mailing),
            this.cs.nextInvoiceNumber(),
            this.DepartmentService.get(company.department_id)
        ]).pipe(
            tap(val => log.Debug(`${this._errorprefix}: `, val))
        ).subscribe(([b, m, i, d]: [{ id: number }, { id: number }, number, { label: string }]) => {
            company.billing_address_id = b.id;
            company.mailing_address_id = m.id;
            company.first_time = true;
            company.same_as_billing = this.sameAsBilling;
            company.invoice_number = `PW${this.yearLastTwo}.${d.label}.${i}`;
            log.Debug(company);
            this.cs.admin_save(company).subscribe(r => {
                this._snack.open('Company Saved!', null, { duration: 3000 });
                this.appService.navigate(['/', 'app', 'admin', 'companies']);
            }, err => {
                this._snack.open(err.error, 'Ok', { duration: 3000, verticalPosition: 'top', panelClass: 'snackError' });
            });
        });
    }
}
