import { Department } from './department';
import { Model } from './model';

export class User extends Model {
    company_id: number | null;
    role_id: number | null;
    phone: string | null;
    title: string | null;
    ext: string | null;
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    password: string | null;
    is_primary: boolean | null;
    created: string | null;
    modified: string | null;
    active: boolean | null;
    username: string | null;
    departments: Department[] | null;

    constructor(properties?: Object) {
        super(properties)
        this.patch(properties)
    }

    static EmptyGroup() {
        return new User({})
    }

}