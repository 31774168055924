<div class="container-fluid">
    <div class="row gutters">
        <div class="col">
            <form [formGroup]="form" (ngSubmit)="save()" autocomplete="off">
        		<div class="row gutters" *ngFor="let column of inputFields">
        			<div class="col" *ngFor="let field of column">
        				<mat-form-field>
        					<mat-label>{{ field.label || field.name }}</mat-label>
        					<input  matInput [formControlName]="field.name" data-lpignore="true" readonly onfocus="this.removeAttribute('readonly');"/>
        				</mat-form-field>
        			</div>
        		</div>
        		<div class="row">
        			<div class="col">
        				<mat-form-field>
        					<mat-label>Company</mat-label>
        					<mat-select required formControlName="company_id"  [disabled]="!AuthService.HasPermission(authGroups.SUPER_ADMIN)">
        						<mat-option *ngFor="let c of companies" [value]=c.id>
        							{{c.name}}
        						</mat-option>
        					</mat-select>
        				</mat-form-field>
        			</div>
        		</div>
        		<div class="row">
        			<div style="pointer-events: none;" class="col">
        				<mat-form-field>
        					<mat-label>Department</mat-label>
        					<input matInput formControlName="department_id" readonly value="{{ departmentName }}"/>
        				</mat-form-field>
        			</div>
        		</div>
        		<div class="row" >
        			<div class="col">
        				<mat-form-field>
        					<mat-label>Role</mat-label>
        					<mat-select required formControlName="role_id" >
        						<mat-option *ngFor="let role of roles" [value]=role.id>
        							{{role.name}}
        						</mat-option>
        					</mat-select>
        				</mat-form-field>
        			</div>
        		</div>
        		<div class="row">
        			<div class="col">
        				<mat-checkbox formControlName="is_primary">Primary Contact</mat-checkbox>
        			</div>
				</div>
				<div class="row" *ngIf="departments && AuthService.HasPermission(authGroups.SUPER_ADMIN) && isRole(['Admin', 'Supplier Coordinator'])" id="chosenDepartments">
					<div class="col" formArrayName="chosenDepartments">
						<h4>Departments</h4>
						<div class="row" >
							<div class="col department" *ngFor="let d of departments; let i = index" [formGroupName]="i">
								<mat-checkbox formControlName="selected" [value]="d.id">{{d.label}}</mat-checkbox>
							</div>
						</div>
					</div>
				</div>
                <button [disabled]="form.invalid" mat-raised-button color="primary" type="submit">Submit</button>
                <a style="margin-left: 5px;" color="primary" mat-raised-button (click)="this.location.back();">Cancel</a>
            </form>
        </div>
        <div class="col">
            <form [formGroup]="passwordForm" (ngSubmit)="changePassword()" autocomplete="off">
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <input required matInput data-lpignore="true" name="password" placeholder="Password" formControlName="password" type="password" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <input required matInput data-lpignore="true" name="confirm_password" placeholder="Confirm Password" formControlName="confirmPassword" type="password" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');">
                        </mat-form-field>
                    </div>
                </div>
                <button [disabled]="passwordForm.invalid" mat-raised-button color="primary" type="submit">Change Password</button>
                <button style="margin-left: 10px;" type="button" mat-raised-button (click)="sendResetPasswordLink()">Send Reset Password Link</button>
            </form>
        </div>
    </div>
</div>