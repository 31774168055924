<button style="margin-bottom:5px;" type="button" mat-raised-button color="accent" (click)="print()">Print <mat-icon>print</mat-icon></button>
<button style="margin-bottom:5px;margin-left:5px;" type="button" mat-raised-button color="accent" (click)="DownloadPDF()">Download PDF <mat-icon>picture_as_pdf</mat-icon></button>
<div id="print-section" #content>
  <img style="width:400px;float:left;" src="{{ data.event.horizontal_logo }}" />
  <img id='hsi-label' style="width:200px;float:right;" src="/assets/img/hartmann-ita-group-logo-2025.png" />
  <div style="clear:both;"></div>
  <div>
    <p style="font-size:22px;"><b>{{ shippingDestination.name }} Shipping Manifest</b></p>
    <div>
      <div class="supplier-information" style="width:50%;float:left;">
        <p style="font-size:16px;"><b>Supplier Information:</b></p>
        <div>
          <p style="vertical-align:top;width:33%;text-align:left;display:inline-block">Company:</p>
          <p style="width:65%;margin-left:1%;display:inline-block">{{ shippingDestination.company_name }}</p>
        </div>
        <div>
          <p style="vertical-align:top;width:33%;text-align:left;display:inline-block">Manifest Number:</p>
          <p style="width:65%;margin-left:1%;display:inline-block">{{ data.dataKey.manifest_number }}</p>
        </div>
        <div>
          <p style="vertical-align:top;width:33%;text-align:left;display:inline-block">Shipment Contact:</p>
          <p style="width:65%;margin-left:1%;display:inline-block">{{ data.dataKey.shipping_contact_name }}</p>
        </div>
        <div>
          <p style="vertical-align:top;width:33%;text-align:left;display:inline-block">Email:</p>
          <p style="width:65%;margin-left:1%;display:inline-block">{{ data.dataKey.shipping_contact_email }}</p>
        </div>
        <div>
          <p style="vertical-align:top;width:33%;text-align:left;display:inline-block">Mobile Phone:</p>
          <p style="width:65%;margin-left:1%;display:inline-block">{{ data.dataKey.shipping_contact_phone }}</p>
        </div>
        <br>
        <div>
          <p style="vertical-align:top;width:33%;text-align:left;display:inline-block">Delivery Address:</p>
          <div class="delivery-address" style="width:65%;margin-left:1%;display:inline-block">
            <p>{{ shippingDestination.name }}</p>
            <p>{{ shippingDestination.ship_company }}</p>
            <p>{{ shippingDestination.address_1 }}</p>
            <p *ngIf="shippingDestination.address_2">{{ shippingDestination.address_2 }}</p>
            <p *ngIf="shippingDestination.address_3">{{ shippingDestination.address_3 }}</p>
            <p>{{ shippingDestination.city }}, {{ shippingDestination.ship_state }} {{shippingDestination.postcode}}</p>
          </div>
        </div>
        <br>
        <div>
          <p style="vertical-align:top;width:33%;text-align:left;display:inline-block">Department(s):</p>
          <p style="width:65%;margin-left:1%;display:inline-block">{{ departmentInfo.name }}</p>
        </div>

      </div>
      <div style="width:45%;float:right;">
        <div>
          <p style="vertical-align:top;width:20%;text-align:left;display:inline-block">Notes:</p>
          <p style="width:79%;height:200px;margin-left:1%;display:inline-block">{{ data.dataKey.notes }}</p>
        </div>
        <div style="font-size:12px;">
          <p style="vertical-align:top;width:33%;text-align:left;display:inline-block">Origination Address:</p>
          <div class="delivery-address" style="width:65%;margin-left:1%;display:inline-block">
            <span *ngIf="data.dataKey.origination_address == null">
              <p>{{ companyMailingAddress.address_1 }}</p>
              <p *ngIf="companyMailingAddress.address_2">{{ companyMailingAddress.address_2 }}</p>
              <p *ngIf="companyMailingAddress.address_3">{{ companyMailingAddress.address_3 }}</p>
              <p>{{ companyMailingAddress.city }}, {{ companyMailingAddress.state }} {{ companyMailingAddress.postal_code }}</p>
            </span>
            <p *ngIf="data.dataKey.origination_address != null">{{ data.dataKey.origination_address }}</p>

          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
    <br>
    <hr style="border:1px solid #999;">
    <br>
    <div class="bottom-description" style="width:100%">
      <table style="font-size:11px;">
        <tr class="package-size">
          <th>Item Qty</th>
          <th>Package Type</th>
          <th>Pkg Width(Inches)</th>
          <th>Pkg Height(Inches)</th>
          <th>Pkg Depth(Inches)</th>
          <th>Weight per Package (Pounds)</th>
        </tr>
        <tr *ngFor="let manifestpackage of manifestpackages" style="text-align:center;">
          <td>{{ manifestpackage.quantity }}</td>
          <td>{{ manifestpackage.type }}</td>
          <td>{{ manifestpackage.width }}</td>
          <td>{{ manifestpackage.height }}</td>
          <td>{{ manifestpackage.depth }}</td>
          <td>{{ manifestpackage.weight }}</td>
        </tr>
      </table>
      <br>
      <p>Shipment Description:</p>
      <table style="font-size:10px;width:100%;table-layout:fixed;">
        <tr>
          <th width="45px">Line Item</th>
          <th width="45px">Item Qty</th>
          <th width="100px">Description</th>
          <th width="45px">Display Type</th>
          <th width="45px">SKU</th>
          <th width="40px" *ngIf="data.event.has_disposition_columns">Discard</th>
          <th width="50px" *ngIf="data.event.has_disposition_columns">Return to Supplier</th>
          <th width="50px" *ngIf="data.event.has_disposition_columns">Return Ship Method</th>
          <th width="40px" *ngIf="data.event.has_disposition_columns && data.event.has_donate_columns">Donate</th>
          <th width="70px" *ngIf="data.event.has_disposition_columns">Special Arrangments</th>
          <th width="50px" *ngIf="data.event.has_disposition_columns && data.event.has_donate_columns">Est. Value (USD)</th>
          <th width="150px">{{ data.event.return_address_column_name }}</th>
        </tr>
        <tr *ngFor="let manifestLineItem of manifestLineItems" style="text-align:center;">
          <td>{{ manifestLineItem.item }}</td>
          <td>{{ manifestLineItem.qty }}</td>
          <td>{{ manifestLineItem.description }}</td>
          <td>{{ manifestLineItem.type }}</td>
          <td>{{ manifestLineItem.sku }}</td>
          <td *ngIf="data.event.has_disposition_columns">
            <span *ngIf="manifestLineItem.is_discard"> X </span>
          </td>
          <td *ngIf="data.event.has_disposition_columns">
            <span *ngIf="manifestLineItem.is_return"> X </span>
          </td>
          <td *ngIf="data.event.has_disposition_columns">
            <span *ngIf="manifestLineItem.return_method"> {{ manifestLineItem.return_method }} </span>
          </td>
          <td *ngIf="data.event.has_disposition_columns && data.event.has_donate_columns">
            <span *ngIf="manifestLineItem.is_donate"> X </span>
          </td>
          <td *ngIf="data.event.has_disposition_columns"></td>
          <td *ngIf="data.event.has_disposition_columns && data.event.has_donate_columns">
            <span *ngIf="manifestLineItem.donated_value"> {{ manifestLineItem.donated_value }} </span>
          </td>
          <td class="description-address">
            {{ manifestLineItem.return_address }}
          </td>
        </tr>
      </table>

    </div>

    <div class="clear"></div>
    <p style="font-size: 12px;color:red;">You <b>must</b> include a copy of this manifest with your shipment, visible on the outside of the package. Please keep a copy for your records.</p>
  </div>
</div>
