import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { InfoComponent } from './info/info.component';
import { ContentComponent } from './content/content.component';
import { ShippingComponent } from './shipping/shipping.component';
import { RegistrationComponent } from './registration/registration.component';
import { PersonalComponent } from './personal/personal.component';
import { CompanyComponent } from './company/company.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoicesComponent as AdminInvoicesComponent} from './admin/invoices/invoices.component';
import { AdminComponent } from './admin/admin.component';
import { USER_ROUTES } from './admin/users/index';
import { MANAGE_REGISTRATION_ROUTES } from './admin/manage-registration/index';
import { COMPANY_ROUTES } from './admin/companies/index';
import { ANALYTICS_ROUTES } from './admin/analytics/index';
import { PAYMENTS_ROUTES } from './admin/payments/index';
import { DEPARTMENT_ROUTES } from './admin/departments/index';
import { INVOICE_TEMPLATE_ROUTES } from './admin/invoice-templates/index';
import { GROUP_ROUTES } from './admin/groups/index';
import { SHIPPING_DESTINATION_ROUTES } from './admin/shipping-destinations/index';
import { SUPPLIER_ROUTES } from './admin/suppliers/index';
import { InvitesComponent } from './admin/invites/invites.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { ActivateComponent } from './activate/activate.component';
import { SHIPPING_ROUTES, SHIPPING_COMPONENTS } from './shipping';
import { PAGES_ROUTES, PAGES_COMPONENTS } from './admin/pages';
import { MENU_ROUTES, MENU_COMPONENTS} from './admin/menus';
import { RegisterComponent } from './registration/register/register.component';
import { EditRegistrationComponent } from './registration/edit-registration/edit-registration.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { AuthGuardService as AuthGuard} from './common/services/auth-guard.service';
import { ActivateResolve } from './activate/activate.resolve';
import { ResetPasswordComponent } from './admin/users/reset-password/reset-password.component';
import { ResetPasswordResolve } from './admin/users/reset-password/reset-password.resolve';
import { ForgotPasswordComponent } from './admin/users/forgot-password/forgot-password.component';
import { ContractComponent } from './contract/contract.component';
import { FILE_MANAGEMENT_ROUTES } from './admin/file-managment/';
import { PageComponent } from './page/page.component';
import { PAYMENT_ROUTES } from './invoices/index';
import { ReportsComponent } from './admin/reports/reports.component';
import { ImportsComponent } from './admin/imports/imports.component';
import { OutboundDispositionComponent } from './admin/outbound-disposition/outbound-disposition.component';
import { UnderConstructionComponent } from './admin/under-construction/under-construction.component';
import { UnderConstructionGuardService as UnderConstructionGuard } from './common/services/under-construction-guard.service';
import { LogoResolve } from './admin/settings/logo.resolve';
import { AppNameResolve } from './admin/settings/app-name.resolve';
import { AppReplyToResolve } from './admin/settings/app-reply-to.resolve';
import { LoginTextResolve } from './admin/settings/login-text.resolve';
import { RegistrationTextResolve } from './admin/settings/registration-text.resolve';
import { EventResolve } from './event';
import { RolesResolve } from './role';
import { CompanyTypesResolve, UserCompanyTypeResolve } from './company_type';
import { ArchiveGuardService as ArchiveGuard } from './common/services/archive.guard';


const dash: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'info/Home/overview'
    },
    {
        path:"page/:slug",
        component: PageComponent,
        canActivate: [UnderConstructionGuard]
    },
    {
        path:'info',
        pathMatch: 'full',
        redirectTo: 'info/Home/overview'
    },
    {
        path: 'info/:menu',
        component: InfoComponent,
        canActivate: [UnderConstructionGuard],
        resolve: {
            event: EventResolve,
        },
        children: [
            {
                path: ':slug',
                component: ContentComponent,
                resolve: {
                    event: EventResolve,
                }
            }
        ]
    },
    ...SHIPPING_ROUTES,
    ...PAYMENT_ROUTES,
    {
        path: 'registration',
        canActivate: [UnderConstructionGuard],
        children: [
            {
                path: '',
                children: [
                    {
                        path: '',
                        component: RegistrationComponent,
                        resolve: {
                            // registrationText: RegistrationTextResolve,
                            event: EventResolve,
                        }
                    }
                ]
            },
            {
                path: 'edit/:registration_id',
                component: EditRegistrationComponent,
                resolve: {
                    event: EventResolve,
                }
            },
            {
                path: 'add',
                component: RegisterComponent,
                resolve: {
                    event: EventResolve,
                }
            }
        ]
    },
    {
        path: 'company',
        component: CompanyComponent,
        canActivate: [UnderConstructionGuard],
        resolve: {
            event: EventResolve,
        }
    },
    {
        path: 'personal',
        component: PersonalComponent,
        canActivate: [UnderConstructionGuard],
        resolve: {
            event: EventResolve,
        }
    },
    {
        path: 'invoices',
        component: InvoicesComponent,
        resolve: {
        	event: EventResolve,
        },
        canActivate: [UnderConstructionGuard]
    },
    {
        path: 'contract',
        component: ContractComponent,
        canActivate: [UnderConstructionGuard],
        resolve: {
            event: EventResolve,
        }
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [UnderConstructionGuard, AuthGuard],
        children: [
            {
                path:'',
                redirectTo: 'companies',
                pathMatch:'full',
            },
            ...USER_ROUTES,
            ...COMPANY_ROUTES,
            ...DEPARTMENT_ROUTES,
            ...INVOICE_TEMPLATE_ROUTES,
            ...MANAGE_REGISTRATION_ROUTES,
            ...GROUP_ROUTES,
            ...SHIPPING_DESTINATION_ROUTES,
            ...SUPPLIER_ROUTES,
            ...FILE_MANAGEMENT_ROUTES,
            ...PAYMENTS_ROUTES,
            ...ANALYTICS_ROUTES,
            {
                path: 'invites',
                component: InvitesComponent,
            },
            {
                path: 'settings',
                component: SettingsComponent,
                resolve: {
                    event: EventResolve,
                }
            },
            {
                path: 'pages',
                children: PAGES_ROUTES
            },
            {
                path: 'menus',
                children: MENU_ROUTES
            },
            {
                path: 'invoices',
                component: AdminInvoicesComponent
            },
            {
                path: 'reports',
                component: ReportsComponent
            },
            {
                path: 'imports',
                component: ImportsComponent
            },
            {
                path: 'outbound-disposition',
                component: OutboundDispositionComponent
            },
        ]
    }
];

const mainChildren: Routes = [
			{
        		path: '',
        		redirectTo: 'app',
        		pathMatch: 'full'
    		},
    		{
        		path: 'under-construction',
        		component: UnderConstructionComponent,
                resolve: {
		            event: EventResolve
		        }
    		},
		    {
		        path: 'app',
		        canActivate: [UnderConstructionGuard, AuthGuard],
		        children: dash,
		        component: AppComponent,
		        resolve: {
		            event: EventResolve,
		            companyType: UserCompanyTypeResolve,
		        }
		    },
		    {
		        path: 'login',
		        component: LoginComponent,
		        canActivate: [UnderConstructionGuard],
		        resolve: {
		            event: EventResolve,
		        }
		    },
		    {
		        path:'signup',
		        component: SignupComponent,
		        canActivate: [UnderConstructionGuard],
		        resolve: {
		            event: EventResolve,
		        }
		    },
		    {
		        path:'activate/:code',
		        component: ActivateComponent,
		        canActivate: [UnderConstructionGuard],
		        resolve: {
		            company: ActivateResolve,
		            event: EventResolve,
		            roles: RolesResolve,
		            companyTypes: CompanyTypesResolve,
		        }
		    },
		    {
		        path: 'reset-password/:hash',
		        component: ResetPasswordComponent,
		        canActivate: [UnderConstructionGuard],
		        resolve: {
		            user: ResetPasswordResolve,
		            event: EventResolve,
		        },
		    },
		    {
		        path: 'forgot-password',
		        component: ForgotPasswordComponent,
		        canActivate: [UnderConstructionGuard],
		        resolve: {
		            event: EventResolve,
		        }
		    }
    	]

const routes: Routes = [
	{
        path: '',
        children: [
        	{
        	path: '',
        	children: mainChildren
    	}]
    },
	{
    	path: ":year",
    	children: mainChildren,
    	canActivate: [ArchiveGuard]
    },
    

    
    
]

export const ROUTE_COMPONENTS = [
    InfoComponent,
    ContentComponent,
    PersonalComponent,
    CompanyComponent,
    ShippingComponent,
    InvoicesComponent,
    RegistrationComponent,
    AdminComponent,
    RegisterComponent,
    AdminInvoicesComponent,
    ...SHIPPING_COMPONENTS,
    ...PAGES_COMPONENTS,
    ...MENU_COMPONENTS
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
