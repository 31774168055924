import { ManageRegistrationComponent } from './manage-registration.component';
import { AddRegistrationComponent } from './add-registration/add-registration.component';


export const MANAGE_REGISTRATION_ROUTES = [
    {
        path: 'registrations',
        component: ManageRegistrationComponent
    },
    {
        path: 'registrations/add',
        component: AddRegistrationComponent
    },
];

export const MANAGE_REGISTRATION_COMPONENTS = [
    ManageRegistrationComponent,
    AddRegistrationComponent
];
