declare var Accept: any;
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { PaymentsService } from '../../common/services/payments.service'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../common/services/app.service';
import { log } from '../../common/log/log';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})

export class PaymentComponent implements OnInit {
  paymentForm: FormGroup;
  invoiceAmount;
  clientKey;
  apiLoginID;
  event;

  constructor(
    private FormBuilder: FormBuilder,
    private PaymentsService: PaymentsService,
    private MatSnackBar: MatSnackBar,
    private Router: Router,
    private route: ActivatedRoute,
    private appService: AppService
  ) { }

  ngOnInit() {
  	this.route.data.subscribe((data: {
		event;
	}) => {
		log.Debug("data: ", data)
		this.event = data.event
	})

    dispatchEvent(new Event('load'));
    this.PaymentsService.paymentInfo().subscribe(r => {
      this.clientKey = r['publicClientKey'];
      this.apiLoginID = r['loginId'];
    });

    this.PaymentsService.myInvoice().subscribe(r => {
      this.invoiceAmount = r;
      this.paymentForm = this.FormBuilder.group({
        card_number: '',
        card_exp: '',
        card_code: '',
        amount: this.invoiceAmount.total.toString()
      });

    });


  }

  GetCardType(number)
  {
      // visa
      var re = new RegExp("^4");
      if (number.match(re) != null)
          return "Visa";
  
      // Mastercard 
      // Updated for Mastercard 2017 BINs expansion
       if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) 
          return "Mastercard";
  
      // AMEX
      re = new RegExp("^3[47]");
      if (number.match(re) != null)
          return "AMEX";
  
      // Discover
      re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
      if (number.match(re) != null)
          return "Discover";
  
      // Diners
      re = new RegExp("^36");
      if (number.match(re) != null)
          return "Diners";
  
      // Diners - Carte Blanche
      re = new RegExp("^30[0-5]");
      if (number.match(re) != null)
          return "Diners - Carte Blanche";
  
      // JCB
      re = new RegExp("^35(2[89]|[3-8][0-9])");
      if (number.match(re) != null)
          return "JCB";
  
      // Visa Electron
      re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
      if (number.match(re) != null)
          return "Visa Electron";
  
      return "";
  }


  sendPayment() {
    let values = this.paymentForm.value;
    values['credit_card_type'] = this.GetCardType(values.card_number);
    var data = {
        authData: {
          clientKey: this.clientKey,
          apiLoginID: this.apiLoginID,
        },
        cardData:{
          cardNumber: values['card_number'],
          month: values['card_exp'].split('/')[0],
          year: values['card_exp'].split('/')[1],
          cardCode: values['card_code']
        }
    };
    let self = this;
    Accept.dispatchData(data, function(data){
      if(data.messages.resultCode == 'Ok'){
        self.PaymentsService.payMyInvoice(data.opaqueData).subscribe(r => {
          if(r['transactionResponse']['responseCode'] == '1' || r['transactionResponse']['ResponseCode'] == '1'){
            self.MatSnackBar.open("Payment Successful!", null, {verticalPosition:"top", duration: 6000, panelClass: ['yellow-snackbar', 'largeSnack']});
            self.appService.navigate(['/', 'app', 'invoices']);
          }
          else{
            self.MatSnackBar.open("Failed Payment: " + r['transactionResponse']['errors'][0]['errorText'],  null, {verticalPosition:"top", duration: 6000, panelClass: ['yellow-snackbar', 'largeSnack']});
          }
        }, err => {
          self.MatSnackBar.open("Failed to save, please contact an administrator: " + err,  null, {verticalPosition:"top", duration: 6000, panelClass: ['yellow-snackbar', 'largeSnack']});
        });
      }
      else{
        self.MatSnackBar.open("Failed Payment: " + data['messages']['message'][0]['text'],  null, {verticalPosition:"top", duration: 6000, panelClass: ['yellow-snackbar', 'largeSnack']});
      }
    });



    // this.PaymentsService.payInvoice(this.paymentForm.value).subscribe(r => {
    //   if(r['success']){
    //     this.MatSnackBar.open("Thank you for your payment", null, {verticalPosition:"top", duration: 6000, panelClass: ['yellow-snackbar', 'largeSnack']});
    //     this.appService.navigate(['/', 'app', 'invoices']);
    //   }
    //   else{
    //     this.MatSnackBar.open(r['message'], null, {verticalPosition:"top", duration: 6000});
    //   }
    // }, r => {
    //   this.MatSnackBar.open(r['message'], null, {verticalPosition:"top", duration: 6000});
    // });
  }

  responseHandler(data){
    //example response 
    // {
    //   "opaqueData":{
    //     "dataDescriptor":"COMMON.ACCEPT.INAPP.PAYMENT",
    //     "dataValue":"9487801666614876704604"
    //   },
    //   "messages":{
    //     "resultCode":"Ok",
    //     "message":[
    //       {
    //         "code":"I_WC_01",
    //         "text":"Successful."
    //       }
    //     ]
    //   }
    // }

  }

}
