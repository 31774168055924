<div class="container-fluid">
	<div class="row">
		<div class="col" #TABLE>
			<table mat-table #table [dataSource]="analyticsAll" matSort class="table mat-elevation-z7 gutters sticky-header">

                <ng-container matColumnDef="item">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                <td mat-cell data-label="Item" *matCellDef="let element"> {{element.item}} </td>
                </ng-container>

                <ng-container matColumnDef="count">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Count </th>
                <td mat-cell *matCellDef="let element"> {{element.count}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
		</div>
	</div>
</div>
