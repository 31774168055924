import { Injectable } from '@angular/core';
import { GenericObj } from '../../models/global';

type RT<T extends GenericObj | GenericObj[]> = T extends GenericObj[] ? Array<T> : T

@Injectable({ providedIn: 'root' })
export class TransformService {
    constructor() { }

    resolve<T extends GenericObj>(r: Partial<T>, build: (c: Partial<T>) => T): T {
        if (!r) throw new Error("Unexpected object resolve response");
        if ('error' in r) throw r.error;
        return build(r);
    }
    resolveList<T extends GenericObj>(build: (c: Partial<T>) => T) {
        return (r: Partial<T>[]) => {
            if (!r) throw new Error("Unexpected list resolve response");
            if ('error' in r) throw r.error;
            return r.map((c: Partial<T>) => this.resolve(c, build));
        }
    }

}
