import { Component, OnInit, ViewChild, HostBinding } from '@angular/core';
import { GroupService } from '../../common/services/group.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// NATODO remove?, update
// import * as io from 'socket.io-client';

@Component({
	selector: 'app-groups',
	templateUrl: './groups.component.html',
	styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

	dataSource;
	public displayedColumns = ['name', 'active', 'actions'];
  @HostBinding('class') classes = 'full-height no-head';
	@ViewChild(MatSort) sort: MatSort;

	constructor(
		private gs: GroupService,
		private sb: MatSnackBar
	) { }

	ngOnInit() {

		this.gs.all().subscribe(r => {
			this.dataSource = new MatTableDataSource(<any> r);
			this.dataSource.sort = this.sort;
		});

	}

	delete(id){
		if(confirm("Are you sure that you want to delete group #" + id + "?")){
			this.gs.delete(id).subscribe(r => {
				this.sb.open("Deleted!", null, {duration: 3000});
				this.ngOnInit();
			});
		}
	}

	print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        printContents = printContents.replace(/> block </gi, "> No <");
        let finalPrintContents = printContents.replace(/> done </gi, "> Yes <");
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              table {font-family: Lato, sans-serif;}
              h3 {font-family: Lato, sans-serif;}
              button {font-weight: bold;font-size: 12px;background: none;border: none;color: #888;font-family: Lato, sans-serif;}
              th {font-weight: bold;font-size: 12px;color: #888;}
              th:last-of-type {display: none;}
              td {font-size: 12px;color: #222;padding: 5px;text-align: center;border-bottom: 1px solid #ccc;}
              td:last-of-type {display: none;}
              div.col {text-align: center;}
              table {display: inline-block;}
              </style>
            </head>
            <body onload="window.print();window.close()">${finalPrintContents}</body>
          </html>`
        );
        popupWin.document.close();
    }
}




