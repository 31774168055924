import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../common/services/auth.service';
import { CompanyService } from '../../common/services/company.service';
import { ManifestService } from '../../common/services/manifest.service';
import { FileServerService } from '@common/services/file-server.service';
import { PageService } from '@common/services/page.service';
import { log } from '@common/log/log';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-download-manifest',
	templateUrl: './download-manifest.component.html',
	styleUrls: ['./download-manifest.component.css']
})
export class DownloadManifestComponent implements OnInit {

	public manifests
	public company
	public downloadingXLS = [];
	public page;
	event;


	public displayedColumns = [
		'manifest_number',
		'shipping_contact_name',
		'shipping_contact_email',
		'shipping_contact_phone',
		'brand',
		'ship_date',
		'arrive_date',
		'actions'
	];

	constructor(
		public AuthService: AuthService,
		public CompanyService: CompanyService,
		public ManifestService: ManifestService,
		public FileServerService: FileServerService,
		private PageService: PageService,
		public route: ActivatedRoute,
	) { }

	ngOnInit() {

		this.route.data.subscribe((data: {
			event,
		}) => {
			log.Debug("data: ", data)
			this.event = data.event
		})

		const user = this.AuthService.getUser()
		this.ManifestService.getAllByCompanyID(user.company_id).subscribe(r => {
			this.manifests = r
		});
		this.PageService.getBySlug('download-manifest').subscribe( r => {
			this.page = r
		})
	}

	// downloadEmpty(manifest){
	// 	this.downloadingXLS[manifest.manifest_number] = true;
	// 	this.ManifestService.blankXLS(manifest.id).subscribe( blob => {
	// 		const a = document.createElement('a');
	// 		a.setAttribute("download", "ShippingManifest2020" + manifest.manifest_number + ".xlsx");
	// 		a.setAttribute("href", window.URL.createObjectURL(blob));
	// 		a.click();
	// 		this.downloadingXLS[manifest.manifest_number] = false;
	// 	})
	// }

	downloadPrefilled(manifest){
		this.downloadingXLS[manifest.manifest_number] = true;
		this.ManifestService.prefilledXLS(manifest.id).subscribe( blob => {
			const a = document.createElement('a');
			a.setAttribute("download", "ShippingManifest" + this.event.label + manifest.manifest_number + "_" + manifest.id +".xlsx");
			a.setAttribute("href", window.URL.createObjectURL(blob));
			a.click();
			this.downloadingXLS[manifest.manifest_number] = false;
		})
	}

	downloadPrevious(manifest) {
		this.downloadingXLS[manifest.manifest_number] = true;
		this.FileServerService.GetByFilePath("manifests/ShippingManifest"+this.event.label+manifest.manifest_number+"_"+manifest.id+".xlsx").subscribe( blob => {
			const a = document.createElement('a');
			a.setAttribute("download", "ShippingManifest" + this.event.label + manifest.manifest_number+"_"+manifest.id+".xlsx");
			a.setAttribute("href", window.URL.createObjectURL(blob));
			a.click();
			this.downloadingXLS[manifest.manifest_number] = false;
		})
	}

	isAdmin(){
		return this.AuthService.getRole() == 'admin'
	}

}
