import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ParamMap, ActivatedRouteSnapshot } from '@angular/router';
import { EventService } from './event.service';
import { map, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})

export class ArchiveGuardService implements CanActivate {

  constructor(
  	private appService: AppService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
  	this.appService.archiveYear = route.paramMap.get('year');

    return of(true)
  }
}
