import { Department } from './../../models/department';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { filter, map, mergeMap, toArray } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DepartmentService {

	constructor(
		public http: HttpClient
	) { }

	all(){
		return this.http.get(environment.api + "/departments/all_by_event_id");
	}

	shipping() {
		return this.http.get<Department[]>(environment.api + '/departments/shipping_by_event').pipe(
            mergeMap(r => from(r)),
            map(d => new Department(d)),
            // filter(d => d.shipping_only),
            toArray()
        );
	}

	allByLabelASC(){
		return this.http.get<Department[]>(environment.api + "/departments/all_by_label_asc");
	}

	allActiveByLabelASC(){
		return this.http.get(environment.api + "/departments/all_active_by_label_asc");
	}

	statuses(){
		return this.http.get(environment.api + "/admin/departments/statuses");
	}

	// includes shipping_only depts - others do not
	filter(filters) {
		return this.http.get(environment.api + '/admin/departments/all', {params: filters});
	}

	get(id) {
		return this.http.get(environment.api + "/departments/by_id/" + id);
	}

	save(data){
		return data.id ? this.update(data) : this.create(data);
	}

	update(data){
		return this.http.put(environment.api + "/admin/departments/update/" + data.id, data);
	}

	create(data){
		return this.http.post(environment.api + "/departments/create_with_event_id", data);
	}

	delete(id){
		return this.http.delete(environment.api + "/admin/departments/delete/" + id);
	}
}
