<div class="full-width" class="no-print">
  <button mat-raised-button color="accent" [disabled]="['supplier', 'supplier_primary_contact', 'supplier_ship_only'].indexOf(currentRole()) !== -1" class="extended gutters" [routerLink]="['../shipment', 'new']"><mat-icon>add</mat-icon>New Shipment</button>
</div>

<form *ngIf="currentRole().includes('admin') || currentRole().includes('vendor') || currentRole().includes('coordinator')" [formGroup]="filterForm" class="no-print">
  <mat-expansion-panel [expanded]="true" [hideToggle]="false" class="col">
      <mat-expansion-panel-header>
          <mat-panel-title>Search</mat-panel-title>
          <mat-panel-description></mat-panel-description>
      </mat-expansion-panel-header>
      <div class="row gutters">
        <div class="col">
          <mat-form-field>
            <mat-label>Company</mat-label>
            <input type="text" formControlName="company" matInput placeholder="Company" />
        </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
              <mat-label>Department</mat-label>
              <mat-select formControlName="department">
                  <mat-option [value]=null>All</mat-option>
                  <mat-option *ngFor="let d of departments" [value]=d.id>{{d.name}}</mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-label>Manifest Number</mat-label>
            <input type="text" formControlName="manifestNumber" matInput placeholder="Manifest Number" />
        </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-label>Shipping Destination</mat-label>
            <mat-select formControlName="destination">
                <mat-option [value]=null>All</mat-option>
                <mat-option *ngFor="let sd of shippingDestinations" [value]=sd.id>{{sd.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row gutters">
        <div class="col">
          <mat-form-field>
            <mat-label>Shipping Method</mat-label>
              <mat-select formControlName="shippingMethod">
                  <mat-option value="">All</mat-option>
                  <mat-option value="Show Carrier (TL)">Show Carrier (TL)</mat-option>
                  <mat-option value="Other Carrier">Other Carrier</mat-option>
                  <mat-option value="UPS">UPS</mat-option>
                  <mat-option value="FedEx">FedEx</mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-label>Display Set Type</mat-label>
              <mat-select formControlName="displaySetType">
                  <mat-option value="">All</mat-option>
                  <mat-option value="Standard In-Bay">Standard In-Bay</mat-option>
                  <mat-option value="Product Demo">Product Demo</mat-option>
                  <mat-option value="Outdoor">Outdoor</mat-option>
                  <mat-option value="Show Display">Show Display</mat-option>
                  <mat-option value="homedepot.com">homedepot.com</mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-label>Ship Only</mat-label>
              <mat-select formControlName="shipOnly">
                  <mat-option [value]=null>All</mat-option>
                  <mat-option [value]=1>Yes</mat-option>
                  <mat-option [value]=0>No</mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-label>Disposition</mat-label>
              <mat-select formControlName="disposition">
                  <mat-option [value]=null>All</mat-option>
                  <mat-option value="is_return">Return</mat-option>
                  <mat-option value="is_discard">Discard</mat-option>
                  <mat-option value="is_donate">Donate</mat-option>
              </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row gutters">
        <div class="col">
          <mat-form-field>
            <mat-label>Shipment Status</mat-label>
            <mat-select formControlName="shipmentStatus">
                <mat-option [value]=null>All</mat-option>
                <mat-option value="Received/Completed">Received/Completed</mat-option>
                <mat-option value="Not Received">Not Received</mat-option>
                <mat-option value="Received With Exceptions">Received With Exceptions</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-label>Customs Shipment</mat-label>
            <mat-select formControlName="isCustomsShipment">
                <mat-option [value]=null>All</mat-option>
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=0>No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <input matInput [max]="filterForm.get('uploadedMax').value" formControlName="uploadedMin" [matDatepicker]="uploadedMin" placeholder="Choose an uploaded date start">
    				<mat-datepicker-toggle matSuffix [for]="uploadedMin"></mat-datepicker-toggle>
    				<mat-datepicker #uploadedMin></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <input matInput [min]="filterForm.get('uploadedMin').value" formControlName="uploadedMax" [matDatepicker]="uploadedMax" placeholder="Choose an uploaded date end">
    				<mat-datepicker-toggle matSuffix [for]="uploadedMax"></mat-datepicker-toggle>
    				<mat-datepicker #uploadedMax></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div  *ngIf="downloading" style="width:200px">
          <mat-progress-bar mode="buffer"></mat-progress-bar>
          Downloading Manifests
      </div>
      <button mat-raised-button color="primary" (click)="reset()">Reset Filters</button>
      <button style="margin-left: 10px;" (click)="bulkDownloadManifests()" mat-raised-button color="accent">Download Manifests<mat-icon>picture_as_pdf</mat-icon></button>
  </mat-expansion-panel>
</form>
<div class="col" #TABLE>
  <table mat-table [dataSource]="shipments" matSort class="table mat-elevation-z7 gutters sticky-header">

   <ng-container matColumnDef="manifest_number">
     <th mat-header-cell *matHeaderCellDef mat-sort-header> Manifest Number </th>
     <td mat-cell data-label="Manifest Number" *matCellDef="let element">
      <a [routerLink]="['../manifest-info', element.id]">{{element.manifest_number}}</a>
     </td>
   </ng-container>

   <ng-container matColumnDef="company_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name </th>
    <td mat-cell data-label="Company Name" *matCellDef="let element"> {{element.company_name}} </td>
   </ng-container>

   <ng-container matColumnDef="department_name">
    <th mat-header-cell *matHeaderCellDef>Funding Department </th>
    <td mat-cell data-label="Department Name" *matCellDef="let element"> {{element.department_name}} </td>
   </ng-container>

   <ng-container matColumnDef="shipping_destination">
    <th mat-header-cell *matHeaderCellDef> Shipping Destination </th>
    <td mat-cell data-label="Shipping Destination" *matCellDef="let element"> {{ getShippingDestinationName(element.shipping_destination_id) }} </td>
   </ng-container>
   <ng-container matColumnDef="shipping_department">
    <th mat-header-cell *matHeaderCellDef> Shipping Department </th>
    <td mat-cell data-label="Shipping Department" *matCellDef="let element"> {{ element.shipping_department_name }} </td>
   </ng-container>

   <ng-container matColumnDef="shipment_method">
    <th mat-header-cell *matHeaderCellDef> Shipment Method </th>
    <td mat-cell data-label="Shipment Method" *matCellDef="let element"> {{element.shipment_method}} </td>
   </ng-container>

   <ng-container matColumnDef="shipping_contact_name">
      <th mat-header-cell *matHeaderCellDef> Contact Name </th>
      <td mat-cell data-label="Contact Name" *matCellDef="let element"> {{element.shipping_contact_name}} </td>
    </ng-container>

   <ng-container matColumnDef="shipping_contact_email">
     <th mat-header-cell *matHeaderCellDef> Contact Email </th>
     <td mat-cell data-label="Contact Email" *matCellDef="let element"> {{element.shipping_contact_email}} </td>
   </ng-container>

   <ng-container matColumnDef="shipping_contact_phone">
      <th mat-header-cell *matHeaderCellDef> Contact Phone </th>
      <td mat-cell data-label="Contact Phone" *matCellDef="let element"> {{element.shipping_contact_phone}} </td>
    </ng-container>

    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef> Brand(s) </th>
      <td mat-cell data-label="Brand" *matCellDef="let element"> {{element.brand}} </td>
    </ng-container>

    <ng-container matColumnDef="ship_date">
      <th mat-header-cell *matHeaderCellDef> Ship Date </th>
      <td mat-cell data-label="Ship Date" *matCellDef="let element"> {{element.ship_date | date}} </td>
    </ng-container>

    <ng-container matColumnDef="arrive_date">
      <th mat-header-cell *matHeaderCellDef> Arrival Date </th>
      <td mat-cell data-label="Arrival Date" *matCellDef="let element"> {{element.arrive_date | date}} </td>
    </ng-container>

    <ng-container matColumnDef="shipment_status">
      <th mat-header-cell *matHeaderCellDef> Shipment Status </th>
      <td mat-cell data-label="Shipment Status" *matCellDef="let element">
        <span *ngIf="!currentRole().includes('admin') && !currentRole().includes('vendor') && !currentRole().includes('supplier_coordinator')">
          <span *ngIf="element.shipment_status == 'Not Received'">{{ element.shipment_status }}</span>
          <span style="color:#008800" *ngIf="element.shipment_status == 'Received/Completed'">{{ element.shipment_status }}</span>
          <span style="color:#9b9b12" *ngIf="element.shipment_status == 'Received with Exceptions'">{{ element.shipment_status }}</span>
        </span>
          <mat-select *ngIf="currentRole().includes('admin') || currentRole().includes('vendor') || currentRole().includes('supplier_coordinator')"
           class="update-shipment-status"
           [ngClass]="selectPlaceholderClass(element.shipment_status)"
           placeholder="{{ element.shipment_status }}"
           (selectionChange)="updateShipmentStatus($event, element.id)">
           <mat-option value="Not Received">Not Received</mat-option>
           <mat-option style="color:#008800" value="Received/Completed">Received/Completed</mat-option>
           <mat-option style="color:#9b9b12" value="Received with Exceptions">Received with Exceptions</mat-option>
          </mat-select>
      </td>
    </ng-container>

    <ng-container matColumnDef="exceptions_note">
      <th mat-header-cell *matHeaderCellDef> Exceptions Note </th>
      <td mat-cell data-label="Exceptions Note" *matCellDef="let element">
          <textarea [disabled]="!isAuthorizedExceptionsNote()" #exceptionsNoteContent
            [placeholder]="isAuthorizedExceptionsNote() ? 'Enter exceptions note...' : ''"
            rows="4"
            style="padding:2px;border-radius:2px;resize:none;"
            (click)="exceptionsNoteFocused[element.id] = true">{{ element.exceptions_note }}</textarea>
        <button mat-raised-button color="accent"
         *ngIf="exceptionsNoteFocused[element.id] && isAuthorizedExceptionsNote()"
         (click)="updateExceptionsNote(exceptionsNoteContent.value, element.id)">Save</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="tracking_info">
      <th mat-header-cell *matHeaderCellDef> Tracking Info </th>
      <td mat-cell data-label="Tracking Info" *matCellDef="let element">
          <textarea [disabled]="!isAuthorizedTrackingInfo()" #trackingInfoContent
            [placeholder]="isAuthorizedTrackingInfo() ? 'Enter tracking info...' : ''"
            rows="4"
            style="padding:2px;border-radius:2px;resize:none;"
            (click)="trackingInfoFocused[element.id] = true">{{ element.tracking_info }}</textarea>
        <button mat-raised-button color="accent"
         *ngIf="trackingInfoFocused[element.id] && isAuthorizedTrackingInfo()"
         (click)="updateTrackingInfo(trackingInfoContent.value, element.id)">Save</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="items">
      <th mat-header-cell *matHeaderCellDef> Items </th>
      <td mat-cell data-label="Arrival Date" *matCellDef="let element"> <span *ngFor="let item of element.line_items" style="display:block;">{{ item.description }}</span></td>
    </ng-container>

    <ng-container matColumnDef="donate_sum">
      <th mat-header-cell *matHeaderCellDef> Donate Sum </th>
      <td mat-cell data-label="Arrival Date" *matCellDef="let element">{{ getDonateSum(element.line_items) | currency}}</td>
    </ng-container>

    <ng-container matColumnDef="uploaded">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Uploaded </th>
      <td mat-cell data-label="Arrival Date" *matCellDef="let element">{{ element.uploaded | date}}</td>
    </ng-container>

   <ng-container matColumnDef="actions">
     <th mat-header-cell *matHeaderCellDef class="action-column"></th>
     <td mat-cell data-label="Actions" *matCellDef="let element">
       <!-- <button [disabled]="['supplier', 'supplier_primary_contact', 'supplier_ship_only'].indexOf(currentRole()) !== -1" style="margin-bottom: 2px;" color="accent" [routerLink]="['../shipment/', element.id, 'manifest']" mat-raised-button >Edit Shipment</button> -->
       <button style="margin-bottom: 2px;" color="accent" [routerLink]="['../shipment/', element.id, 'contact-info']" mat-raised-button >Edit Shipment</button>
       <!-- <button [disabled]="['supplier', 'supplier_primary_contact', 'supplier_ship_only'].indexOf(currentRole()) !== -1" style="margin-bottom: 2px;" (click)="delete(element)" mat-raised-button color="accent">Delete Shipment</button> -->
       <button style="margin-bottom: 2px;" (click)="delete(element)" mat-raised-button color="accent">Delete Shipment</button>
       <button [disabled]="element.uploaded == null" (click)="openPrintLabel(element, event)" mat-raised-button color="accent" style="margin-bottom: 2px;">Print Label</button>
       <button [disabled]="element.uploaded == null" (click)="openManifestLabel(element, event)" mat-raised-button color="accent">Print Manifest</button>
     </td>
   </ng-container>

   <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
 </table>
 <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
</div>
