<div>
    <div class="flex row baseline">
        <span class="display-step" *ngIf="newEntry">Step 3 of 3</span>
        <div class="flex-item text-center">
            <button mat-raised-button color="accent" class="extended gutters" (click)="downloadEmpty()" [disabled]="manifest.uploaded != null">Download Blank Manifest</button>
        </div>
        <div class="flex-item">
            <div class="flex column center">
                <div class="flex-item">
                    <button mat-raised-button color="accent" class="extended gutters" (click)="downloadPrevious()" [disabled]="manifest.uploaded == null">Download Previous Manifest</button>
                </div>
                <div class="flex-item">
                    <mat-hint *ngIf="manifest.uploaded != null">Uploaded: {{ manifest.uploaded | date: 'yyyy-MM-dd': 'UTC' }}</mat-hint>
                </div>
            </div>
        </div>
    </div>

    <div>
        <ol>
            <li>
                Populate the form with the desired details:
            </li>
            <li>
                All fields must be completed to be accepted by {{ event.pretty_url }}
            </li>
            <li>
                Do not use decimal points, dollar signs or fractions.
            </li>
            <li>
                If your item doesn't have a SKU number (fixture, prototype, etc) enter 000000
            </li>
        </ol>
        <p>
            Suggested format: ShippingManifestForm{{ event.label }}_DXX.ABC_QQQQ (DXX is your department number, ABC is the name
            of your company; QQQQ is the manifest number listed in the top portion of the form)
        </p>
    </div>
</div>

<div>
    <div class="drop-zone gutters" (click)="fileInput.click()" appDragDrop (onFileDropped)="dropUpload($event)">
        <div class="text-wrapper">
            <div class="text-center message">
                <ng-container *ngIf="pool.length == 0; else poolList">
                    <p>
                        Drag and drop your file
                    </p>
                    <p>or</p>
                    <p>
                        click to find it in your computer
                    </p>
                </ng-container>
            </div>
            <ng-template #poolList>
                <div *ngFor="let item of pool">
                    {{ item.name }}<br>Uploaded Successfully!
                </div>
            </ng-template>
        </div>
        <input style="display:none;" type="file" #fileInput (change)="clickUpload($event)" />
    </div>

</div>
<div class="item right">
    <button mat-raised-button color="accent" class="gutters no-bottom" (click)="backToShipments($event)">Back to Shipments</button>
</div>
