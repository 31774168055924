import { IModel, Model } from './model';

export class Department extends Model{
    group_id: number = null;
    coordinator_id: number = null;
    invoice_template_id: number = null;
    name: string = null;
    label: string = null;
    active: boolean = null;
    shipping_only: boolean = null;
	abbreviation: string = null;

    constructor(properties?: Partial<Department>) {
        super(properties)
        this.patch(properties)
    }
}
