<div class="container-fluid">
	<form [formGroup]="invoice" (submit)="save()">
		<div class="row">
			<div class="col">
				<mat-form-field>
					<input name="name" placeholder="Name" matInput formControlName="name" />
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<div class="upload" *ngIf="!invoice.value.file">
					<pgl-file-drop (onDrop)="upload($event)" (click)="file.click()"></pgl-file-drop>
					<input type="file" #file style="display:none" (change)="upload($event)" />
				</div>
				<mat-form-field *ngIf="invoice.value.file">
					<input name="name" placeholder="File" matInput formControlName="file" readonly />
				</mat-form-field>
				<button mat-raised-button *ngIf="invoice.value.file" (click)="reset()">Change File</button>
			</div>
		</div>
		<button *ngIf="invoice.value.file" type="submit" mat-raised-button color="primary" >Submit</button>
		<a style="margin-left: 5px;" color="primary" mat-raised-button (click)="this.location.back();">Cancel</a>
	</form>
</div>