import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../../common/services/registration.service';
import { Registration } from '../../models/registration';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { log } from '../../common/log/log';

@Component({
  selector: 'app-edit-registration',
  templateUrl: './edit-registration.component.html',
  styleUrls: ['./edit-registration.component.css']
})
export class EditRegistrationComponent implements OnInit {

  public form: FormGroup;
  event;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private Router: Router,
    public rs: RegistrationService,
    private MatSnackBar: MatSnackBar,
    private _location: Location
  ) { }

  ngOnInit() {

      this.route.data.subscribe((data: {
          event,
      }) => {
          this.event = data.event
      })

    let reg = new Registration();
    this.form = this.fb.group(reg);
    this.route.params.subscribe(data => {
      this.rs.get(data['registration_id']).subscribe(r => {
        this.form = this.fb.group(r);
        log.Debug('tester')
        log.Debug(data)
        log.Debug(this.form)
      });
    });

    log.Debug("form: ", this.form)

  }

  save() {

     //registration disabled
     if(this.event.is_registration_closed) {
         alert('Registrations are disabled, please contact your supplier coordinator for changes.')
         return;
     }

    if(this.form.invalid){
      this.MatSnackBar.open('Please fill out all required fields!', null , {duration: 3000});
      return;
    }

    this.rs.save(this.form.value).subscribe(r => {
      this.MatSnackBar.open("Registration updated successfully", 'Ok', {verticalPosition:'top'});
      this._location.back();
    }, err => {
      this.MatSnackBar.open("There was an error updating this registration: " + err, 'Ok', {verticalPosition: "top", panelClass: "largeSnack"});
    });
  }

}
