import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { log } from '@common/log/log';
import { of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {

	constructor(
		public http: HttpClient,
	) { }

	create(data){
		return this.http.post(environment.api + '/analytics/create', data);
	}

	getAll(){
		return this.http.get(environment.api + '/analytics/all');
	}

}
