<div class="container-fluid">
	<div class="row">
		<div class="col">
			<button mat-raised-button color="accent" class="extended gutters" [routerLink]="['add']"><mat-icon>add</mat-icon>Add Page</button>
		</div>
	</div>
	<div class="row filters">
		<div class="col">
			<mat-expansion-panel [hideToggle]="false">
			<mat-expansion-panel-header>
			<mat-panel-title>Filter Pages</mat-panel-title>
			<mat-panel-description></mat-panel-description>
			</mat-expansion-panel-header>
			<form [formGroup]="filterForm">
				<div class="row gutters">
					<div class="col">
						<mat-form-field>
							<input matInput formControlName="name" placeholder="Name"/>
						</mat-form-field>
					</div>
					<div class="col">
						<mat-form-field>
							<mat-label>Menu</mat-label>
							<mat-select formControlName="menu_id">
								<mat-option [value]=0>All</mat-option>
								<mat-option *ngFor="let m of menus" [value]=m.id>
									{{m.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</form>
			</mat-expansion-panel>
		</div>
	</div>
	<div id="print-section">
        <div class="row">
            <div class="col">
                <div class="drag-drop">
                    <div style="position:sticky;top:-12px;z-index:100;" class="drag-drop-list-header">
                        <div class="row gutters">
                            <div class="col"><span>Title</span></div>
                            <div class="col"><span>Menu</span></div>
                            <div class="col"><span>Slug</span></div>
							<div class="col"><span>Dept</span></div>
                            <div class="col" style="text-align: center;"><span>Actions</span></div>
                        </div>
                    </div>
                    <div cdkDropList (cdkDropListDropped)="onDrop($event)" class="drag-drop-list">
                        <div *ngFor="let page of filteredPages" cdkDrag class="drag-drop-list-box row gutters" [ngStyle]="{'background-color': page.active? 'white' : 'rgba(0,0,0,0.05)'}">
                            <div class="col"><span>{{ page.name }}</span></div>
                            <div class="col"><span>{{ page.menu }}</span></div>
                            <div class="col"><span>{{ page.slug }}</span></div>
							<div class="col"><span>{{ page.dept }}</span></div>
                            <div class="col" style="text-align: center;">
                            	<a mat-icon-button (click)="editContent(page.id)" color="primary"><mat-icon>fullscreen</mat-icon></a>
                                <a mat-icon-button [routerLink]="['edit', page.id]" color="primary"><mat-icon>edit</mat-icon></a>
                                <a mat-icon-button color="accent" (click)="delete(page)"><mat-icon>delete_forever</mat-icon></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
    <button style="margin-left: 10px;" type="button" mat-raised-button color="primary" (click)="saveOrder()">Save Order<mat-icon>save</mat-icon></button>
    <button style="margin-left: 10px;" type="button" mat-raised-button color="primary" (click)="print()">Print <mat-icon>print</mat-icon></button>
</div>
