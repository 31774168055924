<mat-toolbar color="accent">
    <div class="maxcontainer">
        <a class="space" [routerLink]="['/', 'login']"><img style="max-height: 60px;" src="{{ event.logo }}" /></a>
    </div>
</mat-toolbar>
<div class="container full-height-no-sidebar">
    <div class="row">
        <div class="col">
            <mat-card appearance="outlined">
                <mat-card-header>
                    <mat-card-title>Forgot Password?</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form [formGroup]="forgotPasswordForm" (submit)="resetPassword()">
                        <mat-form-field>
                            <input matInput name="username" formControlName="username" placeholder="Username" />
                        </mat-form-field>
                        <button type="submit" mat-raised-button color="accent">Send Link</button>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
<app-footer [event]="event"></app-footer>
