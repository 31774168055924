import { GroupsComponent } from './groups.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { EditGroupComponent } from './edit-group/edit-group.component';
import { GroupComponent } from './group/group.component';
import { Routes } from '@angular/router';

export const GROUP_COMPONENTS = [
    GroupsComponent,
    AddGroupComponent,
	EditGroupComponent,
	GroupComponent
];

export const GROUP_ROUTES: Routes = [
	{
		path: 'groups',
		component: GroupsComponent
	},
	{
		path: 'groups/add',
		component: AddGroupComponent
	},
	{
		path: 'groups/edit/:id',
		component: EditGroupComponent
	}
];