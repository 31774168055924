import { Component, OnInit, Injector } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Manifest } from '@models/manifest';
import { ManifestService } from '@common/services/manifest.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@common/services/auth.service';
import { ShipmentComponent } from '../shipment.component';
import { ShipmentService } from '@common/services/shipment.service';
import { CompanyService } from '@common/services/company.service';
import { ShippingDestinationService } from '@common/services/shipping-destination.service';
import { AppService } from '../../../common/services/app.service';
import { log } from '../../../common/log/log';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.css']
})
export class ContactInfoComponent implements OnInit {
  // public mask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  public patterns = { '0': { pattern: new RegExp('\[0-9]{3}-[0-9]{3}-[0-9]{4}\]')} };

  public companyFilterCtrl: FormControl = new FormControl();
  public newEntry = false;
  parentComponent;
  public companies;
  public departments;
  public shipment;
  public destinations;
  filteredCompanies;
  user;

  form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private ManifestService: ManifestService,
    private MatSnackBar: MatSnackBar,
    private AuthService: AuthService,
    private router: Router,
    private inj: Injector,
    private ShipmentService: ShipmentService,
    private sds: ShippingDestinationService,
    private CompanyService: CompanyService,
    private appService: AppService
  ) {
    this.parentComponent = this.inj.get(ShipmentComponent);
    this.user = this.AuthService.getUser();
    this.form = this._fb.group(new Manifest());
  }
  ngOnInit() {

    this.CompanyService.allSorted().subscribe(r => {
      this.companies = r;
      this.filteredCompanies = this.companies;
    })

    // this.newEntry = this.parentComponent.isNew;
    this.route.parent.params.subscribe( r => {
      if(r.id == "new") {
        this.newEntry = true;
      }
    })

    this.route.data.subscribe( ({data: data}) => {

    	log.Debug("data: ", data)

      // if(!this.newEntry) {
      //   this.form.get('company_id').disable();
      //   this.form.get('shipping_contact_name').disable();
      //   this.form.get('shipping_contact_phone').disable();
      //   this.form.get('shipping_contact_email').disable();
      //   this.form.get('brand').disable();
      //   this.form.get('shipping_destination_id').disable();
      //   this.form.get('is_customs_shipment').disable();
      // } else {
      //   this.form.get('company_id').enable();
      //   this.form.get('shipping_contact_name').enable();
      //   this.form.get('shipping_contact_phone').enable();
      //   this.form.get('shipping_contact_email').enable();
      //   this.form.get('brand').enable();
      //   this.form.get('shipping_destination_id').enable();
      //   this.form.get('is_customs_shipment').enable();
      // }

      this.form.patchValue(data.manifest);
      this.destinations = data.destinations;
      if( !this.hasDestinations() ) {
        this.destinationsByCompanyID({"value": data.manifest.company_id})
      }

    })


    this.companyFilterCtrl.valueChanges.subscribe(() => {
      this.filterCompanies();
    });

  }

  protected filterCompanies() {
    if (!this.companies) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompanies = this.companies;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the companies
    this.filteredCompanies = this.companies.filter(company => company.name.toLowerCase().indexOf(search) > -1);
  }

  next() {
    if(this.newEntry) {
      if(this.form.valid) {
        if( this.form.get('company_id').value == null ) {
          this.form.get('company_id').setValue(this.user['company_id']);
        }
        this.ShipmentService.currentManifest.Info = this.form.value
        log.Debug("debug contact-info form value: ", this.form.value)
        this.appService.navigate(['/', 'app', 'shipping', 'shipment', 'new', 'schedule'])
      } else {
        this.MatSnackBar.open("Please fill out all required fields!", 'Ok');
      }
    } else {
      if(this.form.valid){
        if( this.form.get('company_id').value == null ) {
          this.form.get('company_id').setValue(this.user['company_id']);
        }
        this.ManifestService.save(this.form.value).subscribe(r => {
          this.MatSnackBar.open("Saved!", null, {duration: 3000});
          this.appService.navigate(['/', 'app', 'shipping', 'shipment', r['id'], 'schedule'])
        }, err => {
          this.MatSnackBar.open("Failed to save! Err: " + err, 'Ok');
        });
      }else{
        this.MatSnackBar.open("Please fill out all required fields!", 'Ok');
      }
    }
  }

  destinationsByCompanyID(companyID) {
  	if(companyID.value > 0) {
    	this.sds.getForCompany(companyID.value).subscribe(r => {
      		this.destinations = r;
    	})
    }
  }

  hasDestinations(){
    return this.destinations && this.destinations.length > 0;
  }

  currentRole(){
		return this.AuthService.getRole();
	}

}
