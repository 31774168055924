import { GenericObj } from './../../models/global';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { of, BehaviorSubject, takeUntil, Subject, tap } from 'rxjs';
import { log } from "../log/log"

export class ViewState{
    constructor(
        public position: 'side' | 'over' | 'push' = 'side',
        public open: boolean = true
    ){}

    get show(): boolean {
        return this.position != 'side'
    }
}
 @Injectable({
    providedIn: 'root'
})
export class SidebarService implements OnDestroy {

    displayNameMap = new Map([
        [Breakpoints.XSmall, 'XSmall'],
        [Breakpoints.Small, 'Small'],
        [Breakpoints.Medium, 'Medium'],
        [Breakpoints.Large, 'Large'],
        [Breakpoints.XLarge, 'XLarge'],
    ]);

    private _defaultState: ViewState = new ViewState();
    private _states: GenericObj<ViewState> = {
        [this.displayNameMap.get(Breakpoints.Small)]: new ViewState('over',false)
    }

    private _destroyed$ = new Subject<void>();
    private _open$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private _hasSidebar$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private _state$ = new BehaviorSubject<ViewState>(this._defaultState)
    public state$ = this._state$.asObservable()
    private _currentScreenSize: string;

    public isMobile;

    get open$() {
        return this._open$.asObservable();
    }

    get hasSidebar$() {
        return this._hasSidebar$.asObservable();
    }

    constructor(
        private _observer: BreakpointObserver
    ) {
        this._observer.observe([...this.displayNameMap.keys()]).pipe(
            takeUntil(this._destroyed$)
        ).subscribe(result => {
            let size = this._currentScreenSize
            for (const query of Object.keys(result.breakpoints)) {
                if (result.breakpoints[query]) {
                  size = this.displayNameMap.get(query) ?? 'default';
                }
              }
            if(this._currentScreenSize !== size) {
                this._currentScreenSize = size
                this._setState()
            }

            log.Debug("current size: ", this._currentScreenSize)
        })
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    open() {
        this._open$.next(true);
    }

    close() {
        if (this.isMobile)
            this._open$.next(false);
    }

    toggle() {
        this._open$.next(!this._open$.value);
    }

    isOpen() {
        return this._open$.value;
    }

    pageHasSidebar() {
        return this._hasSidebar$.value;
    }

    setPageHasSidebar(hasSidebar) {
        this._hasSidebar$.next(hasSidebar);
    }

    private _setState(){

        const state = this._currentScreenSize in this._states ? this._states[this._currentScreenSize] : this._defaultState
        this._state$.next(state)
        this._open$.next(state.open)
    }

}
