<!-- TJ: This doesn't appear to be in use -->
<mat-card appearance="outlined" *ngIf="showContractAccepted() && auth.isPrimary()">
    <mat-card-content>
        <p>Thank you for accepting your event contract.</p>
        <p>The invoice for your participation fee will be posted on this site on <b>November 13, 2019</b>. All payments are due <b>NO LATER THAN JANUARY 13, 2020</b>.</p>
    </mat-card-content>
</mat-card>
<button style="margin: 10px;height: 40px;" type="button" mat-raised-button color="primary" (click)="print()">Print <mat-icon>print</mat-icon></button>
<h1 style="margin-left:10px;">SHIPPING INFORMATION</h1>
<span *ngIf="!hasDestinations()" style="color:red;margin-left:10px;" >No shipping destinations are available for your company, please contact your supplier coordinator.</span>
<div style="min-height:calc(100vh - 110px - 60px);" id="print-section" class="container-fluid site-content" [innerHTML]="page.content | safe: 'html'"></div>
