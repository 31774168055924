import { CompanyService } from './company.service';
import { DepartmentService } from './department.service';
import { ContentService } from './content.service';
import { ShipmentService } from './shipment.service';
import { UserService } from './user.service';
import { SupplierService } from './supplier.service';
import { AuthService } from './auth.service';
import { AuthGuardService } from './auth-guard.service';
import { SidebarService } from './sidebar.service';
import { StateService } from './state.service';
import { AddressService } from './address.service';
import { GroupService } from './group.service';
import { RoleService } from './role.service';
import { ContractStatusService } from './contract-status.service';
import { ShippingDestinationService } from './shipping-destination.service';
import { PageService } from './page.service';
import { AppService } from './app.service';
import { InvoiceTemplateService } from './invoice-template.service';
import { PaymentsService} from './payments.service';
import { CustomInvoiceService } from './custom-invoice.service';
import { RegistrationService } from './registration.service';
import { ManifestService } from './manifest.service';
import { FileServerService } from './file-server.service';
import { EventService } from './event.service';

export const SERVICES = [
	FileServerService,
	ManifestService,
	UserService,
	CompanyService,
	DepartmentService,
	ShipmentService,
	ContentService,
	SupplierService,
	AuthService,
	SidebarService,
	StateService,
	GroupService,
	RoleService,
	ContractStatusService,
	ShippingDestinationService,
	PageService,
	AppService,
	AddressService,
	InvoiceTemplateService,
	PaymentsService,
	CustomInvoiceService,
	RegistrationService,
	EventService
];
