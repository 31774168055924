<div class="container-fluid">
	<div class="row">
		<div class="col">
			<a [routerLink]="['add']" mat-raised-button color="accent"><mat-icon>add</mat-icon>Add Group</a>
		</div>
		<div class="col">
<!-- 			<mat-form-field>
				<input matInput name="search" placeholder="Search Groups" />
			</mat-form-field> -->
		</div>
	</div>
	<div id="print-section">
		<div class="row">
			<div class="col">
				<table mat-table #table [dataSource]="dataSource" matSort class="table mat-elevation-z7 gutters sticky-header">
					  <!-- Position Column -->
					  <ng-container matColumnDef="name">
					    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
					    <td data-label="Name" mat-cell *matCellDef="let element"> {{element.name}} </td>
					  </ng-container>

					  <ng-container matColumnDef="active">
					  	<th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
					  	<td data-label="Active" mat-cell *matCellDef="let element">
					  		<mat-icon *ngIf="element.active">
					    		done
					    	</mat-icon>
					    	<mat-icon color="accent" *ngIf="!element.active">
					    		block
					    	</mat-icon>
					  	</td>
					  </ng-container>

					  <ng-container matColumnDef="actions">
					  	<th mat-header-cell *matHeaderCellDef> Actions </th>
					  	<td data-label="Actions" mat-cell *matCellDef="let element">
					  		<a mat-icon-button color="primary" [routerLink]="['edit', element.id]"><mat-icon>edit</mat-icon></a>
					  		<a mat-icon-button color="accent" (click)="delete(element.id)"><mat-icon>delete</mat-icon></a>
					  	</td>
					  </ng-container>

					  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
					  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>
			</div>
		</div>
	</div>
	<button style="margin-left: 10px;" type="button" mat-raised-button color="primary" (click)="print()">Print <mat-icon>print</mat-icon></button>
</div>