import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'pgl-file-drop',
    templateUrl: './file-drop.component.html',
    styleUrls: ['./file-drop.component.css']
})
export class FileDropComponent implements OnInit {

    @Output() onDrop = new EventEmitter<any>();
    pool = [];

    constructor() {}

    ngOnInit() {
    }

    // onFileDragOver(e){
    //   this.onD
    // }
    // onFileDrop(e) {
    //   this.onDrop.emit(e);
    // }

    @HostListener('drop', ['$event']) public ondrop(e) {
        e.preventDefault();
        e.stopPropagation();
        let files = e. e.dataTransfer.files;
        if(files.length > 0) {
            this.onDrop.emit(files)
        }
    }
}
