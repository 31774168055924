import { SuppliersComponent } from './suppliers.component';
import { SuppliersResolve } from './suppliers.resolve';
import { Routes } from '@angular/router';
import { EditUserComponent } from '../users/edit-user/edit-user.component';
import { EditCompanyComponent } from '../companies/edit-company/edit-company.component';

export const SUPPLIER_ROUTES = [
	{
		path: 'suppliers',
		component: SuppliersComponent,
		resolve: {
			suppliers: SuppliersResolve
		}
	},
	{
        path: 'suppliers/edit-user/:id',
        component: EditUserComponent
    },
    {
		path: 'suppliers/edit-company/:id',
		component: EditCompanyComponent
	}
];

export const SUPPLIER_COMPONENTS = [
	SuppliersComponent,
	EditUserComponent,
	EditCompanyComponent
];

export const SUPPLIER_PROVIDERS = [
	SuppliersResolve
];