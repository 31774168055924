<form [formGroup]="addForm" (submit)="add()">
	<div class="row">
		<div class="col">
			<mat-form-field>
				<mat-label>Department</mat-label>
				<mat-select formControlName="department_id" required>
					<mat-option *ngFor="let d of departments" [value]=d.id>{{d.name}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col">
			<mat-form-field>
              <input formControlName="inbound_lock" matInput [matDatepicker]="inbound_lock" placeholder="Inbound Lock Date" required>
              <mat-datepicker-toggle matSuffix [for]="inbound_lock"></mat-datepicker-toggle>
              <mat-datepicker #inbound_lock></mat-datepicker>
            </mat-form-field>
		</div>
		<div class="col">
			<mat-form-field>
              <input formControlName="outbound_lock" matInput [matDatepicker]="outbound_lock" placeholder="Outbound Lock Date" required>
              <mat-datepicker-toggle matSuffix [for]="outbound_lock"></mat-datepicker-toggle>
              <mat-datepicker #outbound_lock></mat-datepicker>
            </mat-form-field>
		</div>
		<div class="col">
			<button type="submit" mat-raised-button color="primary">Add</button>
		</div>
	</div>
</form>
<div class="row">
	<div class="col">
		<table mat-table [dataSource]="dates" class="table mat-elevation-z7 gutters">
			<ng-container matColumnDef="department">
			    <th mat-header-cell *matHeaderCellDef> Department </th>
			    <td mat-cell data-label="Department" *matCellDef="let element"> 
			    	<mat-form-field>
						<mat-label>Department</mat-label>
						<mat-select [(ngModel)]="element.department_id" (selectionChange)="update(element)">
							<mat-option *ngFor="let d of departments" [value]=d.id>{{d.name}}</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
			  </ng-container>

			<ng-container matColumnDef="inbound_lock">
				<th mat-header-cell *matHeaderCellDef>Inbound Lock</th>
				<td mat-cell data-label="Inbound Lock" *matCellDef="let element;let i = index">
					<mat-form-field>
		              <input (dateChange)="update(element)" [(ngModel)]="element.inbound_lock" matInput [matDatepicker]="inbound_lock_i" placeholder="Inbound Lock Date">
		              <mat-datepicker-toggle matSuffix [for]="inbound_lock_i"></mat-datepicker-toggle>
		              <mat-datepicker #inbound_lock_i></mat-datepicker>
		            </mat-form-field>
				</td>
			</ng-container>

			<ng-container matColumnDef="outbound_lock">
				<th mat-header-cell *matHeaderCellDef>Outbound Lock</th>
				<td mat-cell data-label="Outbound Lock" *matCellDef="let element">					
					<mat-form-field>
		              <input (dateChange)="update(element)" [(ngModel)]="element.outbound_lock" matInput [matDatepicker]="outbound_lock_i" placeholder="Outbound Lock Date">
		              <mat-datepicker-toggle matSuffix [for]="outbound_lock_i"></mat-datepicker-toggle>
		              <mat-datepicker #outbound_lock_i></mat-datepicker>
		            </mat-form-field>
		       	</td>
			</ng-container>

			<ng-container matColumnDef="delete">
				<th mat-header-cell *matHeaderCellDef>Delete</th>
				<td mat-cell data-label="Delete" *matCellDef="let element">
					<a mat-icon-button color="accent" (click)="delete(element)"><mat-icon>delete</mat-icon></a>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>
</div>