<div style="min-height:calc(100vh - 120px);" class="container-fluid">
	<div class="row">
		<div class="col">
			<div>
			    <h1>Download Manifest</h1>
			    <h4>NEED TO MAKE CHANGES TO YOUR SHIPMENT SUMMARY OR PRODUCT DETAILS?</h4>
			    <mat-card appearance="outlined">
			    	<mat-card-header>
			    		<mat-card-title>Return to the manifest form saved in your excel document files</mat-card-title>
			    	</mat-card-header>
			    	<mat-card-content>
			            <div *ngIf="page" [innerHTML]="page.content | safe: 'html'"></div>
					</mat-card-content>
			    </mat-card>
			    <mat-card appearance="outlined">
			    	<mat-card-header>
			    		<mat-card-title>Can’t find the saved manifest form in your document files?</mat-card-title>
			    	</mat-card-header>
			    	<mat-card-content>
			    		<div class="row">
			    			<div class="col">
			    				<p>Select the shipment manifest number below to re-download the blank form to complete</p>
			    			</div>
			    		</div>
			    		<div class="row" *ngIf="isAdmin()">
			    			<div class="col">
			    				<mat-form-field>
			    					<mat-label>Company To Download From</mat-label>
			    					<mat-select name="company">
			    						<mat-option selected value="1">Eyesore, Inc</mat-option>
			    						<mat-option value="2">Hartmann Studios</mat-option>
			    					</mat-select>
			    				</mat-form-field>
			    			</div>
			    		</div>
			    		<div class="row">
			    			<div class="col">
    							<table mat-table [dataSource]="manifests" class="table mat-elevation-z7 gutters">
										<ng-container matColumnDef="manifest_number">
											<th mat-header-cell *matHeaderCellDef> Manifest Number </th>
											<td mat-cell data-label="Manifest Number" *matCellDef="let element"> {{element.manifest_number}} </td>
										</ng-container>
									
										<ng-container matColumnDef="shipping_contact_name">
											<th mat-header-cell *matHeaderCellDef> Contact Name </th>
											<td mat-cell data-label="Contact Name" *matCellDef="let element"> {{element.shipping_contact_name}} </td>
										</ng-container>
									
										<ng-container matColumnDef="shipping_contact_email">
										<th mat-header-cell *matHeaderCellDef> Contact Email </th>
										<td mat-cell data-label="Contact Email" *matCellDef="let element"> {{element.shipping_contact_email}} </td>
										</ng-container>
										
										<ng-container matColumnDef="shipping_contact_phone">
											<th mat-header-cell *matHeaderCellDef> Contact Phone </th>
											<td mat-cell data-label="Contact Phone" *matCellDef="let element"> {{element.shipping_contact_phone}} </td>
										</ng-container>
									
										<ng-container matColumnDef="brand">
											<th mat-header-cell *matHeaderCellDef> Brand(s) </th>
											<td mat-cell data-label="Brand" *matCellDef="let element"> {{element.brand}} </td>
										</ng-container>
									
										<ng-container matColumnDef="ship_date">
											<th mat-header-cell *matHeaderCellDef> Ship Date </th>
											<td mat-cell data-label="Ship Date" *matCellDef="let element"> {{element.ship_date | date}} </td>
										</ng-container>
									
										<ng-container matColumnDef="arrive_date">
											<th mat-header-cell *matHeaderCellDef> Arrival Date </th>
											<td mat-cell data-label="Arrival Date" *matCellDef="let element"> {{element.arrive_date | date}} </td>
										</ng-container>
									  <ng-container matColumnDef="actions">
									  	<th mat-header-cell *matHeaderCellDef>Actions</th>
									  	<td mat-cell data-label="Actions" *matCellDef="let element">
									  		<button mat-raised-button color="primary" (click)="downloadPrefilled(element)" [disabled]="element.uploaded != null">Download Blank</button>&nbsp;
											<button mat-raised-button color="accent" (click)="downloadPrevious(element)" [disabled]="element.uploaded == null">Download Previous</button>
											<div  *ngIf="downloadingXLS[element.manifest_number]" style="width:200px">
												<mat-progress-bar mode="buffer"></mat-progress-bar>
											</div>
									  	</td>
									  </ng-container>

									  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
									  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
								</table>
			    			</div>
			    		</div>
			    	</mat-card-content>
			    </mat-card>
            </div>
		</div>
	</div>
</div>