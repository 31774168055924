<mat-sidenav-container *ngIf="SidebarService.state$ | async as state">
	<mat-sidenav [mode]="state.position" #sidenav [opened]="menuOpen() || state.open" width="250px">
		<mat-nav-list>
			<a mat-list-item routerLinkActive="active" *ngFor="let item of pages" [routerLink]="[item.slug]">{{item.name}}</a>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content [linkWatcher]="SaveAnalytic">
		<router-outlet></router-outlet>
		<app-footer [event]="event"></app-footer>
	</mat-sidenav-content>
</mat-sidenav-container>
