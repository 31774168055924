import { SettingsService } from '../../common/services/settings.service';
import { Resolve } from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class LogoResolve {

	constructor(
		public SettingsService: SettingsService
	){}

	resolve(){
		return this.SettingsService.ByName("Logo");
	}

}
