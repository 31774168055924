
import { FormGroup, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { log } from '@common/log/log';

export class FieldBuilder {
    public static fields(group: IFormGroup): IField<any>[] {
        return Object.keys(group).map(key => {
            return group[key];
        });
    }

    public static getGroup(fields: IFormGroup, fb: FormBuilder) {
        if (fields == null) {
            return {};
        }
        return Object.keys(fields).reduce(this._fieldToGroupFn(fields, fb), {});
    }

    public static groupByColumns(list: IField<any>[], numberOfRows: number = 2): IField<any>[][] {
        let index = 1;
        return list.reduce((acc, field) => {
            acc[acc.length - 1].push(field);
            if (index % numberOfRows === 0 && index < list.length) {
                acc = [...acc, []];
            }
            ++index;
            return acc;
        }, [[]]);
    }

    public static groupByPatern(list: IField<any>[], pattern: number[] = [1]): IField<any>[][] {
        let index = 1;
        let pIndex = 0;
        return list.reduce((acc, field) => {
            log.Debug('reduce: acc, field', acc, field);
            const len = acc[acc.length - 1].push(field);
            if (len === pattern[pIndex] && index < list.length) {
                acc = [...acc, []];
                ++pIndex;
            }
            ++index;
            return acc;
        }, [[]]);
    }

    public static getError(field: IField<any>, control: AbstractControl): string {
        if (FieldBuilder.isObjEmpty(control.errors)) {
            return '';
        }
        const error = Object.keys(control.errors)[0];
        return field.errors && field.errors[error] ? field.errors[error] : 'Error';
    }

    private static isObjEmpty(obj) {
        if (obj == null || obj === undefined) {
            return true;
        }
        return Object.keys(obj).length === 0;
    }

    private static _fieldToGroupFn = (fields, fb: FormBuilder) => {
        return (acc, key): {} => {
            const field: IField<any> = fields[key];
            const controlName = field.name;
            switch (true) {
                case !FieldBuilder.isObjEmpty(field.group):
                    acc[controlName] = fb.group(FieldBuilder.getGroup(field.group, fb));
                    break;
                case field.list && field.list.length > 0:
                    acc[controlName] = fb.array(
                        field.list.map(
                            (item: IFormGroup) => fb.group(FieldBuilder.getGroup(item, fb))
                        )
                    );
                    break;
                default:
                    acc[controlName] = field.validators && field.validators.length > 0 ? [field.value, field.validators] : field.value;
            }
            return acc;
        };
    }
}


export interface IField<T> {
    name: string;
    label?: string;
    value?: T;
    group?: IFormGroup;
    list?: IFormGroup[];
    validators?: any[];
    errors?;
    order?: number;
    hint?: string;
    options?: any[];
    type?: 'select' | 'input' | 'radio' | 'checkbox' | 'textarea' | 'datepicker' | 'timepicker' | 'password';
    optionValue?: (option) => any;
    optionDisplay?: (option) => string;
}

export interface IFormGroup {
    [key: string]: IField<any>;
}
