<div class="container-fluid">
	<form [formGroup]="form" (ngSubmit)="onSubmit()">
		<div class="row">
			<div class="col">
				<mat-form-field>
					<mat-label>App Name</mat-label>
					<input matInput placeholder="App Name" formControlName="name"/>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field>
					<mat-label>Reply To Email</mat-label>
					<input matInput placeholder="Reply To Email" formControlName="reply_to"/>
				</mat-form-field>
			</div>
		</div>
		<div class="row gutters" style="display: none;">
			<div class="col">
				<mat-form-field>
					<mat-label>Alternative Logo URL</mat-label>
					<input matInput placeholder="Alt. Logo URL" formControlName="horizontal_logo" />
				</mat-form-field>
			</div>
			<div class="col">
				<p>Alt. Logo Preview:</p>
				<img [src]="form.get('horizontal_logo').value" style="margin-bottom: 20px; max-width: 300px;" matTooltip="This alternative logo will be used in manifest PDFs and manifest label PDFs." matTooltipPosition="right"/>
			</div>
		</div>
		<div class="row">
			<div class="col" style="margin-bottom: 20px;">
				<mat-form-field>
					<mat-label>Manifest Template</mat-label>
					<input matInput placeholder="Manifest Template" formControlName="manifest_template" />
				</mat-form-field>
				<a mat-raised-button color="primary" [href]="form.get('manifest_template').value" target="_blank">Download Manifest Template Preview</a>
			</div>
		</div>
		<div class="row">
			<div class="col" style="margin-bottom: 20px;">
				<mat-form-field>
					<mat-label>Manifest Ship Only Template</mat-label>
					<input matInput placeholder="Manifest Ship Only Template" formControlName="manifest_ship_only_template" />
				</mat-form-field>
				<a mat-raised-button color="primary" [href]="form.get('manifest_ship_only_template').value" target="_blank">Download Manifest Ship Only Template Preview</a>
			</div>
		</div>
		<div class="row">
			<div class="col" style="margin-bottom: 20px;">
				<mat-form-field>
					<mat-label>Participation Agreement</mat-label>
					<input matInput placeholder="Participation Agreement" formControlName="participation_agreement_link" />
				</mat-form-field>
				<a *ngIf="participationAgreementLink"
					mat-raised-button
					matTooltip="This link will also have to be manually updated wherever it is used in page content."
					color="primary"
					[href]="participationAgreementLink"
					target="_blank">
					Participation Agreement Preview
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col" style="margin-bottom: 20px;">
				<mat-form-field>
					<mat-label>Privacy Policy</mat-label>
					<input matInput placeholder="Privacy Policy" formControlName="privacy_policy_link" />
				</mat-form-field>
				<a *ngIf="privacyPolicyLink"
					mat-raised-button
					matTooltip="This link will also have to be manually updated wherever it is used in user content."
					color="primary"
					[href]="privacyPolicyLink"
					target="_blank">
					Privacy Policy Preview
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col" style="margin-bottom: 20px;">
				<mat-form-field>
					<mat-label>Website User Agreement</mat-label>
					<input matInput placeholder="Website User Agreement" formControlName="website_user_agreement_link" />
				</mat-form-field>
				<a *ngIf="websiteUserAgreementLink"
					mat-raised-button
					matTooltip="This link will also have to be manually updated wherever it is used in page content."
					color="primary"
					[href]="websiteUserAgreementLink"
					target="_blank">
					Website User Agreement Preview
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col" style="margin-bottom: 20px;">
				<mat-form-field>
					<mat-label>Wire Transfer Instructions</mat-label>
					<input matInput placeholder="Wire Transfer Instructions" formControlName="wire_transfer_instructions_link" />
				</mat-form-field>
				<a *ngIf="wireTransferInstructionsLink"
					mat-raised-button
					matTooltip="This link will also have to be manually updated wherever it is used in page content."
					color="primary"
					[href]="wireTransferInstructionsLink"
					target="_blank">
					Wire Transfer Instructions Preview
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col" style="margin-bottom: 20px;">
				<mat-form-field>
					<mat-label>Benefits Overview</mat-label>
					<input matInput placeholder="Benefits Overview" formControlName="benefits_overview_link" />
				</mat-form-field>
				<a *ngIf="benefitsOverviewLink"
					mat-raised-button
					matTooltip="This link will also have to be manually updated wherever it is used in page content."
					color="primary"
					[href]="benefitsOverviewLink"
					target="_blank">
					Benefits Overview Preview
				</a>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<p>Registrations Page Top Text</p>
				<editor formControlName="registration_text" [apiKey]="apiKey" [init]="options"></editor>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<p>Register Page Top Text</p>
				<editor formControlName="new_registration_html" [apiKey]="apiKey" [init]="options"></editor>
			</div>
		</div>

		<div class='row'>
			<div class='col'>
				<p>Register Step 3: Contract Acceptance Text</p>
				<editor formControlName='overview_html' [apiKey]="apiKey" [init]="options"></editor>
			</div>
		</div>
	</form>

	<button type="submit" (click)="onSubmit()" mat-raised-button color="primary">Save</button>
</div>
