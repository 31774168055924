import { Model } from './model';

export class CustomInvoice extends Model {
    invoice_number: string = null;
    file: string = null;
	company_id: number = null;
	amount: number = null;
	paid: boolean = null;
    is_added_to_invoice: boolean = null;

    constructor(properties?: Object) {
        super(properties)
        this.patch(properties)
    }
}
