<mat-sidenav-container>
	<mat-sidenav [mode]="SidebarService.isMobile ? 'over' : 'side'" [opened]="menuOpen() || !SidebarService.isMobile" #sidenav mode="side" [(opened)]="openNav" width="250px">
		<!-- <mat-nav-list>
			<a mat-list-item [routerLink]="['information']" routerLinkActive="active">Information</a>
			<a mat-list-item [routerLink]="['instructions']" routerLinkActive="active">Shipping Instructions</a>
			<a mat-list-item [routerLink]="['shipment', 'new', 'contact-info']" >New Shipment</a>
			<a mat-list-item [routerLink]="['shipments']" routerLinkActive="active">View Shipments</a>
			<a mat-list-item [routerLink]="['download_manifest']" routerLinkActive ="active">Download Manifest</a>
			<a mat-list-item [routerLink]="['upload_manifest']" routerLinkActive="active">Upload Manifest</a>
			<a mat-list-item [routerLink]="['outbound-disposition-search']" routerLinkActive="active"  *ngIf="currentRole() == 'admin'">Outbound Disposition Search</a>
		</mat-nav-list> -->
        <mat-nav-list>
            <a mat-list-item *ngFor="let page of pages" routerLink="{{ page.href }}" routerLinkActive="active">{{ page.name }}</a>
        </mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<router-outlet></router-outlet>
		<app-footer [event]="event"></app-footer>
	</mat-sidenav-content>
</mat-sidenav-container>
