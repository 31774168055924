import { Model } from './model';

export class CustomLockDate extends Model {
	shipping_destination_id: number = null;
	department_id: number = null;
	inbound_lock: Date = null;
	outbound_lock: Date = null;

	constructor(properties?: Object) {
		super(properties)
		this.patch(properties)
	}
}
