<div class="container-fluid">
  <div class="row" >
      <div class="col">
          <a [routerLink]="['add']" mat-raised-button color="accent"><mat-icon>add</mat-icon>Add Registration</a>
      </div>
  </div>
  <div class="row gutters">
    <mat-expansion-panel [expanded]="true" [hideToggle]="false" class="col margin-top">
      <mat-expansion-panel-header>
        <mat-panel-title>Filter Pages</mat-panel-title>
        <mat-panel-description></mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="filterForm">
        <div class="row gutters">
          <div class="col">
            <mat-form-field>
              <mat-label>Company</mat-label>
              <input type="text" formControlName="company" matInput placeholder="Company" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input type="text" formControlName="name" matInput placeholder="Name" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field>
                <mat-label>Department</mat-label>
                <mat-select formControlName="department_id">
                    <mat-option>
                        <ngx-mat-select-search
                            [formControl]="departmentFilterCtrl"
                            [placeholderLabel]="'Find department...'"
                            [noEntriesFoundLabel]="'no matching department found'">
                            <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]=null>All</mat-option>
                    <mat-option *ngFor="let d of filteredDepartments" [value]=d.id>{{d.name}}</mat-option>
                </mat-select>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field>
                <mat-select formControlName="primary_onsite" placeholder="Is Primary">
                    <mat-option [value]=null>All</mat-option>
                    <mat-option [value]=1>Yes</mat-option>
                    <mat-option [value]=0>No</mat-option>
                </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
      <button mat-raised-button color="primary" (click)="reset()" >Reset Filters</button>
      <button style="margin-left:10px;" mat-raised-button color="primary" (click)="ExportTOExcel()">Export as .xls</button>
      <mat-checkbox (change)="addressesToXLS = !addressesToXLS">Include address in .xls</mat-checkbox>
    </mat-expansion-panel>
  </div>
	<div id="print-section">
    <div class="row">
      <div class="col" #TABLE>
        <table mat-table #table [dataSource]="registrations" matSort class="table mat-elevation-z7 gutters sticky-header">

          <ng-container matColumnDef="badge_first_name">s
            <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
            <td mat-cell data-label="First Name" *matCellDef="let element"> {{element.badge_first_name}} </td>
          </ng-container>

          <ng-container matColumnDef="badge_last_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
            <td mat-cell data-label="Last Name" *matCellDef="let element"> {{element.badge_last_name}} </td>
          </ng-container>

          <ng-container matColumnDef="onsite_role">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Onsite Role</th>
              <td mat-cell data-label="Onsite Role" *matCellDef="let element">{{element.onsite_role}}</td>
          </ng-container>

          <ng-container matColumnDef="mobile_phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</th>
            <td mat-cell data-label="Phone Number" *matCellDef="let element">{{element.mobile_phone}}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell data-label="Email" *matCellDef="let element">{{element.email}}</td>
          </ng-container>

          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
            <td mat-cell data-label="Company" *matCellDef="let element">{{element.company}}</td>
          </ng-container>

          <ng-container matColumnDef="department">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Department</th>
            <td mat-cell data-label="Department" *matCellDef="let element">{{element.department}}</td>
          </ng-container>

          <ng-container matColumnDef="primary_onsite">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Primary Onsite</th>
            <td mat-cell data-label="Primary Onsite" *matCellDef="let element">
              <mat-icon *ngIf="element.primary_onsite">
                  done
              </mat-icon>
              <mat-icon color="accent" *ngIf="!element.primary_onsite">
                  block
              </mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="registration_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Registration Date</th>
            <td mat-cell data-label="Registration Date" *matCellDef="let element">
              
              <span *ngIf="element.registration_date">
                {{element.registration_date | date:'short'}}
            </span>
            <span color="accent" *ngIf="!element.registration_date">
                --
            </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell data-label="Actions" *matCellDef="let element">
                <a mat-icon-button color="primary" [routerLink]="['../../registration/edit', element.id]" [disabled]="!AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR)"><mat-icon>edit</mat-icon></a>
                <a mat-icon-button color="accent" (click)="delete(element)" [disabled]="!AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR)"><mat-icon>delete_forever</mat-icon></a>
              </td>
          </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
    </div>
</div>
