<span *ngIf="!uploaded && !uploading">
	    <pgl-file-drop (onDrop)="upload($event)" (click)="file.click()"></pgl-file-drop>
	    <input type="file" #file style="display:none" (change)="upload($event)" />
</span>
<mat-spinner *ngIf="uploading" style="margin:auto"></mat-spinner>
<mat-spinner *ngIf="validating" style="margin:auto"></mat-spinner>
<span *ngIf="uploaded" style="height: 600px; overflow:auto">
	Upload Successful, please review before sending invites.
	<table mat-table [dataSource]="results" class="table gutters">
		<ng-container matColumnDef="department">
			<th mat-header-cell *matHeaderCellDef>Department</th>
			<td mat-cell *matCellDef="let element">{{element.department_label}}</td>
		</ng-container>
		<ng-container matColumnDef="company">
			<th mat-header-cell *matHeaderCellDef>Company</th>
			<td mat-cell *matCellDef="let element">{{element.company}}</td>
		</ng-container>
		<ng-container matColumnDef="contact_name">
			<th mat-header-cell *matHeaderCellDef>Contact Name</th>
			<td mat-cell *matCellDef="let element">{{element.contact_name}}</td>
		</ng-container>
		<ng-container matColumnDef="contact_email">
			<th mat-header-cell *matHeaderCellDef>Contact Email</th>
			<td mat-cell *matCellDef="let element">{{element.contact_email}}</td>
		</ng-container>
		<ng-container matColumnDef="participation_fee">
			<th mat-header-cell *matHeaderCellDef>Participation Fee</th>
			<td mat-cell *matCellDef="let element">{{element.participation_fee}}</td>
		</ng-container>
		<ng-container matColumnDef="errors">
			<th mat-header-cell *matHeaderCellDef>Errors</th>
			<td mat-cell *matCellDef="let element; let i = index"><mat-icon *ngIf="errors[i]">warning</mat-icon>
				<ng-container *ngFor="let err of errors[i]">
					{{err}}<br>
				</ng-container>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	 	<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>
	<button mat-raised-button (click)="validate()">Validate</button>
	<button mat-raised-button *ngIf="validated" (click)="import()">Confirm</button>
	<button mat-raised-button (click)="clear()">Restart</button>
</span>
