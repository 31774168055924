import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../common/services/company.service';
import { StateService } from '../common/services/state.service';
import { CountryService } from '../common/services/country.service';
import { AddressService } from '../common/services/address.service';
import { DepartmentService } from '../common/services/department.service';
import { AuthService, AuthGroups } from '../common/services/auth.service';
import { UserService } from '../common/services/user.service';
import { Company } from '../models/company';
import { User } from '../models/user';
import { Address } from '../models/address';
import { Department } from '../models/department';
import { log } from '@common/log/log';
import { Observable, forkJoin } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

    company = new Company();
    get name(): string {
        return this.company.name || ''
    }
    get department_name(): string {
        return this.department.name || ''
    }
    get invitation_code(): string {
        return this.company.invitation_code || ''
    }
    get participation_fee(): string {
        return `${this.company.participation_fee || 0}`
    }

    currentUser;
    states;
    countries;
    sameAsBilling = false;
    isPrimary = true;
    billingCountry;
    mailingCountry;
    shipOnly: boolean;
    user;
    event;

    department = new Department();

    authGroups = AuthGroups;

    form: FormGroup;
    billing: FormGroup;
    mailing: FormGroup;

    hasEditPermission: boolean = false;

  constructor(
      private route: ActivatedRoute,
      private cs: CompanyService,
      private authS: AuthService,
      private ss: StateService,
      public us: UserService,
      private CountryService: CountryService,
      private _fb: FormBuilder,
      private as: AddressService,
      private _snack: MatSnackBar,
      private ds: DepartmentService,
      private auth: AuthService
  ) { }

  ngOnInit() {

      this.route.data.subscribe((data: {
          event;
      }) => {
          log.Debug("data: ", data)
          this.event = data.event
      })

      this.shipOnly = this.auth.getShipOnly()
      this.hasEditPermission = this.auth.HasPermission(this.authGroups.SUPPLIER_PRIMARY_CONTACT)
      log.Debug("readonly: ", !this.hasEditPermission)
      this.user = this.us.getLoggedInUser()

      this.form = this._fb.group(new Company());
      this.mailing = this._fb.group(new Address());
      this.billing = this._fb.group(new Address());


      this.ss.getAll().subscribe(r => {
         this.states = r;
      });
      this.CountryService.getAll().subscribe(r => {
         this.countries = r;
      });
      this.cs.get(this.user.company_id).subscribe( r => {
          Object.assign(this.company, r);
          log.Debug("company: ", this.company);
          forkJoin([
              this.as.get(this.company['billing_address_id']),
              this.as.get(this.company['mailing_address_id']),
              this.ds.get(this.company['department_id']),
              this.cs.GetContractStatusByID(this.company['contract_status_id']),
          ]).subscribe(([b, m, d, c]:[any, any, any, string]) => {
              if(b)
                this.billing = this._fb.group(b);
              if(m)
                this.mailing = this._fb.group(m);
              this.sameAsBilling = this.company.same_as_billing;
              this.billingCountry = this.billing.value.country_id;
              this.mailingCountry = this.mailing.value.country_id;
              this.company.contract_status = c
              Object.assign(this.department, d);
          });
      });
      log.Debug("company: ", this.company);
      log.Debug("billing: ", this.billing);
      log.Debug("mailing: ", this.mailing);
  }

  ngDoCheck() {
    if(this.user['is_primary']){
      this.billing.enable();
      this.mailing.enable();
      this.isPrimary = true;
    }else{
      this.billing.disable();
      this.mailing.disable();
      this.isPrimary = false;
    }
  }

    save() {
        //filter out the selected ones...
        let billing = Object.assign(new Address(), this.billing.value);
        let mailing;
        if(this.sameAsBilling){
            //if mailing address is selected to be the same as billing
            mailing = Object.assign(new Address(), this.billing.value);
            mailing.id = this.mailing.value.id;
        }else{
            mailing = Object.assign(new Address(), this.mailing.value);
        }
        if(mailing.country_id == 1){
            mailing.other_country = null;
            mailing.other_state = null;
        }else if(mailing.country_id > 1 && mailing.country_id < 4){
            mailing.other_country = null;
            mailing.state_id = null;
        }else{mailing.state_id = null;}

        if(billing.country_id == 1){
            billing.other_country = null;
            billing.other_state = null;
        }else if(billing.country_id > 1 && billing.country_id < 4){
            billing.other_country = null;
            billing.state_id = null;
        }else{billing.state_id = null;}
        forkJoin([
            this.as.save(billing),
            this.as.save(mailing)
        ]).subscribe(([b, m]) => {
            if(b)
                this.company.billing_address_id = b["id"];
            if(m)
                this.company.mailing_address_id = m["id"];
            this.company.same_as_billing = this.sameAsBilling;
            this.cs.save(this.company).subscribe(r => {
                this._snack.open('Company Saved!',null ,{duration: 3000,  horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: ['yellow-snackbar', 'largeSnack']});
            });
        });
    }

}
