import { GenericObj } from '@models/global';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Country } from '@models/country';
import { map } from 'rxjs';
import { TransformService } from '@common/services/transform.service';

@Injectable({
	providedIn: 'root'
})
export class CountryService {

	constructor(
		public http: HttpClient,
        public transform: TransformService
	) { }

	getAll(){
		return this.http.get<Country[]>(environment.api + "/countries/all").pipe(map(this.resolveCountries()));
	}

    resolveCountries(): (r: GenericObj) => Country[]{
        return this.transform.resolveList((c) => new Country(c))
    }
}
