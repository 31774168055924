import { Component, OnInit, HostBinding } from '@angular/core';
import { MenuService } from '../../common/services/menu.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { log } from '@common/log/log';
import { FormBuilder } from '@angular/forms';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-menus',
	templateUrl: './menus.component.html',
	styleUrls: ['./menus.component.css']
})
export class MenusComponent implements OnInit {

	public menus;
    public filteredMenus;
	public displayedColumns = ['name', 'actions'];
  @HostBinding('class') classes = 'full-height no-head';

    form;

	constructor(
		public MatSnackBar: MatSnackBar,
        public MenuService: MenuService,
        public FormBuilder: FormBuilder
	) {
        this.form = this.FormBuilder.group({
            name: '',
        })
    }

	ngOnInit() {
		this.MenuService.getAllSorted().subscribe(r => {
			this.menus = r;
            this.filteredMenus = Object.assign([], r);
            this.updateOriginalIndex(this.filteredMenus);
		});
        this.form.get('name').valueChanges.pipe(
            map( val => val)
        ).subscribe( val => {
            this.filteredMenus = this.menus.filter( menu => menu.name.toUpperCase().indexOf(val.toUpperCase()) > -1 )
        })
	}

	delete(menu){
		log.Debug("menu: ", menu)
		if(confirm("Are you sure that you want to delete the menu " + menu.name + "?")){
			this.MenuService.delete(menu.id).subscribe(r => {
				this.MatSnackBar.open("Deleted!", null, {duration: 3000});
				this.ngOnInit();
			});
		}
	}

    updateOriginalIndex( array ) {
        for( let i in array ) {
            const item = array[i]
            item['originalIndex'] = i
        }
    }

    onDrop(event: CdkDragDrop<string[]>) {
        const filteredMenu = this.filteredMenus[event.previousIndex];
        const previousMenuIndex = filteredMenu.originalIndex;

        const swappedMenu = this.filteredMenus[event.currentIndex];
        const currentMenuIndex = swappedMenu.originalIndex;

        moveItemInArray(this.filteredMenus, event.previousIndex, event.currentIndex);
         if( event.previousIndex != event.currentIndex) {
             log.Debug("menus before: ", this.menus)
             moveItemInArray(this.menus, previousMenuIndex, currentMenuIndex);
             log.Debug("menus after: ", this.menus)
        }

        log.Debug("previous: ", event.previousIndex, previousMenuIndex)
        log.Debug("current: ", event.currentIndex, currentMenuIndex)

        this.updateOriginalIndex(this.filteredMenus)

    }

    saveOrder() {
        for(let i in this.menus) {
            const menu = this.menus[i]
            menu.menu_order = (Number(i) + 1)
        }
        this.MenuService.updateAll(this.menus).subscribe( r => {
            this.MatSnackBar.open("Menu Order Updated!",null ,{duration: 3000});
        });
    }

	print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        printContents = printContents.replace(/> block </gi, "> No <");
        let finalPrintContents = printContents.replace(/> done </gi, "> Yes <");
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              table {font-family: Lato, sans-serif;}
              h3 {font-family: Lato, sans-serif;}
              button {font-weight: bold;font-size: 12px;background: none;border: none;color: #888;font-family: Lato, sans-serif;}
              th {font-weight: bold;font-size: 12px;color: #888;}
              th:last-of-type {display: none;}
              td {font-size: 12px;color: #222;padding: 5px;text-align: center;border-bottom: 1px solid #ccc;}
              td:last-of-type {display: none;}
              div.col {text-align: center;}
              table {display: inline-block;}
              </style>
            </head>
            <body onload="window.print();window.close()">${finalPrintContents}</body>
          </html>`
        );
        popupWin.document.close();
    }

}
