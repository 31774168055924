
<mat-toolbar color="accent" class="app-toolbar no-print"
    [class.is-mobile]="isMobile$ | async" [ngClass]="{'archive' : isArchive }"
    >
	<div class="maxcontainer" *ngIf="state$ | async as state">
		<button *ngIf="hasMenu() && state.show" mat-icon-button (click)="toggleMenu()"><mat-icon>menu</mat-icon></button>
		<a *ngIf="!hasMenu() || !state.show" class="logo space" [routerLink]="['info']"><img style="max-height: 60px;" src="{{ event.logo }}" /></a>
		<p *ngIf="isArchive">Viewing {{ event.name }}</p>
		<span *ngIf="!state.show" class="space"></span>
		<div class="tabs" *ngIf="!state.show">
			<a class="space" *ngFor="let m of menus" #rla="routerLinkActive" [disabled]="rla.isActive" [ngStyle]="{'pointer-events': rla.isActive ? 'none' : null}" routerLinkActive="active-border" routerLink="{{ m.href }}">{{m.name}}</a>
			<a class="space" *ngIf="AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR) || AuthService.HasPermission(authGroups.VENDOR)" routerLinkActive="active-border" [routerLink]="['admin']">Admin</a>
		</div>
		<div class="mobile-menu" *ngIf="isMobile$ | async">
			<mat-menu #mobileMenu class="mobile-menu">
				<button mat-menu-item *ngFor="let m of menus" (click)="navigateTo(m.href)">{{m.name}}</button>
				<button mat-menu-item *ngIf="AuthService.HasPermission(authGroups.SUPPLIER_COORDINATOR) || AuthService.HasPermission(authGroups.VENDOR)" (click)="navigateTo('admin')">Admin</button>
			</mat-menu>
			<button mat-button [matMenuTriggerFor]="mobileMenu">{{currentRoute}}<mat-icon>arrow_drop_down</mat-icon></button>
		</div>
		<mat-menu #appMenu="matMenu">
		  <button mat-menu-item [routerLink]="['personal']">Personal Info</button>
		  <button mat-menu-item [routerLink]="['company']">Company Info</button>
	      <button mat-menu-item *ngIf="AuthService.HasPermission(authGroups.SUPPLIER_PRIMARY_CONTACT) && !shipOnly && contractStatus != 'Exempt'" [routerLink]="['contract']">Contract</button>
		  <button mat-menu-item (click)="logout()">Logout</button>
		</mat-menu>
		<button class="account-menu" mat-icon-button [matMenuTriggerFor]="appMenu">
		  <mat-icon>account_circle</mat-icon>
		</button>
	</div>
</mat-toolbar>
<div class="root-app" [class.app-mobile]="isMobile$ | async">
	<router-outlet></router-outlet>
</div>
