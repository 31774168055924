import { ShipmentService } from '../../../common/services/shipment.service';
import { CompanyService } from '../../../common/services/company.service';
import { DepartmentService } from '../../../common/services/department.service';
import { Resolve } from '@angular/router';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, of} from 'rxjs';
import { map } from 'rxjs/operators';
import { Manifest } from '@models/manifest';
import { ManifestService } from '@common/services/manifest.service';
import { AuthService } from '@common/services/auth.service';
import { ShippingDestinationService } from '@common/services/shipping-destination.service';

@Injectable()
export class ContactInfoResolve implements Resolve<any> {

	constructor(
		public ss: ShipmentService,
		public cs: CompanyService,
		public ds: DepartmentService,
		public ms: ManifestService,
		public AuthService: AuthService,
		public ShippingDestinationService: ShippingDestinationService
	){}

	resolve(route, state){
		return forkJoin({
			manifest: route.parent.params.id !== 'new' ? this.ms.get(route.parent.params.id) : of(new Manifest()),
			destinations: this.ShippingDestinationService.getForCompany(this.AuthService.getUser()['company_id'])
        })
        // .pipe(map(all => {
		// 	return {
		// 		manifest: all[0],
		// 		destinations: all[1]
		// 	}
		// }));
	}
}
