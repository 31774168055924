import { Component, OnInit } from '@angular/core';
import { Menu } from '../../../models/menu';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MenuService } from '../../../common/services/menu.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../../common/services/app.service';
import { log } from '../../../common/log/log';

@Component({
	selector: 'app-edit-menu',
	templateUrl: './edit-menu.component.html',
	styleUrls: ['./edit-menu.component.css']
})
export class EditMenuComponent implements OnInit {

	public menu: FormGroup;

	constructor(
		private FormBuilder: FormBuilder,
		public Location: Location,
        private MenuService: MenuService,
		private MatSnackBar: MatSnackBar,
		private Router: Router,
		private ActivatedRoute: ActivatedRoute,
		private appService: AppService
	) { }

	ngOnInit() {

		this.menu = this.FormBuilder.group(new Menu(), {validators: [Validators.required]});
		this.ActivatedRoute.params.subscribe(data => {
			this.MenuService.get(data['id']).subscribe(r => {
				this.menu = this.FormBuilder.group(r);
				log.Debug(r);
			});
		});
	}

	save() {
		this.MenuService.save(this.menu.value).subscribe(r => {
			this.MatSnackBar.open("Menu Saved!", null, { duration: 3000 });
			this.appService.navigate(["/", 'app', 'admin', 'menus']);
		});
	}

}
