import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GroupService } from '../../../common/services/group.service';
import { DepartmentService } from '../../../common/services/department.service';
import { UserService } from '../../../common/services/user.service';
import { Department } from '../../../models/department';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { InvoiceTemplateService } from '@common/services/invoice-template.service';
import { AppService } from '../../../common/services/app.service';

@Component({
	selector: 'app-add-department',
	templateUrl: './add-department.component.html',
	styleUrls: ['./add-department.component.css']
})
export class AddDepartmentComponent implements OnInit {

	groups;
	coordinators;
	invoice_templates;
	form: FormGroup;

	constructor(
		private gs: GroupService,
		private fb: FormBuilder,
		private ds: DepartmentService,
		private sb: MatSnackBar,
		private router: Router,
		public location: Location,
		private UserService: UserService,
		private InvoiceTemplateService: InvoiceTemplateService,
		private appService: AppService
	) { }

	ngOnInit() {
		this.InvoiceTemplateService.all().subscribe(r => {
			this.invoice_templates = r;
		});
		let dep = new Department();
		this.form = this.fb.group(dep);
		this.gs.all().subscribe(r => {
			this.groups = r;
		});

	    this.UserService.getCoordinators().subscribe(r => {
	      this.coordinators = r;
	    });
	}

	save(){
		this.ds.save(this.form.value).subscribe(r => {
			this.sb.open("Department Added!",null ,{duration: 3000});
			this.appService.navigate(['/', 'app', 'admin', 'departments']);
		});
	}

}
