import { Model } from './model';

export class ShippingDestination extends Model {

	public name: string = null
	public address_id: number = null
   public inbound_lock: Date = null
   public outbound_lock: Date = null
   public receiving_hours: string = null
   public contact_name: string = null
   public contact_email: string = null
   public contact_phone: string = null
   public country_id: number = 1
   public ship_company: string = null
   public address_1: string = null
   public address_2: string = null
   public address_3: string = null
   public city: string = null
   public state_id: string = null
   public postcode: string = null
   public custom_lock_dates: boolean = false
   public active: boolean = false
   public other_state: string = null
   public other_country: string = null

   constructor(properties?: Object) {
      super(properties);
      this.patch(properties)
   }
}

