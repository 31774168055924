import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { USERS } from '../../models/example-data/fake_users';
import { User } from '../../models/user';
import { environment } from '../../../environments/environment';
import { log } from '@common/log/log';
import { switchMap, map, filter } from 'rxjs/operators';
import { Menu } from '../../models/menu';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    constructor(
        private http: HttpClient,
        private AppService: AppService
    ){}

    getAll(){
        return this.http.get(environment.api + "/menus/all_by_event_id");
    }

    getAllActive(){
        return this.http.get(environment.api + "/menus/all_by_event_id").pipe(
            this.AppService.isActiveFilter()
        );
    }

    getAllSorted(){
        return this.http.get(environment.api + "/menus/all_sorted")
    }

    getUserMenus(){
        return this.http.get(environment.api + "/menus/for_logged_in_user");
    }

    getAllActiveSorted(){
        return this.http.get(environment.api + "/menus/all_sorted").pipe(
            this.AppService.isActiveFilter()
        );
    }

    filter(params){
        return this.http.get(environment.api + "/menus/filter", {params: params});
    }

    get(id){
        return this.http.get(environment.api + "/menus/by_id/" + id);
    }
    save(data){
        return data.id ? this.update(data) : this.create(data);
    }

    create(data){
        return this.http.post(environment.api + "/menus/create_with_event_id", data)
    }

    update(data) {
        data._exists = true;
        return this.http.put(environment.api + "/menus/update/" + data.id, data);
    }

    delete(id){
        return this.http.delete(environment.api + "/menus/delete/" + id);
    }
    updateAll(data) {
        return this.http.post(environment.api + "/menus/update_all", data);
    }
}
