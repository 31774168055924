<div class="container-fluid">
	<div class="row">
		<div id="print-section" class="col">
			<div id="subcontent" style="font-size:12pt;">
                
    <h2>Shipping Instructions</h2>
<div class="clearall"></div>
<hr><br>
<div class="box">    
<h2><strong>To create a new inbound shipment</strong></h2>         
<p>When you receive the shipping notification email from your supplier coordinator follow the instructions below. Shipping notifications will be distributed in late January 2020.</p>    
<p>Go to the <strong>SHIPPING/Information</strong> tab and select <span style="color: #ffffff; background-color: #009966; padding: 3px 5px;">Ready to Begin?</span> or click on the <span style="font-weight: bold;">SHIPPING/</span><strong>New Inbound Shipment</strong> tab.</p>  </div>
<div class="box">         
<h2><span style="color: red;">Step 1:</span> Shipment Contact Information </h2>         
<p>Enter shipment contact name, mobile phone number and email address. This information will appear on your shipping manifest and label. This is the person we will contact if there is an issue with your delivery.</p>         
<p>Select destination for this shipment per the email instructions from your supplier coordinator</p>         
<p>Click <span style="color: #ff0000;">Save</span></p></div>
<div class="clearall"></div>
<div class="box">         
<h2><span style="color: red;">Step 2:</span> Shipment Schedule </h2>         
<ul>                 
<li>Verify receiving details for the destination you selected (listed on this page)</li>                 
<li>Enter estimated ship date</li>                 
<li>Enter estimated delivery date (no weekend deliveries)</li>                 
<li>Check the box if your shipment is coming through customs (Go to the <span style="font-weight: bold;">SHIPPING/Instructions</span> tab for more information if your shipment is originating outside the U.S.)</li>                 
<li>Click <span style="color: #ff0000;">Save and Continue</span></li>    </ul></div>
<div class="clearall"></div>
<div class="box">         
<h2><span style="color: red;">Step 3:</span> Download an Excel manifest form for completion and save in your files </h2>         
<ul>                 
<li>Note the unique manifest number assigned to this shipment in the white box</li>                 
<li>Click Download Manifest Entry Form</li>                 
<li>An official Product Walk Excel manifest form will be available for your completion</li>                 
<li>Populate the form with the required details:</li>                 
<li>All fields must be completed to be accepted by Productwalk.com</li>                 
<li>Do not use decimal points, dollar signs or fractions</li>                 
<li>If your item doesn’t have a SKU number (fixture, prototype, etc.) enter 000000</li>    </ul>         
<p>Follow the instructions in the green bar at the top of the form for saving the completed form to your files. Suggested format: ShippingManifestForm2020_DXX.ABC_QQQQ (DXX is your department number, ABC is the name of your company; QQQQ is the manifest number listed in the top portion of the form)</p></div>
<div class="clearall"></div>
<div class="box">         
<h2><span style="color: red;">Step 4:</span> Upload the completed manifest form </h2>         
<ul>                 
<li>Return to the website to upload at any time</li>                 
<li>Navigate to the Upload Manifest page</li>                 
<li>Select the shipment number that matches the number on your manifest form</li>                 
<li>Use <span style="color: #ff0000;">Browse</span> to select the form from your document files</li>                 
<li>Click <span style="color: #006633;">Upload Manifest</span></li>                 
<li>Wait for the <span style="color: #ff0000;">UPLOAD SUCCESSFUL</span> message</li>                 
<li>If your manifest doesn’t upload, check for <span style="color: #ff0000;">error messages</span> displayed at the top of the page.</li>                 
<li>Correct your manifest and try again</li>                 
<li>Click <span style="color: #ff0000;">Continue</span></li>    </ul></div>
<div class="clearall"></div>
<div class="box">         
<h2><span style="color: red;">Step 5:</span> Verify/Make Changes </h2>         
<ul>                 
<li>Return to the website at any time</li>                 
<li>Navigate to the View Shipments page</li>                 
<li>Select the shipment number you want</li>                 
<li>From the Shipment Manifest page, click <span style="color: #ff0000;">Make Changes</span></li>                 
<li>Follow the instructions on the next page</li>    </ul></div>
<div class="clearall"></div>
<div class="box">         
<h2><span style="color: red;">Step 6:</span> Print or Save Manifest or Labels </h2>         
<ul>                 
<li>Navigate to the View Shipments page</li>                 
<li>Select the shipment number you want</li>                 
<li>From the Shipment Manifest page, click View/Print/Save Labels or View/Print/Save Manifest</li>                 
<li>You will be directed to a printable web view of the label or manifest. You have the option of printing or creating a PDF of the online label(s) or online manifest.</li>                 
<li>Every container must be labeled on multiple sides and the manifest form must be visible on the outside of the container</li>                 
<li>Keep a copy of the manifest form for your records</li>
<li><span style="text-decoration: underline;">NOTE: Our shipping address for the Advance Warehouse has changed</span>.&nbsp; Please DO NOT plan to send to the same location as in previous years.&nbsp;&nbsp;</li>    </ul></div><br>
<div class="box" style="font-size: 13.3333px;">
<h2><span style="color: red;">Step 7:</span>&nbsp;Save Tracking Information</h2>
<ul>
<li><span style="font-size: 10pt;">Return to the website after your items have shipped</span></li>
<li><span style="font-size: 10pt;">Navigate to the View Shipments page</span></li>
<li><span style="font-size: 10pt;">Find the appropriate shipment number</span></li>
<li><span style="font-size: 10pt;">Scroll to the right and add your tracking information</span></li>
<li><span style="font-size: 10pt;">Click outside the box.&nbsp; It will save your information</span></li></ul></div><br style="font-size: 13.3333px;">
	<button class="no-print" style="margin-left: 10px;" type="button" mat-raised-button color="primary" (click)="print()">Print <mat-icon>print</mat-icon></button>
                <br style="clear: both">
            </div>
		</div>
	</div>
	<br>
</div>