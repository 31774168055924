import { Model } from './model';


export class Country extends Model {
	short_name: string;
	long_name: string;

	constructor(properties?: Object) {
        super(properties)
		this.patch(properties)
    }
}
