import { EventService } from '../common/services/event.service';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError, of, shareReplay, take } from 'rxjs';

@Injectable()
export class EventResolve {

	constructor(
		public EventService: EventService
	){}

	resolve(){
		return this.EventService.GetEvent().pipe(
      take(1),
      catchError(err => of([])),
      shareReplay(1)
    );
	}

}
