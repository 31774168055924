import {Model} from './model';

export class Menu extends Model {
	name: string = null;
    menu_order: number = null;
    href: string = null;
    allowed_role: string = null;
    active: boolean = null;

    constructor(properties?: Object) {
        super(properties)
        this.patch(properties)
    }
}