
<span *ngIf="!uploaded && !uploading">
  <pgl-file-drop (onDrop)="upload($event)" (click)="file.click()"></pgl-file-drop>
  <input type="file" #file style="display:none" (change)="upload($event)" />
</span>
<mat-spinner *ngIf="uploading" style="margin:auto"></mat-spinner>
<mat-spinner *ngIf="validating" style="margin:auto"></mat-spinner>
<span *ngIf="uploaded" style="height: 600px; overflow:auto">
Upload Successful, please review before updating attendees.
<table mat-table [dataSource]="results" class="table gutters">
<ng-container matColumnDef="department_label">
  <th mat-header-cell *matHeaderCellDef>Department Label</th>
  <td mat-cell *matCellDef="let element">{{element.department_label}}</td>
</ng-container>
<ng-container matColumnDef="company_name">
  <th mat-header-cell *matHeaderCellDef>Company Name</th>
  <td mat-cell *matCellDef="let element">{{element.company_name}}</td>
</ng-container>
<ng-container matColumnDef="company_id">
  <th mat-header-cell *matHeaderCellDef>Company ID</th>
  <td mat-cell *matCellDef="let element">{{element.company_id}}</td>
</ng-container>
<ng-container matColumnDef="allowed_attendees">
  <th mat-header-cell *matHeaderCellDef>Allowed Attendees</th>
  <td mat-cell *matCellDef="let element">{{element.allowed_attendees}}</td>
</ng-container>
<!-- <ng-container matColumnDef="errors">
  <th mat-header-cell *matHeaderCellDef>Errors</th>
  <td mat-cell *matCellDef="let element; let i = index"><mat-icon *ngIf="errors[i]">warning</mat-icon>
    <ng-container *ngFor="let err of errors[i]">
      {{err}}<br>
    </ng-container>
  </td>
</ng-container> -->
<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
 <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<!-- <button mat-raised-button (click)="validate()">Validate</button> -->
<button mat-raised-button color="accent" (click)="updateAttendees()">Confirm</button>
<!-- <button mat-raised-button (click)="clear()">Restart</button> -->
</span>
