<div class="container-fluid">
	<div class="row gutters" >
        <mat-expansion-panel [expanded]="true" [hideToggle]="false" class="col">
            <mat-expansion-panel-header>
                <mat-panel-title>Filter Suppliers</mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <form [formGroup]="filterForm">
            	<div class="row gutters">
                	<div class="col">
                		<mat-form-field>
                			<mat-label>Company</mat-label>
                			<input type="text" formControlName="company_name" matInput placeholder="Company" />
                		</mat-form-field>
                	</div>
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Department</mat-label>
                            <mat-select formControlName="department_id">
                                <mat-option>
                                    <ngx-mat-select-search 
                                        [formControl]="departmentFilterCtrl"
                                        [placeholderLabel]="'Find department...'" 
                                        [noEntriesFoundLabel]="'no matching department found'">
                                        <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let d of filteredDepartments" [value]=d.id>{{d.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Contact Name</mat-label>
                            <input type="text" formControlName="contact_name" matInput placeholder="Contact Name" />
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Supplier Type</mat-label>
                            <mat-select formControlName="supplier_type">
                                <mat-option [value]="null">All</mat-option>
                                <mat-option [value]="1">Primary</mat-option>
                                <mat-option [value]="2">Other</mat-option>
                                <mat-option [value]="3">Default</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row gutters">
                    <div class="col">
                        <mat-form-field>
                            <mat-label >Company Active</mat-label>
                            <mat-select formControlName="company_active">
                                <mat-option [value]="null">All</mat-option>
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Group</mat-label>
                            <mat-select formControlName="group_id">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let g of groups" [value]=g.id>{{g.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col">
                    	<mat-form-field>
                    		<mat-label>Contract Status</mat-label>
                    		<mat-select formControlName="contract_status_id">
                    			<mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let c of contractStatuses" [value]=c.id>{{c.name}}</mat-option>
                    		</mat-select>
                    	</mat-form-field>
                    </div>
                </div>
                <div class="row gutters">
                	<div class="col">
                        <mat-form-field>
                            <mat-label >Ship Only</mat-label>
                            <mat-select formControlName="ship_only">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option [value]=true>Yes</mat-option>
                                <mat-option [value]=false>No</mat-option>
                            </mat-select>
                        </mat-form-field>
                	</div>
                	<div class="col">
                        <mat-form-field>
                            <mat-label>Role</mat-label>
                            <mat-select formControlName="role">
                                <mat-option [value]=null>All</mat-option>
                                <mat-option *ngFor="let r of roles" [value]=r.name>{{r.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Account Set Up</mat-label>
                            <mat-select formControlName="account_set_up">
                                <mat-option [value]="null">All</mat-option>
                                <mat-option [value]="1">Yes</mat-option>
                                <mat-option [value]="0">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <button mat-raised-button color="primary" (click)="reset()">Reset Filters</button>
                <button style="margin-left: 10px;" mat-raised-button color="primary" (click)="ExportTOExcel()">Export as .xls</button>
            </form>
        </mat-expansion-panel>
    </div>
</div>
<div class="row">
    <div class="col" #TABLE >
        <table mat-table #table [dataSource]="suppliers" matSort class="table mat-elevation-z7 gutters sticky-header">
              <ng-container matColumnDef="department">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Dept.</th>
                <td mat-cell data-label="Department" *matCellDef="let element;">
                    {{element.department_label}}
                </td>
              </ng-container>
              <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                <td mat-cell data-label="Company" *matCellDef="let element; let i = index;">
                    {{element.company}}
                </td>
              </ng-container>
              <ng-container matColumnDef="contact_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact Name</th>
                <td mat-cell data-label="Contact Name" *matCellDef="let element;">
                    {{element.first_name}} {{element.last_name}}
                </td>
              </ng-container>
              <ng-container matColumnDef="company_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Type</th>
                <td mat-cell data-label="Company Type" *matCellDef="let element;">
                    {{element.company_type}}
                </td>
              </ng-container>
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact Type</th>
                <td mat-cell data-label="Role" *matCellDef="let element;">
                    <span *ngIf="element.role">{{ element.role }}</span>
                    <span *ngIf="!element.role">  -- </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell data-label="Email" *matCellDef="let element;">
                    <span *ngIf="element.email">{{ element.email }}</span>
                    <span *ngIf="!element.email">{{ element.default_email }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="contract_status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract Status</th>
                <td mat-cell data-label="Contract Status" *matCellDef="let element;">
                    {{element.contract_status}}
                </td>
              </ng-container>
              <ng-container matColumnDef="is_primary">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Supplier Type</th>
                <td mat-cell data-label="Supplier Type" *matCellDef="let element;">
                    <span *ngIf="element.is_primary != null">
                        <span *ngIf="element.is_primary">Primary</span>
                        <span *ngIf="!element.is_primary">Other</span>
                    </span>
                    <span *ngIf="element.is_primary == null">Default</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="participation_fee">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract Amount</th>
                <td mat-cell data-label="Contract Amount" *matCellDef="let element;">
                    <span *ngIf="element.participation_fee">
                        {{element.participation_fee | currency:'USD':'symbol'}}
                    </span>
                    <span *ngIf="!element.participation_fee">
                        --
                    </span>
                </td>
              </ng-container>
             <ng-container matColumnDef="ship_only">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Ship Only
                </th>
                <td mat-cell data-label="Ship Only" *matCellDef="let element;">
                    <mat-icon *ngIf="element.ship_only">
                        done
                    </mat-icon>
                    <mat-icon color="accent" *ngIf="!element.ship_only">
                        block
                    </mat-icon>
                </td>
             </ng-container> 
             <ng-container matColumnDef="first_time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Account Set Up
                </th>
                <td mat-cell data-label="account_setup" *matCellDef="let element;">
                    <mat-icon *ngIf="!element.first_time">
                        done
                    </mat-icon>
                    <mat-icon color="accent" *ngIf="element.first_time">
                        block
                    </mat-icon>
                </td>
             </ng-container> 
             <ng-container matColumnDef="company_active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Is Active
                </th>
                <td mat-cell data-label="company_active" *matCellDef="let element;">
                    <mat-icon *ngIf="element.company_active">
                        done
                    </mat-icon>
                    <mat-icon color="accent" *ngIf="!element.company_active">
                        block
                    </mat-icon>
                </td>
             </ng-container> 
             <ng-container matColumnDef="onsite_reps_allowed">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Attendees Allowed
                </th>
                <td mat-cell data-label="Attendees Allowed" *matCellDef="let element;">
                    {{element.onsite_reps_allowed}}
                </td>
             </ng-container>
             <ng-container matColumnDef="attendees_registered">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Attendees Registered
                </th>
                <td mat-cell data-label="Attendees Registered" *matCellDef="let element;">
                    <span *ngIf="element.attendees_registered">{{ element.attendees_registered }}</span>
                    <span *ngIf="!element.attendees_registered"> -- </span>
                </td>
             </ng-container>
             <ng-container matColumnDef="notes">
                <th class="note-container" mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">
                    Notes
                </th>
                <td class="note-container" mat-cell data-label="Notes" *matCellDef="let element" [ngClass]="'customWidthClass'">
                    <div class="note-text-wrap"><p class="note-text">{{ element.notes }}</p></div>
                </td>
             </ng-container>

             <ng-container matColumnDef="user_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
                <td mat-cell data-label="Contract Amount" *matCellDef="let element;">
                    <span *ngIf="element.user_name">{{ element.user_name }}</span>
                    <span *ngIf="!element.user_name"> -- </span>
                </td>
              </ng-container>

             <ng-container matColumnDef="edit_company_user">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell data-label="Actions" *matCellDef="let element">
                    <a matTooltip="Edit User: {{ element.user_name }}" *ngIf="element.user_id" mat-icon-button color="primary" [routerLink]="['edit-user', element.user_id]"><mat-icon>person</mat-icon></a>
                    <a matTooltip="Edit Company: {{ element.company }}" *ngIf="element.company_id" mat-icon-button color="primary" [routerLink]="['edit-company', element.company_id]"><mat-icon>location_city</mat-icon></a>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
    </div>
</div>
