<form (ngSubmit)="next()" [formGroup]="form">
    <span *ngIf="newEntry">Step 1 of 3</span>

    <mat-form-field *ngIf="currentRole().includes('admin') || currentRole().includes('coordinator')">
        <mat-label>Company</mat-label>
        <mat-select formControlName="company_id" (selectionChange)="destinationsByCompanyID($event)" required>
            <mat-option>
                <ngx-mat-select-search
                    [formControl]="companyFilterCtrl"
                    [placeholderLabel]="'Select company...'"
                    [noEntriesFoundLabel]="'no matching company found'">
                    <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let c of filteredCompanies" [value]=c.id>{{c.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('company_id').hasError('required')">This is a required field</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Shipping Contact Name</mat-label>
        <input matInput formControlName="shipping_contact_name" required>
        <mat-error *ngIf="form.get('shipping_contact_name').hasError('required')">This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Contact Mobile Phone</mat-label>
        <input matInput type="text" mask="(000-000-0000)" [dropSpecialCharacters]="['(', ')']" formControlName="shipping_contact_phone" required>
        <mat-hint>Format should be (xxx-xxx-xxxx)</mat-hint>
        <mat-error *ngIf="form.get('shipping_contact_phone').hasError('required')">This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Contact Email</mat-label>
        <input matInput type="email" formControlName="shipping_contact_email" email required>
        <mat-error *ngIf="form.get('shipping_contact_email').hasError('required')">This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Brand Name(s)</mat-label>
        <input matInput formControlName="brand" required>
        <mat-error *ngIf="form.get('brand').hasError('required')">This is a required field</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Destination</mat-label>
        <mat-select formControlName="shipping_destination_id" name="shipping_destination_id" required>
            <mat-option *ngFor="let d of destinations" [value]="d.id">
                {{d.name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('shipping_destination_id').hasError('required')">This is a required field</mat-error>
    </mat-form-field>
    <mat-checkbox formControlName="is_customs_shipment">Check this if your shipment will go through customs</mat-checkbox><br>
    <span *ngIf="!hasDestinations()" class="warning" >No shipping destinations are available for your company, please contact your supplier coordinator.</span>
    <div mat-card-actions class="right">
        <a *ngIf="!newEntry" [routerLink]="['../schedule']" mat-raised-button color="accent">No Changes</a>
        <button [disabled]="form.invalid" *ngIf="hasDestinations() && !newEntry" mat-raised-button color="accent" class="gutters no-bottom">Save</button>
        <button [disabled]="form.invalid" *ngIf="hasDestinations() && newEntry" mat-raised-button color="accent" class="gutters no-bottom">Next</button>
    </div>
</form>
