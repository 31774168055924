<div style="min-height:calc(100vh - 120px);" class="container-fluid">
	<div class="row">
		<div class="col">
			<div *ngIf="page" [innerHTML]="page.content | safe: 'html'"></div>
		</div>
	</div>
	<div class="row" *ngIf="isAdmin()">
		<div class="col">
			<mat-form-field>
				<mat-label>Filter Shipments by Company</mat-label>
				<mat-select name="company">
					<mat-option value="1">Eyesore Inc</mat-option>
					<mat-option value="2">Hartmann Studios</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<table mat-table [dataSource]="forms" class="table mat-elevation-z7 gutters">
					<ng-container matColumnDef="manifest_number">
							<th mat-header-cell *matHeaderCellDef> Manifest Number </th>
							<td mat-cell data-label="Manifest Number" *matCellDef="let element"> {{element.manifest_number}} </td>
						  </ng-container>
					   
						  <ng-container matColumnDef="shipping_contact_name">
							 <th mat-header-cell *matHeaderCellDef> Contact Name </th>
							 <td mat-cell data-label="Contact Name" *matCellDef="let element"> {{element.shipping_contact_name}} </td>
						   </ng-container>
					   
						  <ng-container matColumnDef="shipping_contact_email">
							<th mat-header-cell *matHeaderCellDef> Contact Email </th>
							<td mat-cell data-label="Contact Email" *matCellDef="let element"> {{element.shipping_contact_email}} </td>
						  </ng-container>
						  
						  <ng-container matColumnDef="shipping_contact_phone">
							 <th mat-header-cell *matHeaderCellDef> Contact Phone </th>
							 <td mat-cell data-label="Contact Phone" *matCellDef="let element"> {{element.shipping_contact_phone}} </td>
						   </ng-container>
					   
						   <ng-container matColumnDef="brand">
							 <th mat-header-cell *matHeaderCellDef> Brand(s) </th>
							 <td mat-cell data-label="Brand" *matCellDef="let element"> {{element.brand}} </td>
						   </ng-container>
					   
						   <ng-container matColumnDef="ship_date">
							 <th mat-header-cell *matHeaderCellDef> Ship Date </th>
							 <td mat-cell data-label="Ship Date" *matCellDef="let element"> {{element.ship_date | date}} </td>
						   </ng-container>
					   
						   <ng-container matColumnDef="arrive_date">
							 <th mat-header-cell *matHeaderCellDef> Arrival Date </th>
							 <td mat-cell data-label="Arrival Date" *matCellDef="let element"> {{element.arrive_date | date}} </td>
						   </ng-container>
						 
				  <ng-container matColumnDef="actions">
				  	<th mat-header-cell *matHeaderCellDef>Actions</th>
				  	<td mat-cell data-label="Actions" *matCellDef="let element; let i = index">
				  		<button mat-icon-button color="primary" (click)="uploadingFor = element.id; file.click()"><mat-icon>cloud_upload</mat-icon></button>
				  	</td>
				  </ng-container>
				  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

			</table>
			<input type="file" #file style="display:none" (change)="upload($event)" />
		</div>
	</div>
</div>