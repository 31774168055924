<div class="container-fluid">
	<h1>Edit Company</h1>
 	<form [formGroup]="form" (submit)="save()">
		<div class="row gutters">
			<div class="col">
				<mat-form-field>
					<input formControlName="name" matInput name="name" placeholder="Name" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
				</mat-form-field>
			</div>
			<div class="col">
				<mat-form-field>
					<mat-label>Company Type</mat-label>
					<mat-select formControlName="company_type_id">
						<mat-option *ngFor="let c of companyTypes" [value]=c.id>{{c.name}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="row gutters">
			<div class="col">
				<mat-form-field>
					<input formControlName="invitation_code" matInput name="code" placeholder="Invitation Code" />
				</mat-form-field>
			</div>
			<div class="col" *ngIf="canEditFees()">
				<mat-form-field>
					<input type="number" formControlName="participation_fee" matInput name="fee" placeholder="Participation Fee" />
				</mat-form-field>
			</div>
			<div class="col">
				<mat-form-field>
					<mat-label>Override Invoice Template</mat-label>
					<mat-select formControlName="invoice_template_id" name="template">
						<mat-option *ngFor="let it of invoice_templates" [value]=it.id>{{it.name}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col">
				<mat-checkbox formControlName="hide_invoice" name="hide_invoice">
					Hide Invoice
				</mat-checkbox>
			</div>
		</div>
		<div class="row gutters">
			<div class="col">
				<mat-form-field>
					<mat-label>Contract Status</mat-label>
					<mat-select formControlName="contract_status_id" name="contract" required>
						<mat-option *ngFor="let s of statuses" [value]=s.id>{{s.name}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col">
				<mat-form-field>
					<input matInput formControlName="contact_email" placeholder="Contact Email" readonly onfocus="this.removeAttribute('readonly');" />
				</mat-form-field>
			</div>
			<div class="col">
				<mat-form-field>
					<input matInput formControlName="contact_name" placeholder="Contact Name" />
				</mat-form-field>
			</div>
		</div>
		<div class="row gutters">
			<div class="col">
				<mat-form-field>
					<textarea matInput formControlName="notes" placeholder="Payment Notes"></textarea>
				</mat-form-field>
			</div>
			<div class="col">
				<mat-form-field>
					<input type="number" formControlName="onsite_reps_allowed" matInput name="supplierReps" placeholder="Supplier Reps" />
				</mat-form-field>
			</div>
		</div>
		<div class="row gutters">
			<div class="col">
				<mat-form-field>
					<mat-label>Department</mat-label>
					<mat-select formControlName="department_id">
						<mat-option *ngFor="let d of departments" [value]=d.id>{{d.name}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col">
				<mat-form-field>
					<input formControlName="pvendor_number" matInput name="pvendor_number" placeholder="PVendor Number" autocomplete="off"/>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-checkbox formControlName="active">Active</mat-checkbox>
			</div>
			<div class="col">
				<mat-checkbox formControlName="ship_only">Ship Only</mat-checkbox>
			</div>
		</div>
		<div class="row">
			<div class="col-md" [formGroup]="billing">
				<mat-card appearance="outlined">
					<mat-card-header>
						<mat-card-title>Billing Address</mat-card-title>
					</mat-card-header>
					<mat-card-content>
							<mat-form-field>
								<mat-label>Country</mat-label>
								<mat-select formControlName="country_id" name="country">
									<mat-option  *ngFor="let country of countries" [value]=country.id>{{country.long_name}}</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-form-field *ngIf="billingCountry == 4">
								<input matInput formControlName="other_country" name="other_country" placeholder="Other Country" />
							</mat-form-field>
							<mat-form-field>
								<input matInput formControlName="address_1" name="address_1" placeholder="Address 1" />
							</mat-form-field>
							<mat-form-field>
								<input matInput formControlName="address_2" name="address_2" placeholder="Address 2" />
							</mat-form-field>
							<mat-form-field>
								<input matInput formControlName="address_3" name="address_3" placeholder="Address 3" />
							</mat-form-field>
							<mat-form-field>
								<input matInput formControlName="city" name="city" placeholder="City" />
							</mat-form-field>
							<mat-form-field *ngIf="billingCountry == 1">
								<mat-label>State</mat-label>
								<mat-select formControlName="state_id" name="state">
									<mat-option *ngFor="let state of states" [value]=state.id>{{state.long_name}}</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-form-field *ngIf="billingCountry > 1">
								<input matInput formControlName="other_state" name="other_state" placeholder="State" />
							</mat-form-field>
							<mat-form-field>
								<input matInput formControlName="postal_code" name="postal_code" placeholder="Postal Code" />
							</mat-form-field>
					</mat-card-content>
				</mat-card>
			</div>
			<div class="col-md" [formGroup]="mailing">
				<mat-card appearance="outlined">
					<mat-card-header>
						<mat-card-title>Mailing Address</mat-card-title>
					</mat-card-header>
					<div class="row">
						<div class="col">
							<mat-checkbox [checked]="sameAsBilling" (change)="sameAsBilling = !sameAsBilling">Same as Billing Address</mat-checkbox>
						</div>
					</div>
					<mat-card-content *ngIf="!sameAsBilling">
						<br>
						<mat-form-field>
							<mat-label>Country</mat-label>
							<mat-select formControlName="country_id" name="country">
								<mat-option *ngFor="let country of countries" [value]=country.id>{{country.long_name}}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field *ngIf="mailingCountry == 4">
							<input matInput formControlName="other_country" name="other_country" placeholder="Other Country" />
						</mat-form-field>
						<mat-form-field>
							<input matInput formControlName="address_1" name="address_1" placeholder="Address 1" />
						</mat-form-field>
						<mat-form-field>
							<input matInput formControlName="address_2" name="address_2" placeholder="Address 2" />
						</mat-form-field>
						<mat-form-field>
							<input matInput formControlName="address_3" name="address_3" placeholder="Address 3" />
						</mat-form-field>
						<mat-form-field>
							<input matInput formControlName="city" name="city" placeholder="City" />
						</mat-form-field>
						<mat-form-field *ngIf="mailingCountry == 1">
							<mat-label>State</mat-label>
							<mat-select formControlName="state_id" name="state">
								<mat-option *ngFor="let state of states" [value]=state.id>{{state.long_name}}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field *ngIf="mailingCountry > 1">
							<input matInput formControlName="other_state" name="other_state" placeholder="State" />
						</mat-form-field>
						<mat-form-field>
							<input matInput formControlName="postal_code" name="post_code" placeholder="Postal Code" />
						</mat-form-field>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
		<div class="row" id="shipping_destinations">
			<div class="col" formArrayName="shipping_destinations">
				<h4>Choose Shipping Destinations</h4>
				<div class="row"  *ngFor="let sd of shippingDestinations; let i = index" [formGroupName]="i" >
					<div class="col">
						<mat-checkbox formControlName="selected" [value]="sd.id">{{sd.name}}</mat-checkbox>
					</div>
				</div>
			</div>
            <div class="col">
                <mat-form-field>
                    <mat-label>Choose Shipping Departments if different</mat-label>
                    <mat-select formControlName="shipping_department_id">
                        <ng-container *ngFor="let sd of shippingDepartments$ | async">
                            <mat-option [value]="sd.id">{{sd.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
		</div>

		<button mat-raised-button color="primary" type="submit">Submit</button>
		<a style="margin-left: 5px;" color="primary" mat-raised-button (click)="this.location.back();">Cancel</a>
		<button type="button" mat-raised-button color="accent" (click)="downloadXLS()">Download XLS Invoice</button>
		<button type="button" mat-raised-button color="accent" (click)="downloadPDF()">Download PDF Invoice</button>
		<button type="button" mat-raised-button (click)="openInvoices()">Manage Custom Invoices</button>
	</form>
    <div style="margin-top: 30px;" *ngIf="invites !== false">
        <h4>Sent Invites</h4>
        <div class="row">
            <div class="col" #TABLE>
                <table mat-table #table [dataSource]="invites" matSort class="table mat-elevation-z7 gutters" matSortActive="invite_sent" matSortDirection="desc" matSortDisableClear>

                    <ng-container matColumnDef="invite_sent">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Invites Sent </th>
                        <td mat-cell data-label="Invite" *matCellDef="let element"> {{element.invite_sent | date:'short'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="to_email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> To </th>
                        <td mat-cell data-label="Invite" *matCellDef="let element"> {{element.to_email}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
