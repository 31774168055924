<div class="container-fluid">
    <div class="row">
        <div class="col">
            <mat-card appearance="outlined">
                <mat-card-header>
                    <mat-card-title *ngIf="event.is_registration_closed">
                            Registration is now closed. Please contact your supplier coordinator for changes.
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p [innerHTML]="registrationText" style="font-size: 16px; padding-top: 10px;"></p>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <a *ngIf="!event.is_registration_closed" (click)="newRegistrationClickHandler()" color="accent" mat-mini-fab class="extended gutters"><mat-icon>add</mat-icon>New Registration</a>
    </div>
    <div class="row" >
        <mat-expansion-panel [hideToggle]="false" class="full-width">
            <mat-expansion-panel-header>
                <mat-panel-title>Search</mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <form [formGroup]="form">
              <div class="row">
                  <div class="col">
                    <mat-form-field>
                      <input matInput name="name" placeholder="Name" formControlName="name">
                    </mat-form-field>
                  </div>
              </div>
            </form>
        </mat-expansion-panel>
    </div>
        <div class="row">
            <div class="col">
                <table mat-table [dataSource]="registrations" class="mat-elevation-z7 full-width">

                      <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef> First Name </th>
                        <td mat-cell data-label="First Name" *matCellDef="let element"> {{element.badge_first_name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="lastName">
                        <th mat-header-cell *matHeaderCellDef> Last Name </th>
                        <td mat-cell data-label="Last Name" *matCellDef="let element"> {{element.badge_last_name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef> Email </th>
                        <td mat-cell data-label="Email" *matCellDef="let element"> {{element.email}} </td>
                      </ng-container>

                      <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef> Onsite Role </th>
                        <td mat-cell data-label="Role" *matCellDef="let element"> {{element.onsite_role}} </td>
                      </ng-container>

                      <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef class="action-column">Actions</th>
                          <td mat-cell data-label="Actions" *matCellDef="let element">
                              <a *ngIf="!event.is_registration_closed" mat-icon-button color="primary" [routerLink]="['edit', element.id]"><mat-icon>edit</mat-icon></a>
                              <a *ngIf="!event.is_registration_closed" mat-icon-button color="accent" (click)="delete(element)"><mat-icon>delete_forever</mat-icon></a>
                          </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
