import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../../../common/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { log } from '@common/log/log';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {

    public forgotPasswordForm;
    event;

    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        private us: UserService,
        private _snackBar: MatSnackBar,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.forgotPasswordForm = this.formBuilder.group({
            username: new FormControl(),
        });
        this.route.data.subscribe((data: {
            event,
		}) => {
			log.Debug("data: ", data)
            this.event = data.event
		})
    }

    resetPassword(){
        this.us.sendPasswordResetLink(this.forgotPasswordForm.value).subscribe(r => {
            log.Debug("reset password response: ", r)
            this._snackBar.open("An email with a link to reset your password has been sent!",null ,{duration: 3000});
        },
        err => {
            this._snackBar.open(err.error ,null ,{duration: 3000, verticalPosition: 'top'});
        });
    }
}
