<div class="container-fluid">

  <h3>Download Reports</h3>
  <br>
  <h4>Contract Status Report</h4>
  <button mat-raised-button color="accent" (click)="ExportContractStatus()">Export as .xls</button>
  <br><br>
  <h4>Invoice Summary Report</h4>
  <button mat-raised-button color="accent" (click)="ExportInvoiceSummary()">Export as .xls</button>
  <br><br>
  <h4>Donation Report</h4>
  <button mat-raised-button color="accent" (click)="ExportDonations()">Export as .xls</button>
  <br><br>
  <h4>No Manifest Report</h4>
  <button mat-raised-button color="accent" (click)="ExportNoManifests()">Export as .xls</button>
  <br><br>
  <h4>No Authorized Shipping Destinations Report</h4>
  <button mat-raised-button color="accent" (click)="ExportNoAuthorizedShippingDestinations()">Export as .xls</button>
  <br><br>
  <h4>Supplier Shipping Destinations Report</h4>
  <button mat-raised-button color="accent" (click)="ExportSupplierShippingDestinations()">Export as .xls</button>


</div>
