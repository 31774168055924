import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { EventService } from './event.service';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppService } from './app.service';
import { log } from '../log/log';

@Injectable({
  providedIn: 'root'
})

export class UnderConstructionGuardService implements CanActivate {

  constructor(
      public EventService: EventService,
      public router: Router,
      private appService: AppService
  ) {}

  canActivate(): Observable<boolean> {
  	log.Debug("under-construction guard");

    return this.EventService.GetEvent().pipe(
      take(1),
      map(event => {
        log.Debug("event in uc: ", event)
        if(event['is_under_construction']) {
          this.appService.navigate(['under-construction']);
          return false
        }
        return true
      }),
      catchError(err => of(false)),
      shareReplay(1)
    )
  }
}
