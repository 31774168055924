import { Component, OnInit, HostBinding, ViewChild, AfterViewInit } from '@angular/core';
import { SidebarService } from '../common/services/sidebar.service';
import { AuthService, AuthGroups } from '../common/services/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, map, of } from 'rxjs';

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
	public openNav = true;
    public position$: Observable<string> = of('side')
	authGroups = AuthGroups
	@HostBinding('class') classes = 'full-height no-head';
	isArchive;

    @ViewChild(MatSidenav) sidenav: MatSidenav

	constructor(
		public SidebarService: SidebarService,
		public AuthService: AuthService
	) {}

	ngOnInit() {
		this.SidebarService.setPageHasSidebar(true);
		this.isArchive = window.location.origin.includes("archive");
        this.position$ = this.SidebarService.state$.pipe(map(({position}) => position))
        // this.SidebarService.state$.subscribe()
	}

}
