import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
	providedIn: 'root'
})
export class CustomInvoiceService {

	constructor(
		public http: HttpClient,
    ) { }
    
    getByCompany(id){
        return this.http.get(environment.api + "/custom_invoices/by_company_id/" + id);
    }

    add(data){
        return this.http.post(environment.api + "/custom_invoices/create", data);
    }

    update(data){
        return this.http.put(environment.api + "/custom_invoices/update/" + data.id, data);
    }

    delete(data){
        return this.http.delete(environment.api + "/custom_invoices/delete/" + data.id, data);
    }

    mine(){
        return this.http.get(environment.api + "/custom_invoices/me");
    }

    download(id){
        return this.http.get(environment.api + "/custom_invoices/download/" + id, {responseType: "blob"});
    }

}