import { Component, OnInit, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PageService } from '../../common/services/page.service';
import { MenuService } from '../../common/services/menu.service';
import { FormBuilder, FormControl} from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { log } from '@common/log/log';
import { EditPageContentComponent } from './edit-page-content/edit-page-content.component';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {
    pages;
    filteredPages;
    menus;
    filterForm;

    @HostBinding('class') classes = 'full-height no-head';

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private PageService: PageService,
        private MenuService: MenuService,
        private FormBuilder: FormBuilder,
        private MatSnackBar: MatSnackBar
    ) {
    }

    ngOnInit() {
        this.filterForm = this.FormBuilder.group({
            'name': new FormControl(),
            'menu_id': new FormControl()
        });

        this.PageService.getAllSorted().subscribe(r => {
            log.Debug("pages: ", r)
            this.pages = r;
            this.filteredPages = Object.assign([], this.pages);
            this.updateOriginalIndex(this.filteredPages);
        });

        this.filterForm.valueChanges.subscribe(val => {
            this.filteredPages = this.pages.filter(p => this.isMatch(val, p))
        });

        this.MenuService.getAll().subscribe(menus => {
           this.menus = menus;
        });
    }

    editContent(id){
        this.dialog.open(EditPageContentComponent, {
            width: '900px',
            height: '800px',
            data: {
                page_id: id
            }
        });
    }

    isMatch(arg, val): boolean {
        if( arg.menu_id != null && arg.menu_id != 0 && arg.name != null ) {
            return val.name.toUpperCase().indexOf(arg.name.toUpperCase()) > -1 && val.menu_id == arg.menu_id
        } else if( (arg.menu_id == 0 || arg.menu_id == null)  && arg.name != null ) {
            return val.name.toUpperCase().indexOf(arg.name.toUpperCase()) > -1
        } else if(arg.menu_id != null && arg.menu_id != 0 && arg.name == null) {
            return arg.menu_id == val.menu_id
        }
        return true
    }

    updateOriginalIndex( array ) {
        for( let i in array ) {
            const item = array[i]
            item['originalIndex'] = i
        }
    }

    onDrop(event: CdkDragDrop<string[]>) {
        const filteredMenu = this.filteredPages[event.previousIndex];
        const previousMenuIndex = filteredMenu.originalIndex;

        const swappedMenu = this.filteredPages[event.currentIndex];
        const currentMenuIndex = swappedMenu.originalIndex;

        moveItemInArray(this.filteredPages, event.previousIndex, event.currentIndex);
         if( event.previousIndex != event.currentIndex) {
             moveItemInArray(this.pages, previousMenuIndex, currentMenuIndex);
        }

        log.Debug("previous: ", event.previousIndex, previousMenuIndex)
        log.Debug("current: ", event.currentIndex, currentMenuIndex)

        this.updateOriginalIndex(this.filteredPages)
    }

    delete(page) {
        if(confirm("Are you sure that you want to delete the page " + page.name + "?")){
            this.PageService.delete(page.id).subscribe(r => {
                this.MatSnackBar.open("Page Deleted!", null, {duration: 3000});
                this.ngOnInit();
            });
        }

    }

    saveOrder() {
        for(let i in this.pages) {
            const page = this.pages[i]
            page.menu_order = (Number(i) + 1)
        }
        this.PageService.updateAll(this.pages).subscribe( r => {
            this.MatSnackBar.open("Page Order Updated!",null ,{duration: 3000});
        });
    }

    print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        printContents = printContents.replace(/> block </gi, "> No <");
        let finalPrintContents = printContents.replace(/> done </gi, "> Yes <");
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              table {font-family: Lato, sans-serif;}
              h3 {font-family: Lato, sans-serif;}
              button {font-weight: bold;font-size: 12px;background: none;border: none;color: #888;font-family: Lato, sans-serif;}
              th {font-weight: bold;font-size: 12px;color: #888;}
              th:last-of-type {display: none;}
              td {font-size: 12px;color: #222;padding: 5px;text-align: center;border-bottom: 1px solid #ccc;}
              td:last-of-type {display: none;}
              div.col {text-align: center;}
              table {display: inline-block;}
              </style>
            </head>
            <body onload="window.print();window.close()">${finalPrintContents}</body>
          </html>`
        );
        popupWin.document.close();
    }

}
