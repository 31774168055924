import { AnalyticsComponent } from './analytics.component';
import { AnalyticsResolve } from './analytics.resolve';

export const ANALYTICS_ROUTES = [
	{
		path: 'analytics',
		component: AnalyticsComponent,
        resolve: {
            analyticsAll: AnalyticsResolve,
        }
	},
];

export const ANALYTICS_COMPONENTS = [
	AnalyticsComponent,
];

export const ANALYTICS_PROVIDERS = [
    AnalyticsResolve,
]
