<div class="container-fluid">
	<h2>Add Page</h2>
	<div class="row">
		<div class="col">
			<mat-form-field>
				<mat-label>Menu</mat-label>
				<mat-select matInput [(ngModel)]="page.menu_id">
					<mat-option [value]="null">No Menu</mat-option>
					<mat-option *ngFor="let item of menus" [value]=item.id>
						{{item.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<mat-form-field>
				<input required matInput [(ngModel)]="page.name" placeholder="Name" />
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<mat-form-field>
				<input matInput [(ngModel)]="page.href" placeholder="Href" />
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<mat-checkbox [(ngModel)]="page.active">Active</mat-checkbox>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<editor [(ngModel)]="page.content" apiKey="7wc6qrlmteld66o3pz7atwwsp098r3h19yf6lko7nzyiutx0" [init]="options"></editor>
		</div>
	</div>
	<button [disabled]="!page.name" mat-raised-button (click)="save()" color="accent">Save</button>
</div>